import { Component } from 'react';
import { Config, CaptureAdditionalItem, NameType } from '../../../types/config';
import BaseModel, { createModel } from '../../../util/mvp/base-model';

export type CaptureModel = {
  advertiserName: string;
  allowEmail: boolean;
  allowPhone: boolean;
  captureAdditional: CaptureAdditionalItem[] | null;
  captureName: boolean;
  countryCode: string | null;
  disableCountryPicker: boolean;
  errorAdditional: string | null;
  errorEmail: string | null;
  errorFullName: string | null;
  errorFirstName: string | null;
  errorLastName: string | null;
  errorPhone: string | null;
  errorPrimaryConsent: string | null;
  errorToken: string | null;
  loading: boolean;
  marketingConsentTerms: string;
  nameType: NameType | null;
  showPrimaryConsent: boolean;
  showCountryCode: boolean;
  showMarketingConsent: boolean;
  showErrorSummary: boolean;
  formFocusTrigger: number;
};

export default function createCaptureModel(
  component: Component,
  config: Config,
): BaseModel<CaptureModel> & CaptureModel {
  const { allow_phone: allowPhone, allow_email: allowEmail } = config.token;
  const {
    consent,
    capture_additional: captureAdditional,
    capture_name: captureName,
    name_type: nameType,
  } = config.claim;

  const { marketing: marketingConsent } = consent ?? {};

  const initialState = {
    advertiserName: config.advertiser_name ?? '',
    allowEmail,
    allowPhone,
    captureAdditional,
    captureName,
    countryCode: null,
    disableCountryPicker: false,
    errorAdditional: null,
    errorEmail: null,
    errorFullName: null,
    errorFirstName: null,
    errorLastName: null,
    errorPhone: null,
    errorPrimaryConsent: null,
    errorToken: null,
    loading: false,
    marketingConsentTerms: marketingConsent?.terms ?? '',
    nameType: nameType ?? null,
    showPrimaryConsent: true,
    showCountryCode: false,
    showMarketingConsent: marketingConsent?.enabled ?? false,
    showErrorSummary: false,
    formFocusTrigger: 0,
  };

  return createModel<CaptureModel>(component, initialState);
}
