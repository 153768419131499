/* eslint-disable */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import clsx from 'clsx';
import { Web3Button } from '@web3modal/react';
import { useAccount, useSignMessage } from 'wagmi';
import Log from '../../util/log';
import styles from './index.module.scss';
import ErrorPopup from '../../features/error';
import { VLabsParams } from '../../types/vlabs-user';
import ViewBase from '../view-base';
import { Button, IconButton, Typography } from '@mui/joy';

interface ViewProps {
  t: TFunction;
  onNext?: () => void;
  onBackClick: () => void;
  localePrefix: 'claim' | 'login';
  language?: string;
}

function View(props: ViewProps): JSX.Element {
  const { t, onNext, onBackClick, localePrefix, language } = props;

  const [isBusy, setBusy] = useState(false);
  const { address, isConnected } = useAccount();
  const { data, error, isLoading, signMessageAsync } = useSignMessage();

  Log.info(`WalletConnect View > 
    signedData: ${data}
    error: ${error}
    isLoading: ${isLoading}
  `);

  const submitSignedChallenge = (signature: string): void => {
    Log.info('WalletConnect > Submitting challenge');

    const params: VLabsParams = {
      connect: 'eth',
      connection: {
        address,
        signature,
      },
    };

    if (language) {
      params.language = language;
    }

    VlabsUser.go(
      '/connect',
      params,
      (response, notificationType) => {
        Log.info('WalletConnect > funcLink called', response, notificationType);
        setBusy(false);
        onNext?.();
      },
      (err) => {
        Log.error('WalletConnect > funcError called', err);
        setBusy(false);
        ErrorPopup.show(err);
      },
    );
  };

  const onContinueClick = async (): Promise<void> => {
    setBusy(true);

    // Request challenge
    Log.info('WalletConnect > Requesting challenge for address', address);

    const params: VLabsParams = {
      connect: 'eth',
      connection: {
        address,
      },
      language,
    };

    VlabsUser.go(
      '/connect',
      params,
      (response, notificationType) => {
        Log.info('WalletConnect > funcLink called', response, notificationType);

        if (response.challenge) {
          Log.info('WalletConnect > Signing challenge');
          signMessageAsync({ message: response.challenge }).then(
            (signedMessage) => {
              Log.info('WalletConnect > Received signed message', response);
              Log.info('WalletConnect > Submitting signed message', signedMessage);
              submitSignedChallenge(signedMessage);
            },
            (rejectReason) => {
              Log.error('WalletConnect > Signing challenge failed', rejectReason);
            },
          );
        }

        setBusy(false);
      },
      (err) => {
        Log.error('WalletConnect > funcError called', err);
        setBusy(false);
        // ErrorPopup.show(err); // TODO: Uncomment
      },
    );
  };

  const headerText = t(`${localePrefix}.wallet-connect.header_text`);

  return (
    <ViewBase
      prefix={'wallet-connect'}
      headerText={headerText}
      footerText={t('claim.landing.footer')}
      onBack={(): void => onBackClick()}
      topOffset="0px"
    >
      <Typography className={clsx('view-wc-text', styles.text)}>
        {t(`${localePrefix}.wallet-connect.text`)}
      </Typography>

      <div className={styles.spacer1} role="none"></div>
      <Web3Button icon="hide" />
      <div className={styles.spacer1} role="none"></div>
      <Button
        className={clsx('view-wc-button')}
        loading={isLoading}
        disabled={!isConnected && !isBusy}
        onClick={(): Promise<void> => onContinueClick()}
      >
        {t(`${localePrefix}.wallet-connect.button_text`)}
      </Button>
      <div className={styles.spacer5} role="none"></div>
    </ViewBase>
  );
}

export default withTranslation()(View);
