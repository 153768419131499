import dayjs from 'dayjs';
import ErrorPopup from '../error';
import WafCaptchaPopup from '../waf-captcha';
import Log from '../../util/log';
import { StateModel as Model, ClaimPage } from './model';
import { Config, ConsentType, FormItem, RawFormItem } from '../../types/config';
import { FormattedToken, AuthProvider } from '../../types/common';
import { VLabsConfig, VLabsParams } from '../../types/vlabs-user';
import Countries from '../../util/countries';
import removeLoader from '../../util/removeLoader';

export default class Presenter {
  model: Model;

  config: Config;

  hasSetup = false;

  supported_locales: string[];

  language = 'en';

  constructor(model: Model, config: Config) {
    this.model = model;
    this.config = config;
    this.supported_locales = config.supported_locales ?? [];
  }

  onAttach(): void {
    const baseLanguage = navigator.language.split('-')[0];

    // Use the browser's language, otherwise use the base language (e.g. 'en' for 'en-GB'), otherwise fallback onto the config's default language, otherwise 'en'.
    if (this.supported_locales?.includes(navigator.language)) {
      this.language = navigator.language;
    } else if (this.supported_locales?.includes(baseLanguage)) {
      this.language = baseLanguage;
    } else {
      const defaultLanguage = this.config.default_language;
      let defaultLocale = 'en';
      if (typeof defaultLanguage === 'string') {
        defaultLocale = defaultLanguage;
      } else if (Array.isArray(defaultLanguage) && defaultLanguage.length > 0) {
        [defaultLocale] = defaultLanguage;
      }
      this.language = defaultLocale;
    }
    this.onLanguageChange(this.language);

    if (this.config && !this.hasSetup) {
      this.setup();
      this.hasSetup = true;
    }

    // Ensure the selected language has associated consent policies
    const { policies } = this.config.claim.consent;
    if (policies && Object.keys(policies).length > 0 && !policies[this.model.language]) {
      throw new Error(`Consent policies don't contain selected language ${this.model.language}`);
    }

    this.model.languagePolicies = policies?.[this.model.language] ?? null;

    const initialFormItems: FormItem[] =
      this.config.claim.form_items?.map((item) => {
        const initialItem = item;
        // Set the dropdowns value as null (rather than undefined) to make the component controlled
        if (initialItem.type === 'dropdown' && initialItem.value === undefined) {
          initialItem.value = null;
          initialItem.disabled = false;
          initialItem.error = false;
        }
        return initialItem;
      }) ?? [];

    this.model.formItems = initialFormItems;
  }

  update(hash: string, config: Config): void {
    this.model.hash = hash;
    this.config = config;
    if (this.config && !this.hasSetup) {
      this.setup();
      this.hasSetup = true;
    }
  }

  onMobileFirstContinueClick(): void {
    this.model.showMobileOnly = false;
  }

  onNext(authProvider: AuthProvider, currentPage?: ClaimPage): void {
    this.model.authProvider = authProvider;
    if (authProvider === 'token') {
      this.moveToPage(ClaimPage.capture);
    } else if (authProvider === 'prove') {
      this.moveToPage(ClaimPage.prove);
    } else if (authProvider === 'wallet-connect') {
      if (currentPage === ClaimPage.landing) {
        this.moveToPage(ClaimPage.signChallenge);
      } else if (currentPage === ClaimPage.signChallenge) {
        this.moveToPage(ClaimPage.capture);
      } else {
        Log.error('Unrecognized flow. Current page:', currentPage);
      }
    } else {
      const params: VLabsParams = {
        language: this.model.language,
      };

      params.connect = authProvider;

      if (
        this.config.claim.consent.policies &&
        Object.keys(this.config.claim.consent.policies).length > 0
      ) {
        // Get all policies for the presented language
        const languageSpecificPolicies = this.config.claim.consent.policies[this.model.language];
        // Consent Framework
        if (this.model.acceptedPrimaryConsent) {
          // Build accepted policies
          const accepted = languageSpecificPolicies;

          // Remove marketing if enabled but not accepted
          if (
            this.config.claim.consent.marketing?.enabled === true &&
            !this.model.acceptedMarketingConsent
          ) {
            delete accepted.marketing;
          }

          params.policies_accepted = accepted;
        }
      } else {
        Log.error('No consent policies detected.');
      }

      params.policies_accepted = { ...params.policies_accepted! };

      // get config form items
      params.form_items = this.model.formItems.map((item) => ({
        id: item.id,
        value: item.value ?? null,
      }));

      // map form item shape to legacy param shape
      const preFormItemsMapped = Object.fromEntries(
        this.model.preFormItems.map((obj) => [obj.id, obj.value]),
      );

      // add iframe form items as params (mimicking url search param behavior)
      params.additionalParams = preFormItemsMapped;

      // Initiate OAuth flow
      VlabsUser.go(
        '/signup',
        params,
        (_, notificationType) => {
          this.onRegister?.(authProvider, notificationType);

          // Move to capture screen.
          this.onNext(authProvider, currentPage);
        },
        (error) => {
          const { delay: errorDelay } = this.config.error;
          ErrorPopup.show(error, () => {}, errorDelay);
          Log.error(error);
        },
      ).then((response): void => {
        Log.info(`[Debug] .go('/signup') response:`, response);
      });
    }
  }

  onBack(source?: 'prove'): void {
    this.model.acceptedPrimaryConsent = false;
    this.model.acceptedMarketingConsent = false;
    this.model.isConsentPopupContinueEnabled = false;
    this.model.continuationSessionId = null;
    this.model.continuationDraftId = null;
    if (source === 'prove') {
      this.model.showProveFallback = this.config.prove?.allow_fallback ?? false;
    }
    this.moveToPage(ClaimPage.landing);
  }

  onRegister(
    authProvider: AuthProvider,
    notificationType: string,
    formattedToken: FormattedToken | undefined = undefined,
  ): void {
    Log.info(`[Debug] onRegister:
      authProvider: ${authProvider},
      notificationType: ${notificationType},
      formattedToken: ${formattedToken}
      token_verification: ${this.config.token_verification}
    `);
    if (authProvider === 'token' && formattedToken) {
      Log.info(`[Debug] onRegister: moving to page "otp" or "link"`);
      this.model.formattedToken = formattedToken;
      let pageIndex = ClaimPage.link;
      if (formattedToken.type === 'phone') {
        pageIndex = this.config.token_verification === 'otp' ? ClaimPage.otp : ClaimPage.link;
      }
      this.model.notificationType = notificationType as 'login' | 'verify';
      this.model.index = pageIndex;
    } else {
      Log.info('[Debug] onRegister: moving to page "capture"');
      this.model.notificationType = notificationType as 'login' | 'verify';
      this.model.index = ClaimPage.capture;
    }
  }

  setup(): void {
    const {
      app_id: appId,
      vlabs_api_url: vlabsApiUrl,
      vlabs_aws_waf_threat_detection_url: awsWafThreatDetectionUrl,
      vlabs_aws_waf_captcha_url: awsWafCaptchaUrl,
      vlabs_aws_waf_captcha_api: awsWafCaptchaApi,
      environment,
      version,
      key,
      campaign,
      domain,
      ga_tracking_id: ga,
      facebook_app_id: facebook,
      google_app_id: google,
      samsung_app_id: samsung,
      samsung_alias_id: samsungAlias,
      outside_app_id: outside,
      session_bypass: sessionBypass,
      signup_bypass: signupBypass,
      claim,
      pixel,
      platform_api_url: platformApiUrl,
      request_applet: requestAppletConfig,
    } = this.config;

    const {
      tradr_visit_pixel: tradrVisitPixel,
      tradr_visit_advertiser: tradrVisitAdvertiser,
      tradr_signup_pixel: tradrSignupPixel,
      tradr_signup_advertiser: tradrSignupAdvertiser,
      pixel_performance_visit: pixelPerformanceVisit,
      pixel_performance_signup: pixelPerformanceSignup,
    } = pixel;

    const { hostname } = window.location;
    if (domain && hostname !== domain) {
      const url = new URL(
        `https://${domain}${window.location.pathname}${window.location.hash}${window.location.search}`,
      );
      window.location.replace(url);
      return;
    }

    if (claim?.expired) {
      this.moveToPage(ClaimPage.expired);
      this.model.isSDKSetup = true;
      return;
    }
    const domainParts = window.location.hostname.split('.');
    domainParts.splice(1, 0, 'ra');
    const requestAppletUrl = `https://${domainParts.join('.')}`;

    const appletConfig = {
      ...requestAppletConfig,
      url: requestAppletUrl,
      onready: (error?: Error): void => {
        if (error) {
          ErrorPopup.show({ code: 'request_applet_init_error' }, () => {
            window.location.reload();
          });
        }
      },
    };

    // find default country code
    const findDefaultCountryCode = async (): Promise<void> => {
      this.model.defaultPhoneRegion = Countries.getDefault();
      // find a recommended country, if any, to replace the default
      const recommendedCountry = await Countries.getRecommendedCountryRestrictedByRegion(
        this.config.token.regions,
      );
      this.model.defaultPhoneRegion = recommendedCountry;
    };

    const vlabsConfig: VLabsConfig = {
      app_id: appId,
      vlabs_api_url: vlabsApiUrl,
      aws_waf_threat_detection_url: awsWafThreatDetectionUrl,
      aws_waf_captcha:
        (awsWafCaptchaUrl &&
          awsWafCaptchaApi && {
            url: awsWafCaptchaUrl,
            api: awsWafCaptchaApi,
            onrequest: () => WafCaptchaPopup.show(awsWafCaptchaApi),
          }) ||
        undefined,
      blueprint: 'claim-multi',
      environment,
      version,
      campaign,
      ga,
      facebook_app: facebook,
      google_app: google,
      samsung_app: samsung,
      samsung_alias: samsungAlias,
      outside_app: outside,
      session_bypass: sessionBypass,
      signup_bypass: signupBypass,
      pixel_gtm: pixel?.gtm,
      pixel_facebook: pixel?.facebook,
      pixel_twitter: pixel?.twitter,
      pixel_visit: pixel?.visit,
      pixel_signup: pixel?.signup,
      tradr_visit_pixel: tradrVisitPixel,
      tradr_visit_advertiser: tradrVisitAdvertiser,
      tradr_signup_pixel: tradrSignupPixel,
      tradr_signup_advertiser: tradrSignupAdvertiser,
      pixel_performance_visit: pixelPerformanceVisit,
      pixel_performance_signup: pixelPerformanceSignup,
      request_applet: appletConfig?.enabled ? appletConfig : undefined,
      platform_api_url: platformApiUrl,
    };

    VlabsUser.init({
      key,
      config: vlabsConfig,
    });

    const loadArgs = {
      language: this.language,
    };

    VlabsUser.load(
      (data) => {
        Log.info('load funcHome callback >', data);

        if (this.config.prove?.enabled || this.config.token.allow_phone) {
          // find default country code, don't await
          findDefaultCountryCode();
        }

        if (data?.flow === 'prove_instant_link_finish' && data?.state.session_id) {
          // extract the continuation data from state
          this.model.continuationSessionId = data.state.session_id;
          this.model.continuationDraftId = data.state.draft_id;
          this.model.inputToken = data.state.phone_number ?? data.phone_number; // TODO: Remove data.state.phone_number pii once server has updated.
          this.model.dateOfBirth = data.state?.dob;
          if (data.state?.form_items) {
            const decoded = decodeURIComponent(data.state.form_items);
            const parsed = JSON.parse(decoded);
            this.model.preFormItems = parsed;
          }
          this.moveToPage(ClaimPage.prove);
        } else {
          const { email, phone } = VlabsUser.query || {};
          const userToken = email || phone;
          if (userToken) {
            this.model.inputToken = userToken;
          }
          // Standard
          if (this.config.age.min && !this.model.dateOfBirth) {
            this.moveToPage(ClaimPage.ageGate);
          } else {
            this.moveToPage(ClaimPage.landing);
          }
        }
        this.model.isSDKSetup = true;
      },
      (): void => {
        this.model.isSDKSetup = true;
      },
      (error) => {
        ErrorPopup.showAndRedirectHome(error);
        this.model.isSDKSetup = true;
        Log.error(error);
      },
      (info) => {
        Log.info('[Debug] func onAuthComplete called with:', info);
        if (info.authProvider === 'token') {
          // SDK will redirect to viewer
          this.model.isSDKSetup = true;
        } else {
          // Map SDK's provider to web-viewers provider
          if (info.authProvider === 'facebook') {
            this.model.authProvider = 'facebook';
          } else if (info.authProvider === 'google') {
            this.model.authProvider = 'google';
          } else if (info.authProvider === 'samsung') {
            this.model.authProvider = 'samsung';
          } else if (info.authProvider === 'eth') {
            this.model.authProvider = 'wallet-connect';
          }
          // Flag that auth has already been completed
          this.model.isAuthComplete = true;

          // Populate the input name field from the social info
          const socialName = `${info.firstName ?? ''} ${info.lastName ?? ''}`.trim();
          this.model.socialName = socialName;
          if (claim.name_type) {
            switch (claim.name_type) {
              case 'first_name': {
                this.model.inputFirstName = info.firstName ?? '';
                break;
              }
              case 'last_name':
                this.model.inputLastName = info.lastName ?? '';
                break;
              case 'first_and_last':
                this.model.inputFirstName = info.firstName ?? '';
                this.model.inputLastName = info.lastName ?? '';
                break;
              case 'full_name':
                this.model.inputFullName = socialName;
                break;
              default: {
                break;
              }
            }
          }
          this.model.avatarURL = info.avatarURL ?? null;
          this.moveToPage(ClaimPage.capture);
        }
        this.model.isSDKSetup = true;
      },
      loadArgs,
    );
  }

  // Inputs

  onTokenInputChange(value: string): void {
    this.model.inputToken = value;
  }

  onFullNameInputChange(value: string): void {
    this.model.inputFullName = value;
  }

  onFirstNameInputChange(value: string): void {
    this.model.inputFirstName = value;
  }

  onLastNameInputChange(value: string): void {
    this.model.inputLastName = value;
  }

  onAdditionalChange(value: string): void {
    this.model.inputAdditional = value;
  }

  onConsentChange(type: ConsentType, consent: boolean): void {
    if (type === 'primary') {
      this.model.acceptedPrimaryConsent = consent;
      if (this.config.claim.consent?.default?.enabled) {
        this.model.isConsentPopupContinueEnabled = consent;
      } else {
        this.model.isConsentPopupContinueEnabled = true;
      }
    } else if (type === 'marketing') {
      this.model.acceptedMarketingConsent = consent;
    }
  }

  onAgeGatePassed(date): void {
    const dateOfBirth = dayjs(date).format('YYYY-MM-DD');
    this.model.dateOfBirth = dateOfBirth;
    this.moveToPage(ClaimPage.landing);
  }

  onLanguageChange(language: string): void {
    this.model.language = language;
  }

  onPreFormItemsChange(items: [RawFormItem]): void {
    // check for defined inputs
    const dobItem = items.find((i) => i.id === 'dob');
    if (dobItem) {
      this.model.dateOfBirth = String(dobItem.value);
    }

    this.model.preFormItems = items;

    setTimeout(() => {
      Log.info('this.model.dateOfBirth', this.model.dateOfBirth);
      Log.info('this.model.preFromItems', this.model.preFormItems);
    }, 1000);
  }

  onFormItemChange(item: FormItem): void {
    const index = this.model.formItems.findIndex((i) => i.id === item.id);
    if (index >= 0) {
      const updated = this.model.formItems;
      updated[index] = item;
      this.model.formItems = updated;
    } else {
      Log.error('Form item not found with id:', item.id);
    }
  }

  moveToPage(page: ClaimPage): void {
    this.model.index = page;
  }

  onGeoBlockerComplete(): void {
    this.model.isGeoBlockerCheckComplete = true;
    removeLoader();
  }
}
