import Countries, { CountryInfo } from '../../util/countries';
import Model from './model';

export default class Presenter {
  model: Model;

  onClose: VoidFunction | null;

  onComplete: (country: CountryInfo) => void;

  onError: (error: string) => void;

  regions: string[];

  constructor(
    model: Model,
    regions: string[],
    onClose: VoidFunction | null,
    onComplete: (country: CountryInfo) => void,
    onError: (error: string) => void,
  ) {
    this.model = model;
    this.onClose = onClose;
    this.onComplete = onComplete;
    this.onError = onError;
    this.regions = regions ?? [];
  }

  async onAttach(): Promise<void> {
    Countries.get().then((countries) => {
      if (this.regions?.length > 0) {
        this.model.countries = countries.filter(
          (country) =>
            !!this.regions.find((code) => code.toLowerCase() === country.code.toLowerCase()),
        );
      } else this.model.countries = countries;
    });
  }

  onCountrySelect(country: CountryInfo): void {
    this.onComplete?.(country);
  }

  onCloseClick(): void {
    this.onClose?.();
  }
}
