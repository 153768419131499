// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claim-Umwg3d-proveLogoContainer{margin-top:26px;margin-bottom:16px}`, "",{"version":3,"sources":["webpack://./src/features/prove/view.module.scss"],"names":[],"mappings":"AAAA,iCACE,eAAA,CACA,kBAAA","sourcesContent":[".proveLogoContainer {\n  margin-top: 26px;\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"proveLogoContainer": `claim-Umwg3d-proveLogoContainer`
};
export default ___CSS_LOADER_EXPORT___;
