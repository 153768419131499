export function goToBaseURL(): void {
  const { protocol, host, pathname } = window.location;
  window.location.href = `${protocol}//${host}${pathname}`;
}

export function getLoginURL(): string {
  const { protocol, host } = window.location;
  return `${protocol}//${host}/login`;
}

export function goToLoginURL(): void {
  window.location.href = getLoginURL();
}
