// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claim-ISeMjs-additionalContentsContainer{display:flex;flex-direction:column;gap:8px;margin:16px 8px 8px 8px;min-height:"fit-content"}`, "",{"version":3,"sources":["webpack://./src/features/claim/consent-popup/index.module.scss"],"names":[],"mappings":"AAAA,0CACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,uBAAA,CACA,wBAAA","sourcesContent":[".additionalContentsContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin: 16px 8px 8px 8px;\n  min-height: 'fit-content';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"additionalContentsContainer": `claim-ISeMjs-additionalContentsContainer`
};
export default ___CSS_LOADER_EXPORT___;
