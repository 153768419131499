// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claim-CIO7AV-text{text-align:center;margin-bottom:24px}.claim-dDoyEC-spacer1{min-height:12px;flex:1 1 auto}.claim-nNdldw-spacer5{min-height:12px;flex:5 1 auto}`, "",{"version":3,"sources":["webpack://./src/views/view-wallet-connect/index.module.scss"],"names":[],"mappings":"AAAA,mBACE,iBAAA,CACA,kBAAA,CAGF,sBACE,eAAA,CACA,aAAA,CAGF,sBACE,eAAA,CACA,aAAA","sourcesContent":[".text {\n  text-align: center;\n  margin-bottom: 24px;\n}\n\n.spacer1 {\n  min-height: 12px;\n  flex: 1 1 auto;\n}\n\n.spacer5 {\n  min-height: 12px;\n  flex: 5 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `claim-CIO7AV-text`,
	"spacer1": `claim-dDoyEC-spacer1`,
	"spacer5": `claim-nNdldw-spacer5`
};
export default ___CSS_LOADER_EXPORT___;
