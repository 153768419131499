import React from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import BaseView from '../../../views/view-base';

export interface ViewProps {
  t: TFunction;
  minAge: number;
}

function View(props: ViewProps): JSX.Element {
  const { t, minAge } = props;

  const errorText = t('age.error.text', { age: minAge });
  const footerText = t('claim.landing.footer');

  return (
    <BaseView prefix="age-gate" headerText={errorText} footerText={footerText} topOffset="0px" />
  );
}

export default withTranslation()(View);
