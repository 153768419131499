import { CountryInfo } from '../../util/countries';
import BaseModel from '../../util/model';

export interface StateModel {
  countries: CountryInfo[];
}

export default class CountryModel extends BaseModel {
  constructor(component: React.Component) {
    super(component, { countries: [] });
  }

  get countries(): CountryInfo[] {
    const { countries } = this.value;
    return countries;
  }

  set countries(value: CountryInfo[]) {
    this.value = { countries: value };
  }
}
