import BaseModel from '../../util/model';

export enum AgePage {
  main = 0,
  error,
}

export interface StateModel {
  index: AgePage;
  prevIndex: AgePage | null;
}

export default class AgeModel extends BaseModel implements StateModel {
  constructor(component: React.Component, index: AgePage = AgePage.main) {
    super(component, {
      index,
      prevIndex: null,
    });
  }

  get index(): AgePage {
    const { index } = this.value;
    return index;
  }

  set index(value: AgePage) {
    this.value = { prevIndex: this.index, index: value };
  }

  get prevIndex(): AgePage | null {
    const { prevIndex } = this.value;
    return prevIndex;
  }

  private set prevIndex(value: AgePage | null) {
    this.value = { prevIndex: value };
  }
}
