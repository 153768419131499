import { ThemeStyle } from '../../theme/theme';

export default function createStyles(theme: ThemeStyle): string[] {
  const styles: string[] = [];

  const { top_margin: topMargin } = theme;

  const topOffset = theme.override?.geo_blocking?.top_margin ?? topMargin;

  if (topOffset) {
    let rules = '';
    if (topOffset && topOffset?.length > 0) {
      rules += `padding-top:${topOffset};`;

      styles.push(`.geo-blocking-inner-container-mobile {${rules}}`);
    }
  }

  return styles;
}
