import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import clsx from 'clsx';
import { useMediaQuery } from 'usehooks-ts';
import { CircularProgress, Stack } from '@mui/joy';
import Countries from '../../util/countries';
import Image from '../../components/image';
import styles from './view.module.scss';
import Log from '../../util/log';
import { Config } from '../../types/config';
import ViewAnchor from '../../views/view-anchor';
import ViewBase from '../../views/view-base';
import { LocalePrefix } from '../../types/common';
import { tIfExistsAndIsValid } from '../../util/i18next-helpers';

export interface ViewProps {
  t: TFunction;
  shouldCheck: boolean;
  config: Config;
  availableComponent: React.ReactNode;
  onComplete: () => void;
}

function GeoBlocker(props: ViewProps): React.ReactNode {
  const { t, shouldCheck, config, availableComponent, onComplete } = props;

  const [geoAvailable, setGeoAvailable] = useState<boolean | null>(null);

  const matches = useMediaQuery('(max-width: 856px)');

  const backgroundImageUrl = tIfExistsAndIsValid('geo_blocking.background_image.src');

  // Ensure allowed_regions is present and contains something
  const checkGeoBlocker =
    shouldCheck &&
    config.geo_blocking?.allowed_regions &&
    config.geo_blocking.allowed_regions.length !== 0;

  useEffect(() => {
    if (checkGeoBlocker) {
      // Get API key
      const apiKey = config.ipregistry_api_key;
      if (!apiKey) {
        Log.warn('GeoBlocker ipregistry_api_key not set.');
        setGeoAvailable(true);
        onComplete();
        return;
      }

      // Get whitelist from config
      const countryWhitelist = config.geo_blocking?.allowed_regions?.map((code) =>
        code.toLowerCase(),
      );

      if (!countryWhitelist) {
        setGeoAvailable(true);
        onComplete();
        return;
      }

      // Find user country code
      Countries.detectIPRegistry(apiKey)
        .then((countryInfo) => {
          // Check if any info was returned
          if (!countryInfo) {
            setGeoAvailable(true);
            onComplete();
            return;
          }

          const code = countryInfo.code.toLowerCase();

          // Check if whitelisted
          if (countryWhitelist.includes(code)) {
            setGeoAvailable(true);
            onComplete();
          } else {
            Log.error(`GeoBlocker > Country code ${code} blocked.`);
            // only dismiss loader in failure case
            setGeoAvailable(false);
            onComplete();
          }
        })
        .catch((error) => {
          Log.error(`GeoBlocker > Encountered on error ${error}.`);
          setGeoAvailable(false);
          onComplete();
        });
    } else {
      setGeoAvailable(true);
      onComplete();
    }
  }, []);

  const fallbackPage = (
    <ViewAnchor t={t} prefix={LocalePrefix.geo_blocking}>
      <div className={clsx('geo-blocking-container', styles.container)}>
        {matches && (
          <div className={clsx('geo-blocking-background-image-container', styles.imageContainer)}>
            {backgroundImageUrl && (
              <Image
                className={clsx('geo-blocking-background-image', styles.image)}
                src={backgroundImageUrl}
                alt={t('geo_blocking.background_image.alt')}
              />
            )}
          </div>
        )}
        <div
          className={clsx(
            matches ? 'geo-blocking-inner-container-mobile' : 'geo-blocking-inner-container',
            styles.innerContainer,
          )}
        >
          <ViewBase
            prefix="geo-blocking"
            headerText={t('geo_blocking.text')}
            headerStyles={clsx(styles.message)}
          />
        </div>
      </div>
    </ViewAnchor>
  );

  if (geoAvailable === null) {
    return (
      <Stack>
        <CircularProgress />
      </Stack>
    );
  }
  if (geoAvailable) {
    return availableComponent;
  }
  return fallbackPage;
}

export default withTranslation()(GeoBlocker);
