import { Edit } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Input,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
  VariantProp,
} from '@mui/joy';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import clsx from 'clsx';
import { Dayjs } from 'dayjs';
import i18next, { TFunction } from 'i18next';
import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import FormControlWrapper from '../../components/form/form-control-wrapper';
import { TokenValidationErrorKey } from '../../components/form/format-validate';
import PoweredByProveIcon from '../../resources/images/powered_by_prove.svg';
import { FormItem } from '../../types/config';
import useScrollAndFocusAnchor from '../../util/hooks/useScrollAndFocusAnchor';
import Log from '../../util/log';
import AdditionalForm from './additional-form';
import styles from './view.module.scss';
import truncateAndEllipsis from '../../util/string-helpers';
import MobileDatePicker from '../../components/mobile-date-picker';

export interface PII {
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  dob: Dayjs | null;
  emailAddress: string | null;
}

export type IdentityFormData = PII & { phoneValidationError?: TokenValidationErrorKey | null };

export interface ViewProps extends PII {
  page: 'login' | 'claim';
  loading?: boolean;
  prefilled: boolean;
  piiReadOnly: boolean;
  formItems: FormItem[];
  surfaceExit: boolean;
  showPhoneValidationError: boolean;
  showDobValidationError: boolean;
  showFirstValidationError: boolean;
  showLastValidationError: boolean;
  formFocusTrigger: number;
  showFormErrorSummary: boolean;
  onChangePii: (data: IdentityFormData | null) => void;
  onBlurPii: (field: 'firstName' | 'lastName') => void;
  onFormItemChange: (item: FormItem) => void;
  onSubmit?: () => void;
  onBack: (reason: 'error') => void;
  t: TFunction;
}

interface FormElements extends HTMLFormControlsCollection {
  phoneNumber: HTMLInputElement;
  firstName: HTMLInputElement;
  lastName: HTMLInputElement;
  dob: HTMLInputElement;
  email: HTMLInputElement;
}
interface IdentityFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

function IdentityForm({ loading = false, onSubmit = undefined, ...props }: ViewProps): JSX.Element {
  const {
    page,
    firstName,
    lastName,
    phoneNumber,
    dob,
    emailAddress,
    prefilled,
    piiReadOnly,
    formItems,
    surfaceExit,
    showPhoneValidationError,
    showDobValidationError,
    showFirstValidationError,
    showLastValidationError,
    formFocusTrigger,
    showFormErrorSummary,
    onChangePii,
    onBlurPii,
    onFormItemChange,
    onBack,
    t,
  } = props;

  const handleSubmit = (event: React.FormEvent<IdentityFormElement>): void => {
    event.preventDefault();
    onSubmit?.();
  };

  // hook to focus and scroll to link element targets
  useScrollAndFocusAnchor([
    showFirstValidationError,
    showLastValidationError,
    showPhoneValidationError,
    showDobValidationError,
  ]);

  // Form changes

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    Log.info('form: firstName change', value);
    onChangePii?.({
      firstName: value,
      lastName,
      phoneNumber,
      emailAddress,
      dob,
    });
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    Log.info('form: lastName change', value);
    onChangePii?.({
      firstName,
      lastName: value,
      phoneNumber,
      emailAddress,
      dob,
    });
  };

  const handleDobChange = (inputDate: Dayjs | null): void => {
    Log.info('form: handleDobChange', inputDate);
    if (!inputDate) {
      return;
    }
    onChangePii?.({
      firstName,
      lastName,
      phoneNumber,
      emailAddress,
      dob: inputDate,
    });
  };

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);

  // focus on the field that has an error
  useEffect(() => {
    if (showFirstValidationError) {
      firstNameRef.current?.focus();
    } else if (showLastValidationError) {
      lastNameRef.current?.focus();
    }
  }, [formFocusTrigger]);

  const headerText = prefilled
    ? t('claim.prove.form.prefilled.title')
    : t('claim.prove.form.unfilled.title');

  const textFieldVariant: VariantProp = piiReadOnly ? 'plain' : 'outlined';

  function textForPath(path: string): string | undefined {
    if (i18next.exists(path)) {
      return t(path) ?? undefined;
    }
    return undefined;
  }

  const piiFormErrors = [
    {
      id: 'pii_first_name',
      label: t(`${page}.landing.input_label_first_name`),
      error: showFirstValidationError,
    },
    {
      id: 'pii_last_name',
      label: t(`${page}.landing.input_label_last_name`),
      error: showLastValidationError,
    },
    {
      id: 'pii_dob',
      label: t(`${page}.prove.form.dob_label`),
      error: showDobValidationError,
    },
  ];

  const formItemErrors = formItems.map((item) => {
    let label = '';
    if (item.type === 'checkbox') {
      label = truncateAndEllipsis(t(`claim.form_item.${item.id}.text`) ?? '');
    } else if (item.type === 'dropdown') {
      label = t(`claim.form_item.${item.id}.label`);
    }
    return {
      id: item.id,
      label,
      error: item.error ?? false,
    };
  });

  const formErrors = [...piiFormErrors, ...formItemErrors];

  // map 'standard input' errors to list items
  const FormErrorsListItems: JSX.Element[] = formErrors
    .filter((item) => item.error)
    .map((item) => {
      return (
        <ListItem key={item.id}>
          <Link href={`#${item.id}`} level="body-sm">
            {item.label}
          </Link>
        </ListItem>
      );
    });

  // summary of all errors as a list
  const ErrorSummary: JSX.Element = (
    <Alert aria-live="polite" sx={{ width: '100%', my: '12px' }}>
      <div>
        <Typography id="list-title" level="title-md">
          {t('claim.landing.form_review_required')}
        </Typography>
        <List
          aria-labelledby="list-title"
          component="ol"
          marker="decimal"
          sx={{
            '--ListItem-paddingY': '2px',
            '--ListItem-minHeight': '20px',
          }}
        >
          {FormErrorsListItems}
        </List>
      </div>
    </Alert>
  );

  return (
    <Container component="main" maxWidth="xs" sx={{ height: '100%' }}>
      <CssBaseline />
      <Box
        sx={{
          pt: 4,
          pb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {/* Visa Figma does not include the unfilled reason */}
        {/* {!prefilled && (
          <Typography component="h3" level="body-md" mb={2}>
            {textForPath(`${page}.prove.form.unfilled.reason`)}
          </Typography>
        )} */}
        <Stack spacing={1.5} mb={4} sx={{ width: '100%' }}>
          <Typography component="h1" level="h3" textAlign="center">
            {headerText}
          </Typography>
          <Typography component="h2" level="body-sm" textAlign="center">
            {t(`${page}.prove.form.unfilled.sub_title`)}
          </Typography>
        </Stack>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ pt: 1, paddingBottom: '40px', width: '100%' }}
        >
          <Grid container spacing={1}>
            <Grid xs={12} sm={6}>
              <FormControlWrapper
                id="pii_first_name"
                label={textForPath(`${page}.landing.input_label_first_name`)}
                helper={
                  showFirstValidationError
                    ? t(`${page}.prove.form.first_name_helper_invalid`)
                    : undefined
                }
                required
                error={showFirstValidationError}
              >
                <Input
                  name="firstName"
                  placeholder={textForPath(`${page}.landing.input_placeholder_first_name`)}
                  size="lg"
                  variant={textFieldVariant}
                  required
                  fullWidth
                  autoComplete="given-name"
                  readOnly={piiReadOnly}
                  value={firstName ?? ''}
                  onChange={handleFirstNameChange}
                  onBlur={() => onBlurPii('firstName')}
                  slotProps={{ input: { ref: firstNameRef } }}
                />
              </FormControlWrapper>
            </Grid>

            <Grid xs={12} sm={6}>
              <FormControlWrapper
                id="pii_last_name"
                label={textForPath(`${page}.landing.input_label_last_name`)}
                helper={
                  showLastValidationError
                    ? t(`${page}.prove.form.last_name_helper_invalid`)
                    : undefined
                }
                required
                error={showLastValidationError}
              >
                <Input
                  name="lastName"
                  size="lg"
                  placeholder={textForPath(`${page}.landing.input_placeholder_last_name`)}
                  variant={textFieldVariant}
                  required
                  fullWidth
                  autoComplete="family-name"
                  readOnly={piiReadOnly}
                  value={lastName ?? ''}
                  onChange={handleLastNameChange}
                  onBlur={() => onBlurPii('lastName')}
                  sx={{ flexGrow: 1 }}
                  slotProps={{ input: { ref: lastNameRef } }}
                />
              </FormControlWrapper>
            </Grid>
            <Grid xs={12}>
              <FormControlWrapper label={t(`${page}.prove.form.phone_label_read_only`)} required>
                <Input readOnly value={phoneNumber ?? ''} />
              </FormControlWrapper>
            </Grid>
            <Grid xs={12}>
              <FormControlWrapper
                error={showDobValidationError}
                label={t(`${page}.prove.form.dob_label`)}
                required
                helper={
                  showDobValidationError ? t(`${page}.prove.form.dob_helper_invalid`) : undefined
                }
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  dateFormats={{ monthAndYear: 'MMMM' }}
                >
                  <MobileDatePicker
                    name="dob"
                    views={['month', 'day']}
                    format="MM / DD"
                    readOnly={piiReadOnly}
                    value={dob}
                    onChange={(newValue): void => handleDobChange(newValue)}
                    slotProps={{
                      textField: {
                        id: 'pii_dob',
                        fullWidth: true,
                        variant: piiReadOnly ? 'outlined' : 'standard',
                        focused: piiReadOnly ? false : undefined,
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControlWrapper>
            </Grid>
            {prefilled && (
              <Grid xs={12}>
                <Button
                  size="sm"
                  variant="plain"
                  startDecorator={<Edit />}
                  onClick={(): void => {
                    onChangePii?.(null);
                    firstNameRef.current?.focus();
                  }}
                  disabled={!piiReadOnly}
                >
                  {t(`${page}.prove.form.prefilled.edit_button_text`)}
                </Button>
              </Grid>
            )}

            {formItems.length > 0 && (
              <>
                <Grid xs={12} mt={2} mb={2}>
                  <Typography component="h2" level="body-sm">
                    {t(`${page}.prove.form.section_additional_info`)}
                  </Typography>
                </Grid>

                <AdditionalForm
                  formItems={formItems}
                  onItemChange={(item): void => {
                    onFormItemChange(item);
                  }}
                />
              </>
            )}

            {showFormErrorSummary && ErrorSummary}

            <Grid xs={12}>
              <Button size="lg" type="submit" fullWidth sx={{ mt: 3, mb: 2 }} loading={loading}>
                {textForPath(`${page}.prove.form.button_text`)}
              </Button>
            </Grid>

            {surfaceExit && (
              <Grid container justifyContent="center" xs={12}>
                <Grid>
                  <Button
                    variant="plain"
                    disabled={loading}
                    onClick={(): void => {
                      onBack('error');
                    }}
                  >
                    {textForPath(`${page}.prove.form.button_text_alt_action`)}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
        <div style={{ flexGrow: 1 }} />
        <div className={styles.proveLogoContainer}>
          <img className={clsx(`powered-by-prove-icon`)} alt="" src={PoweredByProveIcon} />
        </div>
      </Box>
    </Container>
  );
}

export default withTranslation()(IdentityForm);
