import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useMediaQuery } from 'usehooks-ts';
import styles from './index.module.scss';

interface TransitionScreenProps {
  children: React.ReactNode;
  in: boolean;
  direction: 'left' | 'right';
}

function TransitionScreen({ children, in: inProp, direction }: TransitionScreenProps): JSX.Element {
  // note: hardcoded timeouts linked to .scss
  const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion)');
  const timeout = prefersReducedMotion ? 650 : 250;
  const left = direction === 'left';

  return (
    <CSSTransition
      in={inProp}
      timeout={timeout}
      classNames={{
        enter: left ? styles.slideLeftEnter : styles.slideRightEnter,
        enterActive: left ? styles.slideLeftEnterActive : styles.slideRightEnterActive,
        exit: left ? styles.slideLeftExit : styles.slideRightExit,
        exitActive: left ? styles.slideLeftExitActive : styles.slideRightExitActive,
      }}
      mountOnEnter
      unmountOnExit
      onEntered={(node): void => {
        const title = node?.getElementsByTagName('h1');
        if (title.length > 0) {
          title[0].setAttribute('tabIndex', 0);
          title[0]?.focus();
          title[0].setAttribute('tabIndex', -1);
        }
      }}
    >
      <div role="presentation" style={{ position: 'absolute', width: '100%', height: '100%' }}>
        {children}
      </div>
    </CSSTransition>
  );
}

export default TransitionScreen;
