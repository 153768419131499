import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { StateModel as Model } from './model';

dayjs.extend(isSameOrBefore);

export default class Presenter {
  model: Model;

  minAge: number;

  onSuccess: (date: Dayjs | null) => void;

  onError: () => void;

  constructor(
    model: Model,
    minAge: number,
    onSuccess: (date: Dayjs | null) => void,
    onError: () => void,
  ) {
    this.model = model;
    this.minAge = minAge;
    this.onSuccess = onSuccess;
    this.onError = onError;
  }

  onBirthdayChange(date: Dayjs | null): void {
    this.model.error = undefined;
    this.model.selectedDate = date;
  }

  onBirthdayBlur(): void {
    if (!this.model.selectedDate) {
      this.model.error = ['age.landing.error_invalid_date'];
    }
  }

  onNextClick(focusRef?: React.RefObject<HTMLInputElement>): void {
    const { selectedDate } = this.model;

    if (selectedDate) {
      const minDateOfBirth = dayjs().subtract(this.minAge, 'year');
      if (selectedDate.isSameOrBefore(minDateOfBirth)) {
        this.onSuccess?.(selectedDate);
      } else {
        this.onError?.();
      }
    } else {
      this.model.error = ['age.landing.error_invalid_date'];
      // focus first field
      if (focusRef) {
        focusRef.current?.focus();
      }
    }
  }
}
