import React from 'react';
import { withTranslation } from 'react-i18next';
import i18next, { TFunction } from 'i18next';
import clsx from 'clsx';
import { Input, Option } from '@mui/joy';
import Select from '../../../components/select';
import { FormItem } from '../../../types/config';
import Checkbox from '../../../components/checkbox';
import FormControlWrapper from '../../../components/form/form-control-wrapper';

type FormItemWithRef = {
  inputRef?: React.RefObject<HTMLInputElement | HTMLButtonElement | null>;
} & FormItem;

interface ViewProps {
  t: TFunction;
  formItems: FormItemWithRef[];
  containerClassName?: string;
  formControlClassName?: string;
  onFormItemChange: (item: FormItem) => void;
  onFormItemBlur: (item: FormItem) => void;
}

function FromItemsView({
  containerClassName = undefined,
  formControlClassName = undefined,
  ...props
}: ViewProps): JSX.Element {
  const { t, formItems, onFormItemChange, onFormItemBlur } = props;

  return (
    <div className={containerClassName}>
      {formItems.map((item) => {
        // parse locale strings
        const localePath = `claim.form_item.${item.id}`;
        const formLabel = i18next.exists(`${localePath}.label`)
          ? t(`${localePath}.label`)
          : undefined;
        const itemPlaceholder = i18next.exists(`${localePath}.placeholder`)
          ? t(`${localePath}.placeholder`)
          : undefined;
        const formHelper = i18next.exists(`${localePath}.helper`)
          ? t(`${localePath}.helper`)
          : undefined;

        return (
          <FormControlWrapper
            id={`form-item-${item.type}-${item.id}`}
            className={clsx(`claim-form-item-control`, formControlClassName)}
            key={item.id}
            label={formLabel}
            required={item.required}
            error={item.error}
            helper={formHelper}
          >
            {item.type === 'checkbox' && (
              <Checkbox
                className={clsx('claim-form-item-checkbox')}
                label={t(`${localePath}.text`)}
                checked={item.value ?? false}
                onToggle={(value: boolean): void => {
                  const copy = item;
                  copy.value = value;
                  onFormItemChange(copy);
                }}
                onBlur={(): void => {
                  onFormItemBlur(item);
                }}
                disabled={item.disabled}
                ref={item.inputRef}
              />
            )}

            {item.type === 'dropdown' && (
              <Select
                className={clsx('claim-form-item-select')}
                placeholder={itemPlaceholder}
                value={item.value}
                disabled={item.disabled}
                onChange={(newValue): void => {
                  const copy = item;
                  copy.value = newValue;
                  onFormItemChange(copy);
                }}
                onBlur={(listViewed): void => {
                  if (listViewed) {
                    onFormItemBlur(item);
                  }
                }}
                slotProps={{ button: { ref: item.inputRef as React.RefObject<HTMLButtonElement> } }}
              >
                {item.options.map((option) => (
                  <Option value={option.value}>
                    {t(`claim.form_item.${item.id}.option.${option.name}`)}
                  </Option>
                ))}
              </Select>
            )}

            {item.type === 'input' && (
              <Input
                className={clsx('claim-form-item-input')}
                placeholder={itemPlaceholder}
                value={item.value}
                disabled={item.disabled}
                onChange={(event): void => {
                  const copy = item;
                  copy.value = event.target.value;
                  onFormItemChange(copy);
                }}
                onBlur={(): void => {
                  onFormItemBlur(item);
                }}
                slotProps={{ input: { ref: item.inputRef as React.RefObject<HTMLInputElement> } }}
              />
            )}
          </FormControlWrapper>
        );
      })}
    </div>
  );
}

export default withTranslation()(FromItemsView);
