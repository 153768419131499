import { Checkbox, CheckboxProps, Typography } from '@mui/joy';
import { checkboxClasses } from '@mui/joy/Checkbox';
import DOMPurify from 'dompurify';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';

export interface IndexProps
  extends Pick<
    CheckboxProps,
    'id' | 'className' | 'checked' | 'size' | 'disabled' | 'autoFocus' | 'required'
  > {
  label: string;
  onToggle: (checked: boolean) => void;
  onBlur?: () => void;
}

const BaseCheckbox = forwardRef(
  ({ onBlur = undefined, ...props }: IndexProps, ref): JSX.Element => {
    const { id, className, label, checked, size, onToggle, disabled, required } = props;
    const pureText = DOMPurify.sanitize(label);

    const innerInputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => {
      return {
        focus(): void {
          innerInputRef.current?.focus();
        },
        scrollIntoView(): void {
          innerInputRef.current?.scrollIntoView();
        },
      };
    }, []);

    return (
      <Checkbox
        slotProps={{ input: { ref: innerInputRef } }}
        id={id}
        className={className}
        size={size}
        checked={checked}
        disabled={disabled}
        tabIndex={0}
        sx={{ [`& > .${checkboxClasses.checkbox}`]: { position: 'relative' } }}
        onChange={(): void => {
          // onToggle?.(event.target.checked);
          onToggle?.(!checked);
        }}
        onBlur={onBlur}
        onKeyDown={(event): void => {
          if (event.key === 'Enter') {
            onToggle?.(!checked);
          }
        }}
        label={
          <Typography level="body-sm">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: pureText,
              }}
            />
            {required && (
              <Typography level="inherit" color="danger" aria-hidden>
                {' '}
                *
              </Typography>
            )}
          </Typography>
        }
      />
    );
  },
);

export default BaseCheckbox;
