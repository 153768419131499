import { ThemeStyleV2 } from './theme';

export default function createStyles(theme: ThemeStyleV2): string[] {
  const root: HTMLElement | null = document.querySelector(':root');
  const styles: string[] = [];

  // Loader
  root?.style.setProperty('--loader-color', 'var(--smt-palette-primary-500)');
  root?.style.setProperty('--loader-background-color', 'var(--smt-palette-background-body)');

  // Overrides
  if (theme.components?.loader) {
    const { foreground: loaderForeground, background: loaderBackground } = theme.components.loader;

    if (loaderForeground) {
      root?.style.setProperty('--loader-color', loaderForeground);
    }

    if (loaderBackground) {
      root?.style.setProperty('--loader-background-color', loaderBackground);
    }
  }

  return styles;
}
