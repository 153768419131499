import { ThemeStyle } from '../../theme/theme';

export default function createStyles(theme: ThemeStyle): string[] {
  const { palette } = theme;

  const { background: backgroundColor } = palette ?? {};

  const styles: string[] = [];

  // TODO: Verify whether margin top is needed.
  // if (marginTop && marginTop?.length > 0) {
  //   styles.push(`.base-landing-container {margin-top:${marginTop};}`);
  // }

  if (backgroundColor && backgroundColor.length > 0) {
    styles.push(`.base-landing-container {background-color: ${backgroundColor};}`);
    styles.push(`.base-landing-container-anchored {background-color: ${backgroundColor};}`);
  }

  return styles;
}
