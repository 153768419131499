import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonAuth from './auth-button';
import OutsideButton from './button-outside';
import SamsungButton from './button-samsung';
import { AuthProvider } from '../types/common';

type AuthName =
  | 'email'
  | 'phone'
  | 'emailOrPhone'
  | 'google'
  | 'facebook'
  | 'outside'
  | 'samsung'
  | 'wallet-connect';

export type AuthButtonInput = {
  name: AuthName;
  sortKey: AuthProvider;
  className: string;
  localeKey: string;
};

interface Props {
  onClick: (provider: AuthProvider) => void;
  providers: AuthButtonInput[];
  disabled?: boolean;
}

export default function AuthButtonList({ disabled = false, ...props }: Props): JSX.Element {
  const { t } = useTranslation();

  const { providers, onClick } = props;

  return (
    <>
      {providers.map((provider) => {
        if (provider.name === 'outside') {
          return (
            <OutsideButton
              key={provider.name}
              className={provider.className}
              text={t(provider.localeKey)}
              disabled={disabled}
              onClick={(): void => onClick('outside')}
            />
          );
        }
        if (provider.name === 'samsung') {
          return (
            <SamsungButton
              key={provider.name}
              className={provider.className}
              text={t(provider.localeKey)}
              disabled={disabled}
              onClick={(): void => onClick('samsung')}
            />
          );
        }
        return (
          <ButtonAuth
            key={provider.name}
            className={provider.className}
            provider={provider.name}
            disabled={disabled}
            onClick={(): void => {
              let prov: AuthProvider;
              if (
                provider.name === 'phone' ||
                provider.name === 'email' ||
                provider.name === 'emailOrPhone'
              ) {
                prov = 'token';
              } else {
                prov = provider.name;
              }
              onClick(prov);
            }}
          >
            {t(provider.localeKey)}
          </ButtonAuth>
        );
      })}
    </>
  );
}
