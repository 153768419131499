import { Component } from 'react';
import { AuthProvider, FormattedToken } from '../../types/common';
import { FormItem, LanguagePolicies, RawFormItem } from '../../types/config';
import { NotificationType } from '../../types/vlabs-user';
import BaseModel, { createModel } from '../../util/mvp/base-model';
import { CountryInfo } from '../../util/countries';

export enum ClaimPage {
  ageGate = 0,
  landing,
  capture,
  link,
  expired,
  otp,
  phone,
  signChallenge,
  prove,
}

export type StateModel = {
  authProvider: AuthProvider | null;
  hash: string | null;
  index: ClaimPage | null;
  prevIndex: ClaimPage | null;
  notificationType: NotificationType | null;
  showMobileOnly: boolean;
  inputToken: string;
  formattedToken: FormattedToken | null;
  avatarURL: string | null;
  inputFullName: string;
  inputFirstName: string;
  inputLastName: string;
  socialName: string | null;
  inputAdditional: string | null;
  acceptedPrimaryConsent: boolean;
  acceptedMarketingConsent: boolean;
  isConsentPopupContinueEnabled: boolean;
  isAuthComplete: boolean;
  dateOfBirth: string | null;
  language: string;
  languagePolicies: LanguagePolicies | null;
  defaultPhoneRegion: CountryInfo | null;
  continuationSessionId: string | null;
  continuationDraftId: string | null;
  showProveFallback: boolean;
  formItems: FormItem[];
  /** Form items collected prior to collecting landing page form items, e.g. from pre-auth iframe */
  preFormItems: RawFormItem[];
  /** Indicates the app has been setup (sdk has initialized and loaded) */
  isSDKSetup: boolean;
  isGeoBlockerCheckComplete: boolean;
};

export default function createStateModel(
  component: Component,
  isConsentPopupContinueEnabled: boolean,
  showMobileOnly: boolean,
): BaseModel<StateModel> & StateModel {
  const initialState = {
    authProvider: null,
    hash: null,
    index: null,
    prevIndex: null,
    notificationType: null,
    showMobileOnly,
    inputToken: '',
    formattedToken: null,
    avatarURL: null,
    inputFullName: '',
    inputLastName: '',
    inputFirstName: '',
    socialName: null,
    inputAdditional: null,
    acceptedPrimaryConsent: false,
    acceptedMarketingConsent: false,
    isConsentPopupContinueEnabled,
    isAuthComplete: false,
    dateOfBirth: null,
    language: 'en',
    languagePolicies: null,
    defaultPhoneRegion: null,
    continuationSessionId: null,
    continuationDraftId: null,
    showProveFallback: false,
    formItems: [],
    preFormItems: [],
    isSDKSetup: false,
    isGeoBlockerCheckComplete: false,
  };

  return createModel<StateModel>(component, initialState);
}
