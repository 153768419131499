import clsx from 'clsx';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Typography, Button } from '@mui/joy';
import OtpInput from '../../components/otp-input';
import Presenter from './presenter';
import styles from './view.module.scss';
import { StateModel } from './model';
import { FormattedToken } from '../../types/common';
import ViewBase from '../view-base';
import FormControlWrapper from '../../components/form/form-control-wrapper';
import VisuallyHiddenSpan from '../../components/span-visually-hidden';

interface ViewProps extends StateModel {
  t: TFunction;
  presenter: Presenter;
  formattedToken: FormattedToken;
  numInputs: number;
  localePrefix: 'claim' | 'login';
  enableAccessibility: boolean;
}

function View(props: ViewProps): JSX.Element {
  const {
    t,
    presenter,
    otp,
    error,
    formattedToken,
    loading,
    isContinueEnabled,
    numInputs,
    localePrefix,
    enableAccessibility,
  } = props;

  let headerText = '';
  if (formattedToken.type === 'email') {
    headerText = t(`${localePrefix}.otp.header_text_email`);
  } else if (formattedToken.type === 'phone') {
    headerText = t(`${localePrefix}.otp.header_text_phone`);
  }

  let errorText = '';
  if (error === 'invalid_otp') {
    errorText = t(`${localePrefix}.otp.error_invalid_otp`);
  }

  const headerTextSuffix = ` - ${t(`${localePrefix}.landing.progress_context`)} (${t(
    'common.progress_step',
    {
      current: 2,
      total: 2,
    },
  )})`;
  const headerTextSuffixSpan = <VisuallyHiddenSpan>{headerTextSuffix}</VisuallyHiddenSpan>;

  return (
    <ViewBase
      prefix="view-otp"
      localePrefix={localePrefix}
      pageTitleSuffix={enableAccessibility ? `${headerText} ${headerTextSuffix}` : undefined}
      headerText={headerText}
      headerTextChildren={enableAccessibility ? headerTextSuffixSpan : undefined}
    >
      <div className={clsx('view-otp-inner-container', styles.innerContainer)}>
        <Typography className={clsx('view-otp-text', styles.text)}>
          {t(`${localePrefix}.otp.text`, { token: formattedToken?.value, defaultValue: '' })}
        </Typography>

        {/* <Typography
          className={clsx('view-otp-resend-text', styles.text, styles.resend)}
          onClick={(): Promise<void> => presenter.onResendClick()}
        >
          {t(`${localePrefix}.otp.resend_text`, { token: formattedToken?.value })}
        </Typography> */}
        <div style={{ height: '40px' }} />

        <FormControlWrapper
          label={t(`${localePrefix}.otp.input_label`)}
          helper={errorText}
          disabled={false}
          required={false}
        >
          <OtpInput
            className={clsx('view-otp-input-label')}
            style={undefined}
            numInputs={numInputs}
            value={otp}
            onChange={(otpValue: string): void => presenter.onOtpChange(otpValue)}
          />
        </FormControlWrapper>
        <div className={styles.spacer1} role="none" />
        <Button
          className={clsx('view-otp-button')}
          loading={loading}
          disabled={!isContinueEnabled || loading}
          onClick={(): Promise<void> => presenter.onContinueClick()}
        >
          {loading
            ? t(`${localePrefix}.landing.loading_text`)
            : t(`${localePrefix}.otp.button_text`)}
        </Button>

        <div className={styles.spacer5} role="none" />
      </div>
    </ViewBase>
  );
}

export default withTranslation()(View);
