import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

function VisuallyHiddenSpan({ children }: Props): JSX.Element {
  return <span className="screen-reader-only">{children}</span>;
}

export default VisuallyHiddenSpan;
