export interface ClientError {
  locale_key: string;
  code: number | string;
}

export interface VlabsSDKError {
  code: number | string;
  message?: string;
  note?: string;
  system?: Record<string, unknown>;
}

class Errors {
  // Check if the object conforms to the interface's shape.
  isValid(obj: unknown): obj is VlabsSDKError {
    return obj !== null && typeof obj === 'object' && 'code' in obj && typeof obj.code === 'number';
  }
}

export default new Errors();
