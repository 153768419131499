import { Alert } from '@mui/material';
import { Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';

export default function AnnounceError(props: { error: string[] | undefined }): JSX.Element | null {
  const { error } = props;
  const [key, setKey] = useState<number>();
  useEffect(() => {
    setKey((key ?? 0) + 1);
  }, [error]);
  return (
    ((error?.length ?? 0) > 0 && key && (
      <Alert key={`announce${key}`} className="screen-reader-only" severity="error" tabIndex={-1}>
        {error?.map((message) => <Typography key={message}>{message}</Typography>)}
      </Alert>
    )) ||
    null
  );
}
