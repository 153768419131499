import { Theme, extendTheme } from '@mui/joy/styles';
import { Config } from '../types/config';
import { ThemeStyleV2 } from './theme';

const extendJoyTheme = (config: Config): Theme => {
  const themeV2: ThemeStyleV2 = config.style.theme as ThemeStyleV2;

  const defaultSize: 'sm' | 'md' | 'lg' = 'md';

  const themeRadius = themeV2?.radius;
  const themeLightPalette = themeV2?.color_schemes?.light?.palette;
  const themeTypography = themeV2?.typography;

  // components
  const themeButton = themeV2?.components?.button;
  const themeInput = themeV2?.components?.input;
  const themeCheckbox = themeV2?.components?.checkbox;
  const themeFormControl = themeV2?.components?.form_control;

  // const preTheme: CssVarsThemeOptions = { ... }
  // TODO Remove all undefined values, as these will kill the theme's value

  return extendTheme({
    cssVarPrefix: 'smt',
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 856, // Desktop
        lg: 1200,
        xl: 1536,
      },
    },
    radius: { sm: themeRadius }, // TODO: Follow up on GitHub issue
    fontSize: {
      ...themeV2.font_size,
    },
    fontWeight: {
      ...themeV2.font_weight,
    },
    fontFamily: {
      display: themeV2.font_family?.display, // applies to `h1`–`h4`
      body: themeV2.font_family?.body, // applies to `title-*` and `body-*`
    },
    colorSchemes: {
      light: {
        palette: {
          background: { ...themeLightPalette?.background },
          primary: { ...themeLightPalette?.primary },
          neutral: { ...themeLightPalette?.neutral },
          success: { ...themeLightPalette?.success },
          warning: { ...themeLightPalette?.warning },
          danger: { ...themeLightPalette?.danger },
          text: { ...themeLightPalette?.text },
        },
      },
    },
    components: {
      // JoyList: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: 'var(--smt-palette-background-body)',
      //     },
      //   },
      // },
      // JoyListItem: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: 'transparent',
      //     },
      //   },
      // },
      // JoyListItem: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: 'transparent',
      //     },
      //   },
      // },
      // JoyListItemButton: {
      //   styleOverrides: {
      //     root: {
      //       color: 'red',
      //       backgroundColor: 'green',
      //       '&:hover': {
      //         backgroundColor: 'green',
      //       },
      //     },
      //   },
      // },
      JoyTypography: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            '& span a': {
              color: themeLightPalette?.link?.unvisited ?? 'inherit', // Inherit the text color from the parent Typography component
              textDecoration: 'underline',
              '&:hover': {
                color: themeLightPalette?.link?.unvisited ?? 'inherit',
                textDecoration: 'underline',
              },
              '&:visited': {
                color: themeLightPalette?.link?.visited ?? 'inherit',
              },
              '&:active': {
                color: themeLightPalette?.link?.active ?? 'inherit',
              },
            },

            [theme.breakpoints.down('md')]: {
              ...(ownerState.level === 'h3' && {
                color: themeTypography?.h3?.font_color,
                fontSize: themeTypography?.h3?.font_size,
                fontWeight: themeTypography?.h3?.font_weight,
                fontFamily: themeTypography?.h3?.font_family,
              }),
              ...(ownerState.level === 'h4' && {
                color: themeTypography?.h4?.font_color,
                fontSize: themeTypography?.h4?.font_size,
                fontWeight: themeTypography?.h4?.font_weight,
                fontFamily: themeTypography?.h4?.font_family,
              }),
              ...(ownerState.level === 'title-lg' && {
                color: themeTypography?.['title-lg']?.font_color,
                fontSize: themeTypography?.['title-lg']?.font_size,
                fontWeight: themeTypography?.['title-lg']?.font_weight,
                fontFamily: themeTypography?.['title-lg']?.font_family,
              }),
              ...(ownerState.level === 'title-md' && {
                color: themeTypography?.['title-md']?.font_color,
                fontSize: themeTypography?.['title-md']?.font_size,
                fontWeight: themeTypography?.['title-md']?.font_weight,
                fontFamily: themeTypography?.['title-md']?.font_family,
              }),
              ...(ownerState.level === 'title-sm' && {
                color: themeTypography?.['title-sm']?.font_color,
                fontSize: themeTypography?.['title-sm']?.font_size,
                fontWeight: themeTypography?.['title-sm']?.font_weight,
                fontFamily: themeTypography?.['title-sm']?.font_family,
              }),
              ...(ownerState.level === 'body-lg' && {
                color: themeTypography?.['body-lg']?.font_color,
                fontSize: themeTypography?.['body-lg']?.font_size,
                fontWeight: themeTypography?.['body-lg']?.font_weight,
                fontFamily: themeTypography?.['body-lg']?.font_family,
              }),
              ...(ownerState.level === 'body-md' && {
                color: themeTypography?.['body-md']?.font_color,
                fontSize: themeTypography?.['body-md']?.font_size,
                fontWeight: themeTypography?.['body-md']?.font_weight,
                fontFamily: themeTypography?.['body-md']?.font_family,
              }),
              ...(ownerState.level === 'body-sm' && {
                color: themeTypography?.['body-sm']?.font_color,
                fontSize: themeTypography?.['body-sm']?.font_size,
                fontWeight: themeTypography?.['body-sm']?.font_weight,
                fontFamily: themeTypography?.['body-sm']?.font_family,
              }),
              ...(ownerState.level === 'body-xs' && {
                color: themeTypography?.['body-xs']?.font_color,
                fontSize: themeTypography?.['body-xs']?.font_size,
                fontWeight: themeTypography?.['body-xs']?.font_weight,
                fontFamily: themeTypography?.['body-xs']?.font_family,
              }),
              ...(ownerState.level === 'footer' && {
                color: themeTypography?.footer?.font_color,
                fontSize: themeTypography?.footer?.font_size,
                fontWeight: themeTypography?.footer?.font_weight,
                fontFamily: themeTypography?.footer?.font_family,
              }),
            },
          }),
        },
      },
      JoyButton: {
        defaultProps: {
          size: defaultSize,
          fullWidth: true,
        },
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...(ownerState.size === 'xl' && {
              '--Icon-fontSize': '2rem',
              '--Button-gap': '1rem',
              minHeight: 'var(--Button-minHeight, 4rem)',
              fontSize: theme.vars.fontSize.xl,
              paddingBlock: '0.5rem',
              paddingInline: '2rem',
            }),
            ...(ownerState.size === 'lg' && {
              // ...(ownerState.variant !== 'plain' &&
              //   ownerState.variant !== 'soft' && {
              //     borderRadius: themeButton?.size?.large?.border_radius,
              //     borderStyle: themeButton?.size?.large?.border_style,
              //     borderWidth: themeButton?.size?.large?.border_thickness,
              //   }),
              height: themeButton?.size?.large?.height,
              width: themeButton?.size?.large?.width,
              lineHeight: themeButton?.size?.large?.line_height,
              paddingLeft: themeButton?.size?.large?.padding_left,
              paddingRight: themeButton?.size?.large?.padding_right,
              paddingTop: themeButton?.size?.large?.padding_top,
              paddingBottom: themeButton?.size?.large?.padding_bottom,
              marginLeft: themeButton?.size?.large?.margin_left,
              marginRight: themeButton?.size?.large?.margin_right,
              marginTop: themeButton?.size?.large?.margin_top,
              marginBottom: themeButton?.size?.large?.margin_bottom,
              fontWeight: themeButton?.size?.large?.font_weight,
              fontSize: themeButton?.size?.large?.font_size,
              fontFamily: themeButton?.size?.large?.font_family,
            }),
            ...(ownerState.size === 'md' && {
              // ...(ownerState.variant !== 'plain' && {
              //   borderRadius: themeButton?.size?.medium?.border_radius,
              //   borderStyle: themeButton?.size?.medium?.border_style,
              //   borderWidth: themeButton?.size?.medium?.border_thickness,
              // }),
              height: themeButton?.size?.medium?.height,
              width: themeButton?.size?.medium?.width,
              paddingLeft: themeButton?.size?.medium?.padding_left,
              paddingRight: themeButton?.size?.medium?.padding_right,
              paddingTop: themeButton?.size?.medium?.padding_top,
              paddingBottom: themeButton?.size?.medium?.padding_bottom,
              marginLeft: themeButton?.size?.medium?.margin_left,
              marginRight: themeButton?.size?.medium?.margin_right,
              marginTop: themeButton?.size?.medium?.margin_top,
              marginBottom: themeButton?.size?.medium?.margin_bottom,
              fontWeight: themeButton?.size?.medium?.font_weight,
              fontSize: themeButton?.size?.medium?.font_size,
              fontFamily: themeButton?.size?.medium?.font_family,
              lineHeight: themeButton?.size?.medium?.line_height,
            }),
            ...(ownerState.variant === 'auth' && {
              color: '#3F3F3F',
              background: '#ffffff',
              border: '1px solid #ececec',
              margin: '6px 0',
              '&:hover': {
                background: '#f5f5f5',
              },
            }),
          }),
        },
      },
      JoyInput: {
        defaultProps: {
          size: defaultSize,
        },
        styleOverrides: {
          // root: ({ ownerState, theme }) => ({
          root: () => ({
            height: themeInput?.height,
            // borderStyle: themeInput?.border_style,
            // borderColor: themeInput?.border_color,
            // borderWidth: themeInput?.border_width,
            // backgroundColor: themeInput?.background_color,
            // paddingLeft: themeInput?.padding_left,
            // paddingRight: themeInput?.padding_right,
            // paddingTop: themeInput?.padding_top,
            // paddingBottom: themeInput?.padding_bottom,
            // marginLeft: themeInput?.margin_left,
            // marginRight: themeInput?.margin_right,
            // marginTop: themeInput?.margin_top,
            // marginBottom: themeInput?.margin_bottom,
            // '--Input-radius': themeInput?.border_radius,
            // '--Input-decoratorChildHeight': `40px`,
          }),
        },
      },
      JoySelect: {
        defaultProps: {
          size: defaultSize,
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.size === 'md' && {
              minHeight: '44px',
            }),
          }),
        },
      },
      JoyCheckbox: {
        defaultProps: {
          size: defaultSize,
        },
        styleOverrides: {
          root: () => ({
            marginTop: themeCheckbox?.margin_top,
            marginRight: themeCheckbox?.margin_right,
            marginBottom: themeCheckbox?.margin_bottom,
            marginLeft: themeCheckbox?.margin_left,
            paddingTop: themeCheckbox?.padding_top,
            paddingRight: themeCheckbox?.padding_right,
            paddingBottom: themeCheckbox?.padding_bottom,
            paddingLeft: themeCheckbox?.padding_left,
          }),
          checkbox: {
            color: themeCheckbox?.color,
            // backgroundColor: themeCheckbox?.background_color, // breaks the checkbox
          },
        },
      },
      JoyModalDialog: {
        defaultProps: {
          size: defaultSize,
        },
      },
      JoyFormControl: {
        defaultProps: {
          size: defaultSize,
        },
      },
      JoyFormLabel: {
        styleOverrides: {
          root: {
            color: themeFormControl?.label?.color,
          },
        },
      },
      JoyFormHelperText: {
        styleOverrides: {
          root: {
            color: themeFormControl?.helper?.color,
          },
        },
      },
    },
    typography: {
      footer: {
        fontSize: '0.75rem', // 'var(--joy-fontSize-xs)',
        lineHeight: 'var(--joy-lineHeight-xs)',
        marginBottom: '3px',
      },
    },
  });
};

export default extendJoyTheme;
