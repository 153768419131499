import Log from '../../util/log';

interface IFrameProps {
  title: string;
  src: string;
  dataSource: string;
  onMessageReceived?: (event: MessageEvent) => void;
}

function setupIframe({ title, src, dataSource, onMessageReceived }: IFrameProps): () => void {
  const iframe = document.createElement('iframe');
  iframe.title = title;
  iframe.src = src;
  iframe.style.display = 'none';
  iframe.ariaHidden = 'true';
  iframe.tabIndex = -1;

  const handleMessage = (event: MessageEvent): void => {
    // handle messages received from the iframe
    if (event.data.source === dataSource) {
      Log.info('Message received from iframe:', event.data);
      onMessageReceived?.(event);
    }
  };

  window.addEventListener('message', handleMessage);
  document.body.appendChild(iframe);

  // return a cleanup function to remove event listener when done
  return () => {
    window.removeEventListener('message', handleMessage);
    document.body.removeChild(iframe);
  };
}

export default setupIframe;
