import { WafCaptchaModel } from './model';
import Log from '../../util/log';

export type WafCaptchaPresenterType = {
  onAttach: () => void;
  onDetach: () => void;
  onLoad: (element: HTMLDivElement) => void;
};
export default class WafCaptchaPresenter implements WafCaptchaPresenterType {
  model: WafCaptchaModel;

  onSuccess: () => void;

  onBackDispose?: () => void;

  element?: HTMLDivElement;

  apiKey: string;

  constructor(model: WafCaptchaModel, apiKey: string, onSuccess: () => void) {
    this.model = model;
    this.apiKey = apiKey;
    this.onSuccess = onSuccess;
  }

  onLoad(element: HTMLDivElement): void {
    if (element !== this.element) {
      this.element = element;
      AwsWafCaptcha.renderCaptcha(element, {
        apiKey: this.apiKey,
        onSuccess: this.onSuccess,
        onError: (error) => {
          Log.warn(error);
        },
      });
    }
  }

  onAttach(): void {}

  onDetach(): void {}
}
