import Log from './log';

export default function addCurrentSearchParamsToUrl(iframeUrl: string): string {
  const url = new URL(iframeUrl);

  // check the origin of the message
  if (
    !(
      url.origin === 'https://cdn-alpha.smartmedialabs.io' ||
      url.origin === 'https://cdn-beta.smartmedialabs.io' ||
      url.origin === 'https://cdn.smartmedialabs.io'
    )
  ) {
    Log.error(
      'Invalid iframe origin. Expected: cdn-*.smartmedialabs.io. Search params will not be appended.',
    );
    return url.toString();
  }

  // define the parameters that should not be appended to the new URL
  const excludedParams = ['password', 'token'];

  // append current search params to the new URL
  const currentSearchParams = new URLSearchParams(window.location.search);
  currentSearchParams.forEach((value, key) => {
    if (!excludedParams.includes(key)) {
      url.searchParams.append(key, value);
    }
  });
  return url.toString();
}
