import React, { HTMLInputTypeAttribute } from 'react';
import clsx from 'clsx';
import { Button, Input } from '@mui/joy';
import { useTranslation } from 'react-i18next';

interface IndexProps {
  className?: string;
  leftText?: string | null;
  disableLeft?: boolean;
  onLeftClick?: () => void;
  value?: string;
  placeholder?: string | null;
  type?: HTMLInputTypeAttribute;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  disabled?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  error?: boolean;
}

function InputLegacy({
  className = undefined,
  leftText = undefined,
  disableLeft = undefined,
  onLeftClick = undefined,
  value = undefined,
  placeholder = undefined,
  type = undefined,
  onChange = undefined,
  onBlur = undefined,
  disabled = undefined,
  inputRef = undefined,
  error = false,
}: IndexProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Input
      className={clsx('input-field', className)}
      value={value}
      placeholder={placeholder ?? ''}
      autoCapitalize="none"
      autoCorrect="off"
      type={type}
      onChange={(event): void => {
        if (!disabled) {
          onChange?.(event.target.value);
        }
      }}
      onBlur={(event): void => {
        if (!disabled) {
          onBlur?.(event.target.value);
        }
      }}
      disabled={disabled}
      slotProps={{ input: { ref: inputRef } }}
      startDecorator={
        leftText && (
          <>
            <div aria-live="polite" className="screen-reader-only">
              {t('common.token_button.aria_country_code_picker_available')}
            </div>
            <div aria-live="polite">
              <Button
                aria-label={
                  t('common.token_button.aria_label_choose_country_code') + (leftText ?? '')
                }
                aria-expanded={!!leftText}
                aria-haspopup="dialog"
                sx={{
                  height: 'inherit',
                  m: 0,
                  px: '4px',
                }}
                variant="plain"
                disabled={disableLeft}
                onClick={(): void => {
                  if (!disabled && !disableLeft) {
                    onLeftClick?.();
                  }
                }}
              >
                {leftText}
              </Button>
            </div>
          </>
        )
      }
      error={error}
    />
  );
}

export default InputLegacy;
