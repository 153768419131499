import DOMPurify from 'dompurify';
import { TFunction } from 'i18next';

export default function configureDomPurify(t: TFunction, enableAccessibility: boolean): void {
  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener noreferrer');
    }

    if (enableAccessibility) {
      // check if the node is an <a> element and has a target attribute with the value "_blank"
      if (node.nodeName.toLowerCase() === 'a' && node.getAttribute('target') === '_blank') {
        // insert accessibility text and icon (Links Opening in New Tabs (INT-WEB-006-04))
        node.insertAdjacentHTML(
          'beforeend',
          ` <span class="material-icons inherit" aria-hidden="true">open_in_new</span><span class="screen-reader-only">${t('common.opens_in_new_tab')}</span>`,
        );
      }
    }
  });
}
