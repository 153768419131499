import React from 'react';
import { Grid, Select, Option } from '@mui/joy';
import i18next, { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { FormItem } from '../../types/config';
import Log from '../../util/log';
import FormControlWrapper from '../../components/form/form-control-wrapper';
import Checkbox from '../../components/checkbox';

interface AdditionalProps {
  t: TFunction;
  formItems: FormItem[];
  onItemChange: (item: FormItem) => void;
}

function AdditionalForm(props: AdditionalProps): JSX.Element | null {
  const { t, formItems, onItemChange } = props;

  // ensure we recognize the type
  if (formItems?.find((item) => item.type !== 'checkbox' && item.type !== 'dropdown')) {
    Log.error('Unrecognized from type');
    return null;
  }

  return (
    <>
      {formItems.map((item) => {
        // parse strings
        const localePath = `claim.form_item.${item.id}`;
        const label = i18next.exists(`${localePath}.label`) ? t(`${localePath}.label`) : undefined;
        const placeholder = i18next.exists(`${localePath}.placeholder`)
          ? t(`${localePath}.placeholder`)
          : undefined;
        const helper = i18next.exists(`${localePath}.helper`)
          ? t(`${localePath}.helper`)
          : undefined;

        return (
          <Grid xs={12} key={item.id}>
            <FormControlWrapper
              required={item.required}
              label={label}
              helper={helper}
              error={item.error}
              size="lg"
            >
              {item.type === 'checkbox' && (
                <Checkbox
                  id={item.id}
                  size="lg"
                  label={t(`${localePath}.text`)}
                  checked={item.value ?? false}
                  onToggle={(value: boolean): void => {
                    const copy = item;
                    copy.value = value;
                    onItemChange(copy);
                  }}
                />
              )}
              {item.type === 'dropdown' && (
                <Select
                  id={item.id}
                  size="lg"
                  value={item.value}
                  placeholder={placeholder}
                  onChange={(event: React.SyntheticEvent | null, newValue): void => {
                    const copy = item;
                    copy.value = newValue;
                    onItemChange(copy);
                  }}
                >
                  {item.options.map((option) => (
                    <Option key={option.name} value={option.value}>
                      {t(`${localePath}.option.${option.name}`)}
                    </Option>
                  ))}
                </Select>
              )}
            </FormControlWrapper>
          </Grid>
        );
      })}
    </>
  );
}

export default withTranslation()(AdditionalForm);

// eslint-disable-next-line no-lone-blocks
{
  /* <FormWrapper label={textForPath(`${page}.landing.input_label_email`)}>
  <Input
    id="email"
    name="email"
    placeholder={textForPath(`${page}.landing.input_placeholder_email`)}
    size="lg"
    required
    fullWidth
    autoComplete="email"
  />
</FormWrapper>; */
}
