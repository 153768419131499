import React from 'react';
import Dialog from '@mui/material/Dialog';
import { withTranslation } from 'react-i18next';
import styles from './view.module.scss';
import { WafCaptchaModel } from './model';
import { WafCaptchaPresenterType } from './presenter';

type WafCaptchaViewProps = WafCaptchaModel & {
  presenter: WafCaptchaPresenterType;
  open: boolean;
};

function WafCaptchaView(props: WafCaptchaViewProps): JSX.Element {
  const { open, presenter } = props;
  return (
    <Dialog fullScreen open={open} scroll="paper">
      <div
        className={styles.container}
        ref={(element): void => {
          if (element) {
            presenter.onLoad(element);
          }
        }}
      />
    </Dialog>
  );
}
export default withTranslation()(WafCaptchaView);
