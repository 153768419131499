import { Button } from '@mui/joy';
import React, { useEffect, useState } from 'react';

interface Props {
  className?: string;
  disabled?: boolean;
  text: string;
  onClick: () => void;
}

function SamsungButton({ className = undefined, disabled = false, ...props }: Props): JSX.Element {
  const { text, onClick } = props;
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const loadFont = async (): Promise<void> => {
      // dynamically import the font CSS to ensure code splitting
      await import('./font.module.css');
      setFontLoaded(true);
    };

    loadFont();
  }, []);

  return (
    <Button
      className={className}
      size="lg"
      variant="auth"
      disabled={disabled}
      onClick={onClick}
      fullWidth
      style={{ paddingLeft: '16px', paddingRight: '16px' }}
    >
      <span
        style={{
          fontFamily: fontLoaded ? 'SamsungSharpSans-Bold' : undefined,
          fontWeight: 'bold',
          fontSize: '18px',
        }}
      >
        {text}
        <span
          style={{
            color: '#1428A0',
          }}
        >
          {' Samsung'}
        </span>
      </span>
    </Button>
  );
}

export default SamsungButton;
