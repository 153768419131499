import * as React from 'react';
import Button from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { ColorPaletteProp } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useId } from 'react';
import { useMediaQuery } from 'usehooks-ts';

interface AlertModalProps {
  title: string;
  content: React.ReactNode;
  open: boolean;
  isActionDisabled?: boolean;
  actionTitle?: string;
  actionColor?: ColorPaletteProp;
  onAction: VoidFunction;
  closeTitle: string;
  onClose: VoidFunction;
  /** Description of the modal, generally the id of the content's description. */
  ariaDescribedby: string;
  /** alertdialog role is used to notify the user of urgent information that demands the user's immediate attention. */
  role: 'dialog' | 'alertdialog';
}

export default function AlertDialog({
  actionTitle = undefined,
  isActionDisabled = false,
  actionColor = 'primary',
  ...props
}: AlertModalProps): JSX.Element {
  const isHeightConstrained = useMediaQuery('(max-height: 450px)');
  const id = useId();
  const { t } = useTranslation();
  const { title, content, open, onAction, closeTitle, onClose, ariaDescribedby, role } = props;

  const showActionButton = actionTitle && actionTitle.length > 0;
  const dialogContentRef = React.useRef<HTMLDivElement>(null);

  // const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
  //   /*
  //    If the dialog's content contains interactive elements like inputs and checkboxes,
  //    handling the Enter key directly might interfere with the normal behavior of those
  //    elements, such as focusing on inputs or toggling checkboxes.
  //   */

  //   // check if the event target is within the dialog content
  //   if (!(dialogContentRef.current && dialogContentRef.current.contains(event.target as Node))) {
  //     if (event.key === 'Enter') {
  //       if (showActionButton && !isActionDisabled) {
  //         onAction();
  //       } else if (!showActionButton) {
  //         onClose();
  //       }
  //       // enter should only be allowed to trigger onClose if there is no action button shown
  //     }
  //   }
  // };

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        // Modals Prevent Clicks Outside (INT-WEB-004-07)
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      // onKeyDown={(e) => handleKeyDown(e)}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-${ariaDescribedby}`}
    >
      <ModalDialog
        maxWidth="480px"
        size="md"
        role={role}
        layout={isHeightConstrained ? 'fullscreen' : 'center'}
        variant="plain"
      >
        <DialogTitle id={`${id}-title`} sx={{ justifyContent: 'center' }}>
          {title}
        </DialogTitle>
        <DialogContent ref={dialogContentRef}>{content}</DialogContent>
        <DialogActions sx={{ flexWrap: 'wrap' }}>
          {showActionButton && (
            <Button
              size="sm"
              color={actionColor ?? 'neutral'}
              disabled={isActionDisabled}
              onClick={(): void => {
                onAction();
              }}
              fullWidth={false}
              aria-label={t('common.modal_button_continue_aria_label')}
              sx={{ width: 'fit-content', flexGrow: 1 }}
            >
              {actionTitle}
            </Button>
          )}
          <Button
            size="sm"
            color={showActionButton ? 'neutral' : 'primary'}
            onClick={(): void => onClose()}
            fullWidth={false}
            aria-label={t('common.modal_button_close_aria_label')}
            sx={{ width: 'fit-content', flexGrow: 1 }}
          >
            {closeTitle}
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
}
