import React, { ReactNode } from 'react';
import DOMPurify from 'dompurify';

interface Props {
  className: string;
  children: ReactNode;
}

function SafeHtmlComponent({ className, children }: Props): JSX.Element {
  const sanitizedHtml = DOMPurify.sanitize(children as string);

  // eslint-disable-next-line react/no-danger
  return <div className={className} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
}

export default SafeHtmlComponent;
