import React, { useState, useRef, useEffect } from 'react';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  onLoad?: () => void;
}

function Image({ src, alt, onLoad = undefined, ...props }: ImageProps): JSX.Element {
  const [source, setSource] = useState<string | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const element = imgRef.current;
    if (element) {
      const styles = window.getComputedStyle(element);
      let content = styles?.content;
      if (content && content.startsWith('url(') && content.endsWith(')')) {
        content = content.replace('url(', '');
        content = content.substring(0, content.length - 1);
        content = content.replaceAll('"', '').replaceAll("'", '');
        if (content !== source) {
          setSource(content);
        }
      }
    }
  }, [source]);

  const handleLoad = (): void => {
    if (onLoad) {
      onLoad();
    }
  };

  return (
    <img alt={alt} ref={imgRef} onLoad={handleLoad} {...props} src={(src || source) ?? undefined} />
  );
}

export default Image;
