import { Button } from '@mui/joy';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TFunction } from 'i18next';
import React, { useRef, useId } from 'react';
import { withTranslation } from 'react-i18next';
import BaseView from '../../../views/view-base';
import { StateModel } from './model';
import Presenter from './presenter';
import styles from './view.module.scss';
import FormControlWrapper from '../../../components/form/form-control-wrapper';
import MobileDatePicker from '../../../components/mobile-date-picker';
import AnnounceError from '../../../components/announce-error';

interface ViewProps extends StateModel {
  t: TFunction;
  presenter: Presenter;
  format: string;
}

function View(props: ViewProps): JSX.Element {
  const { t, selectedDate, presenter, format, error } = props;

  let dateFormat = 'MM/DD/YYYY';
  if (format === 'dmy') {
    dateFormat = 'DD/MM/YYYY';
  }

  const dobInputId = useId();
  const required = true;
  const firstFieldRef = useRef<HTMLInputElement>(null);
  return (
    <BaseView
      prefix="age-landing"
      headerText={t('age.landing.input_label')}
      autoFocusHeader
      footerText={t('claim.landing.footer')}
      topOffset="0px"
    >
      <div className={styles.spacer1} role="none" />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormControlWrapper
          label={t('age.landing.input_label')}
          required={required}
          error={!!error}
          helper={error && t(error[0])}
          sx={{ minHeight: 'fit-content', width: '100%' }}
          inputId={dobInputId}
        >
          <MobileDatePicker
            openTo="year"
            disableFuture
            value={selectedDate}
            format={dateFormat}
            onChange={(newValue): void => presenter.onBirthdayChange(newValue)}
            onTextFieldCloseBlur={() => presenter.onBirthdayBlur()}
            slotProps={{
              textField: {
                inputRef: firstFieldRef,
                error: !!error,
                required,
                inputProps: {
                  id: dobInputId,
                  'aria-describedby': error ? `${dobInputId}-helper-text` : undefined,
                },
              },
            }}
          />
        </FormControlWrapper>
      </LocalizationProvider>
      {error && <AnnounceError error={[t(error[0])]} />}
      <div className={styles.spacer2} role="none" />
      <Button
        className="age-landing-button"
        onClick={(): void => presenter.onNextClick(firstFieldRef)}
      >
        {t('age.landing.button_text')}
      </Button>
      <div className={styles.spacer4} role="none" />
    </BaseView>
  );
}

export default withTranslation()(View);
