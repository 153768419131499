import { useEffect } from 'react';

const useScrollAndFocusAnchor = (dependency: React.DependencyList | undefined): void => {
  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      e.preventDefault(); // Prevent default behavior of the anchor element

      const anchor = e.target as HTMLAnchorElement;
      if (anchor && anchor.getAttribute) {
        const targetId = anchor.getAttribute('href');
        if (targetId) {
          const cleanTargetId = targetId.substring(1);
          const targetElement = document.getElementById(cleanTargetId);

          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
            targetElement.focus({ preventScroll: true });
          }
        }
      }
    };

    document.querySelectorAll<HTMLAnchorElement>('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', handleClick);
    });

    return (): void => {
      document.querySelectorAll<HTMLAnchorElement>('a[href^="#"]').forEach((anchor) => {
        anchor.removeEventListener('click', handleClick);
      });
    };
  }, [dependency]);
};

export default useScrollAndFocusAnchor;
