import { CssBaseline } from '@mui/joy';
import { CssVarsProvider as JoyCssVarsProvider, Theme as JoyTheme } from '@mui/joy/styles';
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme,
} from '@mui/material/styles';
import i18n, { InitOptions, Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from 'react';
import { initReactI18next } from 'react-i18next';
import './app.module.scss';
import createOTPInputThemeStyles from './components/otp-input/style-loader-theme';
import createClaimMultiThemeStyle from './features/claim/style-loader-theme';
import createGeoBlockerThemeStyle from './features/geo-blocker/style-loader-theme';
import createLoginMultiThemeStyle from './features/login/style-loader-theme';
import createUnsubscribeMultiThemeStyle from './features/unsubscribe/style-loader-theme';
import LocaleEn from './resources/locales/en.json';
import createThemeStyle from './theme/style-loader-theme';
import extendJoyThemeV1 from './theme/theme-joy-v1';
import extendJoyThemeV2 from './theme/theme-joy-v2';
import extendMaterialTheme from './theme/theme-material';
import { AppPage } from './types/common';
import { Config } from './types/config';
import AlertProvider from './util/alert/alert-provider';
import deepMerge from './util/deep-merge';
import configureDomPurify from './util/dompurifyConfig';
import loadScript from './util/load-script';
import Log from './util/log';
import { sanitizeCdnUrl } from './util/validation';
import createAnchorViewThemeStyles from './views/view-anchor/style-loader-theme';
import createBaseViewStyles from './views/view-base/style-loader-theme';

interface AppProps {
  path: string;
  page: AppPage;
  children: (config: Config) => JSX.Element;
}

export default class App extends React.Component<AppProps, { config?: Config }> {
  config?: Config;

  joyTheme?: JoyTheme;

  materialTheme?: ReturnType<typeof experimental_extendTheme>;

  constructor(props: AppProps) {
    super(props);
    // Setup state
    this.state = {};
  }

  componentDidMount(): void {
    const { path, page } = this.props;
    fetch(`${path}public/config/config.json`, {
      method: 'GET',
      cache: 'no-store',
    })
      .then((result) => result.json())
      .then((config) => {
        const {
          global_config_path: globalConfigPath,
          locales,
          default_language: defaultLanguage,
        } = config;
        if (locales !== undefined && (locales === null || Array.isArray(locales))) {
          // eslint-disable-next-line no-param-reassign
          config.locales = {};
        }
        if (
          defaultLanguage === null ||
          (Array.isArray(defaultLanguage) && defaultLanguage.length === 0) ||
          defaultLanguage === ''
        ) {
          // eslint-disable-next-line no-param-reassign
          delete config.default_language;
        }
        if (globalConfigPath) {
          return fetch(`${globalConfigPath}`, { method: 'GET', cache: 'no-store' })
            .then((result) => result.json())
            .catch(() => ({}))
            .then((globalConfig) => {
              return deepMerge(globalConfig, config);
            });
        }
        return config;
      })
      .then(async (config) => {
        const { lab_sdk_url: labSdkUrl } = config;

        // Load the vlabs sdk
        if (labSdkUrl && labSdkUrl.length > 0) {
          await loadScript(labSdkUrl, undefined, { 'data-ot-ignore': '' });
          return config;
        }

        // Load fallback (live cdn)
        const fallbackUrl =
          page === 'unsubscribe'
            ? `https://cdn.smartmedialabs.io/s/js/vlabs-unsubscribe.js`
            : `https://cdn.smartmedialabs.io/s/js/vlabs-user.js`;

        Log.error(
          `Config Error: Missing 'lab_sdk_url'. Falling back on hardcoded url ${fallbackUrl}`,
        );

        await loadScript(fallbackUrl, undefined, { 'data-ot-ignore': '' });
        return config;
      })
      .then(async (config) => {
        // cookie consents
        if (page !== 'theme') {
          // find OneTrust API key based on domain
          const domainKeys = {
            '.smartmediawallet.app': '63de1bb4-71b3-4250-b289-91984ba6e0a3',
            '.smartwallet.app': '9c9cf5af-db55-47da-bf82-3b80f627e7b2',
            '.olympicrewards.com': '018f5c67-208c-7deb-a473-1a0dcfb9b83f',
            legacy: 'cd2e990d-f529-43a0-9d9f-5b2c05e96047',
          };
          const domain = window.location.hostname;
          const matchingDomain = Object.entries(domainKeys).find(([key]) => domain.endsWith(key));
          let oneTrustApiKey = matchingDomain ? matchingDomain[1] : domainKeys.legacy;
          // append -test to the API key for alpha environment
          const { environment } = config;
          if (environment === 'alpha') {
            oneTrustApiKey = `${oneTrustApiKey}-test`;
          }
          Log.debug(
            'Using OneTrust API key for domain:',
            matchingDomain ? matchingDomain[0] : 'legacy',
          );
          // load scripts
          try {
            await Promise.all([
              loadScript(`https://cdn.cookielaw.org/consent/${oneTrustApiKey}/OtAutoBlock.js`),
              loadScript('https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', undefined, {
                'data-domain-script': oneTrustApiKey,
              }),
            ]);
          } catch (error: unknown) {
            Log.error('CookieLaw script(s) load failed.', error);
          }
        }

        return config;
      })
      .then(async (config): Promise<Config> => {
        // generate style sheet from config
        const { style } = config;
        const { external, theme } = style;
        const sheet = document.createElement('style');
        document.body.appendChild(sheet);
        const styleSheet = sheet.sheet;
        if (styleSheet) {
          style.fonts?.forEach((font) => {
            styleSheet.insertRule(
              `@font-face {font-family: ${font?.family};src: url(${font.src});}`,
              styleSheet.cssRules.length,
            );
          });

          if (theme) {
            createThemeStyle(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createAnchorViewThemeStyles(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createBaseViewStyles(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createOTPInputThemeStyles(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createGeoBlockerThemeStyle(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            // Page specific theme
            if (page === 'claim-multi') {
              createClaimMultiThemeStyle(theme).forEach((rule) => {
                styleSheet.insertRule(rule, styleSheet.cssRules.length);
              });
            } else if (page === 'login-multi') {
              createLoginMultiThemeStyle(theme).forEach((rule) => {
                styleSheet.insertRule(rule, styleSheet.cssRules.length);
              });
            } else if (page === 'unsubscribe-multi') {
              createUnsubscribeMultiThemeStyle(theme).forEach((rule) => {
                styleSheet.insertRule(rule, styleSheet.cssRules.length);
              });
            }
          }

          if (external?.length > 0) {
            await Promise.all(
              external.map((stylePath: string) => {
                return new Promise<Config | void>((resolve, reject) => {
                  const sanitizedStylePath = sanitizeCdnUrl(stylePath);
                  if (sanitizedStylePath === undefined) {
                    reject(new Error(`Style URL is untrusted: ${stylePath}`));
                    return;
                  }
                  const stylesheet = document.createElement('link');
                  stylesheet.rel = 'stylesheet';
                  stylesheet.type = 'text/css';
                  stylesheet.href = sanitizedStylePath.toString();
                  const timeout = setTimeout(() => {
                    resolve(config);
                    stylesheet.onload = null;
                    stylesheet.onerror = null;
                  }, 3000);
                  stylesheet.onload = (): void => {
                    clearTimeout(timeout);
                    stylesheet.onload = null;
                    stylesheet.onerror = null;
                    resolve();
                  };
                  stylesheet.onerror = (loadError): void => {
                    Log.error(loadError);
                    clearTimeout(timeout);
                    stylesheet.onload = null;
                    stylesheet.onerror = null;
                    resolve();
                  };
                  document.getElementsByTagName('head')[0].prepend(stylesheet);
                });
              }),
            );
            return config;
          }
          return Promise.resolve(config);
        }
        Log.error('Config setup failed: Missing stylesheet');
        throw new Error('Config setup failed: Missing stylesheet');
      })
      .then((config) => {
        this.config = config;
        const resources: Resource = {};
        const {
          locales = {},
          disable_built_in_language: disableBuiltInLanguage,
          supported_locales: supportedLocales,
          default_language: defaultLanguage,
        } = config;
        if (!disableBuiltInLanguage) {
          resources.en = { translation: LocaleEn };
        }

        let defaultLang: string | null = null;
        if (typeof defaultLanguage === 'string') {
          defaultLang = defaultLanguage;
        } else if (Array.isArray(defaultLanguage)) {
          [defaultLang] = defaultLanguage;
        }
        if (defaultLang && supportedLocales?.indexOf(defaultLang) === -1) {
          // eslint-disable-next-line no-param-reassign
          config.default_language = supportedLocales;
        }
        return Promise.all(
          // Map over each available languages in the config's locale section (generally set in the global config)
          Object.keys(locales).map(async (localeLngKey) => {
            // Don't fetch unsupported languages (but always fetch english)
            if (supportedLocales?.indexOf(localeLngKey) === -1 && localeLngKey !== 'en') {
              return Promise.resolve();
            }

            const { external, overrides } = locales[localeLngKey];

            // Fetch external translation files
            if (external?.length > 0) {
              Log.info('[Languages] Fetching external', external);
              try {
                const result = await fetch(external);
                const externalJson = await result.json();
                const mergedTranslations = deepMerge(externalJson, overrides);
                resources[localeLngKey] = { translation: mergedTranslations };
                // Handle en special case
                if (localeLngKey === 'en') {
                  // Use en as the key-only fallback
                  resources.fb = resources.en;
                  // If en isn't supported, delete it
                  if (supportedLocales && !supportedLocales.includes('en')) {
                    delete resources.en;
                  }
                }
              } catch {
                return {};
              }
            }
            return Promise.resolve();
          }),
        )
          .then(() => {
            const fallbackLng = [...(this.config?.default_language ?? []), 'fb'];
            const options: InitOptions = {
              debug: true,
              fallbackLng,
              detection: {
                order: ['localStorage', 'navigator', 'querystring', 'cookie', 'htmlTag'],
              },
              resources,
            };
            i18n.on('languageChanged', (language) => {
              document.documentElement.lang = language;
            });
            i18n.use(LanguageDetector).use(initReactI18next).init(options);
          })
          .then(() => {
            configureDomPurify(i18n.t, config.enable_vgar);
          })
          .then(() => {
            // configure JoyUI theme
            if (config.style.theme?.version) {
              this.joyTheme = extendJoyThemeV2(config);
            } else {
              // best-effort backwards compatibility
              this.joyTheme = extendJoyThemeV1(config);
            }

            // get locale to pass to material theme
            const locale = i18n.languages[0];
            // configure MaterialUI theme
            this.materialTheme = extendMaterialTheme(config, this.joyTheme, locale);

            return Promise.resolve(config);
          })
          .then(() => {
            this.setState({ config: this.config });
          });
      })
      .catch((error) => {
        Log.error('App load error:', error);
      });
  }

  render(): JSX.Element {
    const { config } = this.state;
    const { children } = this.props;

    const materialTheme = this.materialTheme
      ? { [MATERIAL_THEME_ID]: this.materialTheme }
      : undefined;

    return (
      <MaterialCssVarsProvider theme={materialTheme} defaultMode="light">
        <JoyCssVarsProvider theme={this.joyTheme} defaultColorScheme="light" defaultMode="light">
          <CssBaseline enableColorScheme />
          <AlertProvider>{config && children?.(config)}</AlertProvider>
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    );
  }
}

declare module '@mui/joy/styles' {
  interface PaletteBackground {
    // make the node `anchor_backdrop` configurable in `extendTheme`

    /** Desktop anchor backdrop */
    desktop_backdrop?: string;
  }

  interface Palette {
    // make the node `link` configurable in `extendTheme`
    link: {
      active?: string;
      hover?: string;
      visited?: string;
      unvisited?: string;
    };
  }

  interface TypographySystemOverrides {
    footer: true;
  }

  interface FontSizeOverrides {
    // used for the footer
    xxs: true;
  }
}

declare module '@mui/joy/Button' {
  interface ButtonPropsVariantOverrides {
    auth: true;
  }
  interface ButtonPropsSizeOverrides {
    xl: true;
  }
}
