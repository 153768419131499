import i18next from 'i18next';

/**
 * Convenience function to translate a key if it exists in the i18next resources.
 * @param {string} key The translation key to look up.
 * @returns {string | null} The translation string if the key exists, otherwise null.
 */
export function tIfExists(key: string): string | null {
  if (i18next.exists(key)) {
    return i18next.t(key);
  }
  return null;
}

/**
 * Convenience function to translate a key if it exists in the i18next resources and its value is not an empty string.
 * @param {string} key The translation key to look up.
 * @returns {string | null} The translation string if the key exists and is not an empty string, otherwise null.
 */
export function tIfExistsAndIsValid(key: string): string | null {
  if (i18next.exists(key)) {
    const translation = i18next.t(key);
    if (typeof translation === 'string' && translation.trim() !== '') {
      return i18next.t(key);
    }
  }
  return null;
}

/**
 * Checks if a translation key exists and its value is not an empty string.
 * @param {string} key The translation key to check.
 * @returns {boolean} True if the key exists and its value is not empty, otherwise false.
 */
export function keyExistsAndIsValid(key: string): boolean {
  if (i18next.exists(key)) {
    const translation = i18next.t(key);
    return typeof translation === 'string' && translation.trim() !== '';
  }
  return false;
}
