// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claim-ylx4pb-container{width:100%;height:100%;display:flex;flex-direction:column;align-items:center}.claim-QpJEin-innerContainer{display:flex;flex-direction:column;align-items:center;max-width:600px;width:100%;height:100%;box-sizing:border-box;position:relative;width:330px}.claim-QpJEin-innerContainer>*{z-index:1}.claim-v9XgT7-imageContainer{overflow:visible}.claim-vHfh4_-image{width:100%;height:auto;object-fit:contain}.claim-KKueXw-contextImageContainer{display:flex;justify-content:center;padding-top:21px;padding-bottom:21px}.claim-bOQost-contextImage{object-fit:contain;width:30%;height:auto}.claim-m7pv3M-linkText{text-align:center}`, "",{"version":3,"sources":["webpack://./src/features/claim/link/view.module.scss"],"names":[],"mappings":"AAAA,wBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CAGF,6BACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CACA,iBAAA,CACA,WAAA,CAGF,+BACE,SAAA,CAGF,6BACE,gBAAA,CAGF,oBACE,UAAA,CACA,WAAA,CACA,kBAAA,CAGF,oCACE,YAAA,CACA,sBAAA,CACA,gBAAA,CACA,mBAAA,CAGF,2BACE,kBAAA,CACA,SAAA,CACA,WAAA,CAGF,uBACE,iBAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.innerContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-width: 600px;\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  position: relative;\n  width: 330px;\n}\n\n.innerContainer > * {\n  z-index: 1;\n}\n\n.imageContainer {\n  overflow: visible;\n}\n\n.image {\n  width: 100%;\n  height: auto;\n  object-fit: contain;\n}\n\n.contextImageContainer {\n  display: flex;\n  justify-content: center;\n  padding-top: 21px;\n  padding-bottom: 21px;\n}\n\n.contextImage {\n  object-fit: contain;\n  width: 30%;\n  height: auto;\n}\n\n.linkText {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `claim-ylx4pb-container`,
	"innerContainer": `claim-QpJEin-innerContainer`,
	"imageContainer": `claim-v9XgT7-imageContainer`,
	"image": `claim-vHfh4_-image`,
	"contextImageContainer": `claim-KKueXw-contextImageContainer`,
	"contextImage": `claim-bOQost-contextImage`,
	"linkText": `claim-m7pv3M-linkText`
};
export default ___CSS_LOADER_EXPORT___;
