import { timer } from 'rxjs';
import Model from './model';
import { isHttps } from '../../../util/validation';

export default class Presenter {
  model: Model;

  redirect: string;

  redirectDelay: number;

  constructor(model: Model, redirect: string, redirectDelay: number) {
    this.model = model;
    this.redirect = redirect;
    this.redirectDelay = redirectDelay;
  }

  onAttach(): void {
    if (this.redirect && this.redirect.length > 0 && isHttps(this.redirect)) {
      timer(this.redirectDelay).subscribe(() => {
        window.location.href = this.redirect;
      });
    }
  }
}
