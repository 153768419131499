import { Component } from 'react';
import BaseModel, { createModel } from '../../../util/mvp/base-model';
import { Config } from '../../../types/config';

export type LandingModel = {
  loading: boolean;
  allowPhone: boolean;
  allowEmail: boolean;
  showFacebook: boolean;
  showSamsung: boolean;
  showGoogle: boolean;
  showOutsideOauth: boolean;
  showWalletConnect: boolean;
  showProve: boolean;
  oauthOnly: boolean;
  advertiserName: string;
  showPrimaryConsent: boolean;
  showMarketingConsent: boolean;
  marketingConsentTerms: string;
  isConsentPopupOpen: boolean;
  collapseCard: boolean;
  hideAuth: boolean;
  showLoginLink: boolean;
};

export default function createLandingModel(
  component: Component,
  config: Config,
  showProveFallback: boolean,
): BaseModel<LandingModel> & LandingModel {
  const showProve = config.prove?.enabled ?? false;

  const { marketing: marketingConsent } = config.claim.consent ?? {};

  const { allow_phone: allowPhone, allow_email: allowEmail } = config.token;

  const initialState = {
    loading: false,
    allowPhone: false,
    allowEmail: false,
    showFacebook: false,
    showSamsung: false,
    showGoogle: false,
    showOutsideOauth: false,
    showWalletConnect: false,
    showProve,
    oauthOnly: !!config.oauth_only,
    advertiserName: '',
    showPrimaryConsent: true,
    showMarketingConsent: marketingConsent?.enabled ?? false,
    marketingConsentTerms: marketingConsent?.terms ?? '',
    isConsentPopupOpen: false,
    collapseCard: false,
    hideAuth: config.hide_auth,
    showLoginLink: false,
  };

  // check if Prove is enabled and were not showing the fallback
  const proveEnabled = config.prove?.enabled ?? false;

  if (proveEnabled) {
    if (showProveFallback) {
      initialState.showProve = false;
      initialState.allowPhone = allowPhone;
      initialState.allowEmail = allowEmail;
      initialState.showGoogle = !!config.google_app_id;
      initialState.showSamsung = !!config.samsung_app_id && !!config.samsung_alias_id;
      initialState.showFacebook = !!config.facebook_app_id;
      initialState.showOutsideOauth = !!config.outside_app_id;
      initialState.showWalletConnect = !!config.wallet_connect?.project_id;
    } else {
      initialState.showProve = true;
      initialState.allowPhone = false;
      initialState.allowEmail = false;
      initialState.showGoogle = false;
      initialState.showSamsung = false;
      initialState.showFacebook = false;
      initialState.showOutsideOauth = false;
      initialState.showWalletConnect = false;
    }
  } else {
    initialState.allowPhone = allowPhone;
    initialState.allowEmail = allowEmail;
    initialState.showGoogle = !!config.google_app_id;
    initialState.showSamsung = !!config.samsung_app_id && !!config.samsung_alias_id;
    initialState.showFacebook = !!config.facebook_app_id;
    initialState.showOutsideOauth = !!config.outside_app_id;
    initialState.showWalletConnect = !!config.wallet_connect?.project_id;
  }

  return createModel<LandingModel>(component, initialState);
}
