import React, { Component } from 'react';
import Model, { StateModel } from './model';
import View from './view';
import Presenter from './presenter';
import Alert from '../../util/alert';
import { CountryInfo } from '../../util/countries';

export interface IndexProps extends StateModel {
  onClose: VoidFunction | null;
  onComplete: (country: CountryInfo) => void;
  onError: (error: string) => void;
  regions: string[];
  open: boolean;
  selectedCountry: CountryInfo | null;
}

export default class CountryPopup extends Component<IndexProps> {
  static show(
    selectedCountry: CountryInfo | null,
    regions: string[],
    onClose: VoidFunction | null,
    onComplete: (country: CountryInfo) => void,
    onError?: (error: string) => void,
  ): void {
    Alert.show(
      (id: React.Key, closeAlert: VoidFunction) =>
        (open: boolean): JSX.Element =>
          CountryPopup.createPopup(
            id,
            open,
            selectedCountry,
            regions,
            closeAlert,
            onComplete,
            onClose,
            onError,
          ),
    );
  }

  static createPopup(
    id: React.Key,
    open: boolean,
    selectedCountry: CountryInfo | null,
    regions: string[],
    close: VoidFunction,
    onComplete: (country: CountryInfo) => void,
    onClose: VoidFunction | null,
    onError?: (error: string) => void,
  ): JSX.Element {
    return (
      <CountryPopup
        key={`${id}-country`}
        open={open}
        selectedCountry={selectedCountry}
        regions={regions}
        onError={(error: string): void => {
          onError?.(error);
          close();
        }}
        onComplete={(country: CountryInfo): void => {
          onComplete?.(country);
          close();
        }}
        onClose={(): void => {
          onClose?.();
          close();
        }}
        countries={[]}
      />
    );
  }

  presenter: Presenter;

  constructor(props: IndexProps) {
    super(props);
    const { onClose, onComplete, onError, regions } = props;
    this.presenter = new Presenter(new Model(this), regions, onClose, onComplete, onError);
  }

  componentDidMount(): void {
    this.presenter.onAttach();
  }

  componentDidUpdate(): void {
    const { onClose } = this.props;
    this.presenter.onClose = onClose;
  }

  render(): JSX.Element {
    const { selectedCountry, open } = this.props;
    return (
      <View
        {...(this.state as StateModel)}
        selectedCountry={selectedCountry}
        presenter={this.presenter}
        open={open}
      />
    );
  }
}
