import { Dayjs } from 'dayjs';
import { Component } from 'react';
import BaseModel, { createModel } from '../../../util/mvp/base-model';

export type StateModel = {
  selectedDate: Dayjs | null;
  error: [string] | undefined;
};

export default function createStateModel(component: Component): BaseModel<StateModel> & StateModel {
  const initialState = { error: undefined, selectedDate: null };
  return createModel<StateModel>(component, initialState);
}
