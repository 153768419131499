// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claim-AB3NAZ-spacer1{min-height:12px;flex:1 1 auto}.claim-WYxJDP-spacer2{min-height:12px;flex:3 1 auto}.claim-yUTeYR-spacer4{min-height:12px;flex:10 1 auto}`, "",{"version":3,"sources":["webpack://./src/features/age-gate/landing/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,aAAA,CAGF,sBACE,eAAA,CACA,aAAA,CAGF,sBACE,eAAA,CACA,cAAA","sourcesContent":[".spacer1 {\n  min-height: 12px;\n  flex: 1 1 auto;\n}\n\n.spacer2 {\n  min-height: 12px;\n  flex: 3 1 auto;\n}\n\n.spacer4 {\n  min-height: 12px;\n  flex: 10 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spacer1": `claim-AB3NAZ-spacer1`,
	"spacer2": `claim-WYxJDP-spacer2`,
	"spacer4": `claim-yUTeYR-spacer4`
};
export default ___CSS_LOADER_EXPORT___;
