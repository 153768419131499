import isMobile from 'is-mobile';
import React, { Component } from 'react';
import { Config } from '../../types/config';
import GeoBlocker from '../geo-blocker/view';
import createStateModel, { StateModel, ClaimPage } from './model';

import Presenter from './presenter';
import View from './view';
import Spinner from '../../components/spinner';

interface ClaimMultiProps {
  hash: string;
  config: Config;
}

export default class ClaimMulti extends Component<ClaimMultiProps> {
  presenter: Presenter;

  config: Config;

  constructor(props: ClaimMultiProps) {
    super(props);
    const { config } = props;
    this.config = config;

    const showMobileOnly =
      (config.view_mode === 'mobile-only' || config.view_mode === 'mobile-first') &&
      !isMobile({ featureDetect: true, tablet: true });

    // Initialize the custom input from config.
    const { consent } = this.config.claim;
    const isConsentPopupContinueEnabled = !consent?.default?.enabled;
    const model = createStateModel(this, isConsentPopupContinueEnabled, showMobileOnly);
    this.presenter = new Presenter(model, this.config);
  }

  componentDidMount(): void {
    this.presenter.onAttach();
  }

  componentDidUpdate(prevProps: Readonly<ClaimMultiProps>): void {
    const { hash, config } = this.props;
    if (hash !== prevProps.hash || config !== prevProps.config) {
      this.presenter.update(hash, config);
    }
  }

  render(): JSX.Element {
    const { isSDKSetup, index } = this.state as StateModel;
    if (!isSDKSetup) {
      return <Spinner />;
    }

    return (
      <GeoBlocker
        config={this.config}
        shouldCheck={index === ClaimPage.landing}
        availableComponent={
          <View {...(this.state as StateModel)} config={this.config} presenter={this.presenter} />
        }
        onComplete={() => this.presenter.onGeoBlockerComplete()}
      />
    );
  }
}
