import React from 'react';
import clsx from 'clsx';
import styles from './index.module.scss';
import OutsideIcon from './outside-logo.svg';

export default function Button({
  className = undefined,
  disabled = false,
  ...props
}: IndexProps): JSX.Element {
  const { onClick, text } = props;
  return (
    <button
      type="button"
      className={clsx(
        className,
        'outside-button',
        disabled && 'disabled',
        styles.button,
        disabled && styles.disabled,
      )}
      onClick={(event): void | boolean => !disabled && onClick?.(event)}
    >
      <div className="outside-button-label">{text}</div>
      <img className="outside-button-icon" alt="" src={OutsideIcon} />
    </button>
  );
}

export interface IndexProps {
  className?: string;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  text: string;
}
