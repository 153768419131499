import { TFunction } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button } from '@mui/joy';
import Image from '../../components/image';
import styles from './index.module.scss';
import { tIfExistsAndIsValid } from '../../util/i18next-helpers';

interface ViewProps {
  t: TFunction;
  onContinue?: VoidFunction;
}

function View({ onContinue = undefined, ...props }: ViewProps): JSX.Element {
  const { t } = props;

  const backgroundImageUrl = tIfExistsAndIsValid('mobile-only.background_image.src');

  return (
    <div className={clsx('mobileOnly', styles.container)}>
      <main>
        {backgroundImageUrl && (
          <Image src={backgroundImageUrl} alt={t('mobile-only.background_image.alt')} />
        )}
        {onContinue && (
          <div className={styles.buttonContainer}>
            <Button className={styles.button} onClick={onContinue}>
              {t('mobile-only.continue')}
            </Button>
          </div>
        )}
      </main>
    </div>
  );
}

export default withTranslation()(View);
