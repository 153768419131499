import React, { useId } from 'react';
import clsx from 'clsx';
import i18next, { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Button, Link, Typography } from '@mui/joy';
import styles from './view.module.scss';
import Presenter from './presenter';
import { LandingModel } from './model';
import ConsentPopup from '../consent-popup';
import { ConsentType, Environment, LanguagePolicies } from '../../../types/config';
import Image from '../../../components/image';
import { tIfExistsAndIsValid } from '../../../util/i18next-helpers';
import TypographyHTML from '../../../components/typography-html';
import packageJson from '../../../../package.json';
import addCurrentSearchParamsToUrl from '../../../util/add-current-search-params-to-url';
import AuthButtonList, { AuthButtonInput } from '../../../components/auth-button-list';
import { getLoginURL } from '../../../util/navigation';
import SafeHtmlComponent from '../../../components/safe-html-component';

type ViewProps = LandingModel & {
  t: TFunction;
  presenter: Presenter;
  isConsentPopupOpen: boolean;
  acceptedPrimaryConsent: boolean;
  acceptedMarketingConsent: boolean;
  isConsentPopupContinueEnabled: boolean;
  languagePolicies: LanguagePolicies;
  environment: Environment | undefined;
  authButtonOrder: string[] | undefined;
  showLoginLink: boolean;
  onConsentChange: (type: ConsentType, consent: boolean) => void;
};

function View(props: ViewProps): JSX.Element {
  const isMobile = useMediaQuery('(max-width: 856px)');

  const {
    t,
    presenter,
    loading,
    showFacebook,
    showGoogle,
    showSamsung,
    showOutsideOauth,
    showWalletConnect,
    showProve,
    oauthOnly,
    allowEmail,
    allowPhone,
    isConsentPopupOpen,
    isConsentPopupContinueEnabled,
    advertiserName,
    showPrimaryConsent,
    showMarketingConsent,
    marketingConsentTerms,
    acceptedPrimaryConsent,
    acceptedMarketingConsent,
    languagePolicies,
    onConsentChange,
    collapseCard,
    hideAuth,
    environment,
    showLoginLink,
  } = props;

  let { authButtonOrder } = props;

  const mainContentId = `${useId()}-main-content`;

  const is1stPartyAuthAvailable = !oauthOnly && (allowEmail || allowPhone);

  // other auth buttons
  const authButtonInputList: AuthButtonInput[] = [];
  if (is1stPartyAuthAvailable) {
    if (allowEmail && allowPhone) {
      authButtonInputList.push({
        name: 'emailOrPhone',
        sortKey: 'token',
        className: 'claim-landing-token',
        localeKey: 'claim.landing.token_button_email_phone',
      });
    } else if (allowEmail) {
      authButtonInputList.push({
        name: 'email',
        sortKey: 'token',

        className: 'claim-landing-token',
        localeKey: 'claim.landing.token_button_email',
      });
    } else if (allowPhone) {
      authButtonInputList.push({
        name: 'phone',
        sortKey: 'token',

        className: 'claim-landing-token',
        localeKey: 'claim.landing.token_button_phone',
      });
    }
  }
  if (showFacebook) {
    authButtonInputList.push({
      name: 'facebook',
      sortKey: 'facebook',
      className: 'claim-landing-facebook',
      localeKey: 'claim.landing.facebook',
    });
  }
  if (showGoogle) {
    authButtonInputList.push({
      name: 'google',
      sortKey: 'google',
      className: 'claim-landing-google',
      localeKey: 'claim.landing.google',
    });
  }
  if (showOutsideOauth) {
    authButtonInputList.push({
      name: 'outside',
      sortKey: 'outside',
      className: 'claim-landing-outside',
      localeKey: 'claim.landing.outside_oauth',
    });
  }
  if (showSamsung) {
    authButtonInputList.push({
      name: 'samsung',
      sortKey: 'samsung',
      className: 'claim-landing-samsung',
      localeKey: 'claim.landing.samsung',
    });
  }
  if (showWalletConnect) {
    authButtonInputList.push({
      name: 'wallet-connect',
      sortKey: 'wallet-connect',
      className: 'claim-landing-wallet-connect',
      localeKey: 'claim.landing.wallet_connect',
    });
  }

  authButtonOrder = authButtonOrder?.map((name) => name.toLowerCase());

  // if an order is specified, sort the buttons accordingly
  if (authButtonOrder && authButtonOrder.length > 0) {
    authButtonInputList.sort(
      (a, b) => authButtonOrder.indexOf(a.sortKey) - authButtonOrder.indexOf(b.sortKey),
    );
  }

  // iframe
  const backgroundImageUrl = tIfExistsAndIsValid('claim.landing.background_image.src');

  let backgroundIframeUrl = i18next.exists(`claim.landing.background_iframe.src`)
    ? t(`claim.landing.background_iframe.src`)
    : null;
  if (backgroundIframeUrl) {
    backgroundIframeUrl = addCurrentSearchParamsToUrl(backgroundIframeUrl);
  }
  const backgroundIframeTitle = t(`claim.landing.background_iframe.title`, { defaultValue: '' });
  const backgroundIframeLang = t(`claim.landing.background_iframe.lang`, { defaultValue: 'en' });

  const AuthStack = (
    <>
      <Box component="fieldset" className={styles.authButtonFieldset}>
        <legend className="screen-reader-only">
          <span>{t('claim.landing.auth_button_aria_group')}</span>
        </legend>
        {showProve && (
          <Button onClick={(): void => presenter.onAuthProviderClick('prove')}>
            {t('claim.landing.prove')}
          </Button>
        )}

        <div className={clsx('claim-landing-social-container', styles.socialContainer)}>
          <AuthButtonList
            providers={authButtonInputList}
            disabled={loading}
            onClick={(provider) => {
              presenter.onAuthProviderClick(provider);
            }}
          />
        </div>
      </Box>
      {showLoginLink && (
        <Link
          className={clsx(styles.linkLogin, 'claim-landing-login-link')}
          level="body-sm"
          href={getLoginURL()}
          aria-label={`${t('login.landing.no_account')} ${t('login.landing.sign_up')}`}
        >
          {t('claim.landing.login_link')}
        </Link>
      )}
    </>
  );

  const showHTMLContent1 = tIfExistsAndIsValid('claim.landing.subtext_1');
  const showHTMLContent2 = tIfExistsAndIsValid('claim.landing.subtext_2');

  return (
    <>
      <main
        id={mainContentId}
        className={clsx(
          isMobile ? 'claim-landing-container' : 'claim-landing-container-anchored',
          styles.container,
        )}
      >
        {isMobile && (
          <>
            {/* <video
              className={clsx('claim-landing-background-video-container', styles.videoContainer)}
              autoPlay
              muted
              loop
            >
              <Source
                type="video/mp4"
                className={clsx('claim-landing-background-video', styles.video)}
              />
            </video> */}
            <div
              className={clsx('claim-landing-background-image-container', styles.imageContainer)}
            >
              {backgroundImageUrl && (
                <Image
                  className={clsx('claim-landing-background-image', styles.image)}
                  src={backgroundImageUrl}
                  alt={t('claim.landing.background_image.alt')}
                />
              )}
            </div>

            {backgroundIframeUrl && (
              <div
                className={clsx(
                  `claim-landing-background-iframe-container`,
                  styles.backgroundIframeContainer,
                )}
              >
                <iframe
                  id="background_iframe"
                  title={backgroundIframeTitle}
                  lang={backgroundIframeLang}
                  src={backgroundIframeUrl}
                  className={clsx(`claim-landing-background-iframe`, styles.iframe)}
                />
              </div>
            )}
          </>
        )}

        {(!isMobile || !backgroundIframeUrl) && (
          <div className={clsx('claim-landing-inner-container', styles.innerContainer)}>
            <TypographyHTML
              className={clsx(styles.landingHeader)}
              level="h3"
              component="h1"
              htmlContent={t('claim.landing.text')}
            />

            {showHTMLContent1 && (
              <SafeHtmlComponent className="claim-landing-inner-html-content-1">
                {t('claim.landing.subtext_1')}
              </SafeHtmlComponent>
            )}

            <div className={clsx('claim-landing-header-space', styles.headerSpace)} />

            {!hideAuth && AuthStack}

            {showHTMLContent2 && (
              <SafeHtmlComponent className="claim-landing-inner-html-content-2">
                {t('claim.landing.subtext_2')}
              </SafeHtmlComponent>
            )}

            <div className={clsx('claim-landing-footer-space', styles.footerSpace)} />

            <TypographyHTML
              role="contentinfo"
              className={clsx('claim-landing-footer', styles.landingFooter)}
              level="footer"
              htmlContent={t('claim.landing.footer')}
            />
          </div>
        )}
        {!hideAuth && isMobile && backgroundIframeUrl && (
          <div
            className={clsx('claim-landing-card', styles.card, collapseCard && styles.slideDown)}
          >
            <div className={clsx('claim-landing-card-inner', styles.cardInner)}>
              {collapseCard && (
                <div
                  aria-hidden="true"
                  className={styles.cardClickZone}
                  onClick={(): void => presenter.onCardExpandClick()}
                />
              )}
              {AuthStack}
            </div>
          </div>
        )}
        {environment === 'alpha' && (
          <Typography level="footer" sx={{ mb: 2 }}>
            {`v${packageJson.version}`}
          </Typography>
        )}
      </main>

      <ConsentPopup
        open={isConsentPopupOpen}
        isContinueEnabled={isConsentPopupContinueEnabled}
        onContinue={(): void => presenter.onConsentPopupContinue()}
        onClose={(): void => presenter.closeConsentPopup()}
        advertiserName={advertiserName}
        showPrimaryConsent={showPrimaryConsent}
        showMarketingConsent={showMarketingConsent}
        marketingConsentTerms={marketingConsentTerms}
        marketingChecked={acceptedMarketingConsent}
        primaryChecked={acceptedPrimaryConsent}
        onPrimaryChange={(checked: boolean): void => {
          onConsentChange('primary', checked);
        }}
        onMarketingChange={(checked: boolean): void => {
          onConsentChange('marketing', checked);
        }}
        languagePolicies={languagePolicies}
      />
    </>
  );
}

export default withTranslation()(View);
