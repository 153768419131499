import { Typography, TypographyProps } from '@mui/joy';
import DOMPurify from 'dompurify';
import React, { forwardRef } from 'react';

interface Props extends TypographyProps {
  htmlContent: string;
}

export default forwardRef<HTMLElement, Props>(function TypographyHTML(
  { htmlContent, children, ...restProps }: Props,
  ref,
): JSX.Element {
  const safeContent = DOMPurify.sanitize(htmlContent, { USE_PROFILES: { html: true } });
  return (
    <Typography ref={ref} {...restProps}>
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: safeContent }} />
      {children}
    </Typography>
  );
});
