import { ThemeStyle } from '../../theme/theme';

export default function createStyles(theme: ThemeStyle): string[] {
  // landing page overrides
  const { background_color: overrideBackgroundColor } = theme.override?.landing?.mobile ?? {};

  const styles: string[] = [];

  if (overrideBackgroundColor && overrideBackgroundColor.length > 0) {
    styles.push(`.login-landing-container {background-color: ${overrideBackgroundColor};}`);
  }

  if (theme.top_margin) {
    styles.push(`.login-landing-background-image-container {height: ${theme.top_margin}}`);
    styles.push(`.base-background-image-container {height: ${theme.top_margin}}`);
  }

  return styles;
}
