export default function loadScript(url, head, attributes) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = () => {
      resolve();
    };
    script.onerror = (error) => {
      reject(error);
    };
    script.src = url;
    if (attributes) {
      Object.entries(attributes).forEach(([key, value]) => {
        script.setAttribute(key, value);
      });
    }
    if (head) {
      document.head.appendChild(script);
    } else document.body.appendChild(script);
  });
}
