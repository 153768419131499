import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { arbitrum, mainnet, polygon } from 'wagmi/chains';
import App from '../../app';
import Page from '../../features/claim';
import { PageProps } from '../../types/common';
import { Config } from '../../types/config';
import { tIfExists } from '../../util/i18next-helpers';
import Log from '../../util/log';
import ErrorBoundaryView from '../../views/view-error-boundary';
import removeLoader from '../../util/removeLoader';

function ClaimMultiPage(props: PageProps): JSX.Element | null {
  const { config } = props;
  const location = useLocation();
  let { hash } = location;
  if (hash.startsWith('#/')) {
    hash = hash.replace('#/', '');
  } else if (hash.startsWith('#')) {
    hash = hash.replace('#', '');
  }

  const pageTitle = tIfExists('claim.page_title');

  useEffect(() => {
    // check if claim page is archived
    if (config.claim.archived) {
      Log.warn('Claim page is archived. Redirecting to "/".');
      window.location.replace('/');
      removeLoader();
    }
  }, []);

  const projectId = config.wallet_connect?.project_id;
  if (!projectId) {
    Log.info('Missing WalletConnect Cloud Project Id', projectId);
  }

  let page: JSX.Element;
  let ethereumClient: EthereumClient | undefined;

  const innerPage = (
    <Page
      {...{
        config,
        hash,
        removeLoader,
      }}
    />
  );

  page = innerPage;

  if (projectId) {
    const chains = [arbitrum, mainnet, polygon];

    // Wagmi config
    const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
    const wagmiConfig = createConfig({
      autoConnect: true,
      connectors: w3mConnectors({ projectId, chains }),
      publicClient,
    });

    // Web3Modal Ethereum Client
    ethereumClient = new EthereumClient(wagmiConfig, chains);

    page = <WagmiConfig config={wagmiConfig}>{innerPage}</WagmiConfig>;
  }

  return (
    <>
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      {projectId && projectId.length > 0 && (
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      )}
      {page}
    </>
  );
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <App path="./" page="claim-multi">
    {(config: Config): JSX.Element => {
      return (
        <BrowserRouter>
          <Routes>
            <Route
              path="/*"
              key="claim-multi"
              element={
                <ErrorBoundary FallbackComponent={ErrorBoundaryView}>
                  <ClaimMultiPage config={config} />
                </ErrorBoundary>
              }
            />
          </Routes>
        </BrowserRouter>
      );
    }}
  </App>,
);
