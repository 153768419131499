import React from 'react';
import { withTranslation } from 'react-i18next';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import { TFunction } from 'i18next';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  ListDivider,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/joy';
import useId from '@mui/material/utils/useId';
import { useMediaQuery } from 'usehooks-ts';
import { CountryInfo } from '../../util/countries';
import Presenter from './presenter';

export interface ViewProps {
  t: TFunction;
  presenter: Presenter;
  open: boolean;
  selectedCountry: CountryInfo | null;
  countries: CountryInfo[];
}

function View(props: ViewProps): JSX.Element {
  const { presenter, open, selectedCountry, countries, t } = props;
  const isHeightConstrained = useMediaQuery('(max-height: 450px)');
  const id = useId();
  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        // Modals Prevent Clicks Outside (INT-WEB-004-07)
        if (reason !== 'backdropClick') {
          presenter.onCloseClick?.();
        }
      }}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-ariaDescribedby`}
    >
      <ModalDialog layout={isHeightConstrained ? 'fullscreen' : 'center'}>
        <DialogTitle>{t('country.title')}</DialogTitle>
        <ModalClose />
        <DialogContent>
          <p className="screen-reader-only" id={`${id}-ariaDescribedby`}>
            {t('common.aria_select_phone_prefix')}
          </p>
          {selectedCountry && (
            <ListItem
              key={selectedCountry.name}
              className="country-picker-list-item"
              onClick={(): void => presenter.onCountrySelect(selectedCountry)}
              onKeyDown={(event): void => {
                if (event.key === 'Enter') {
                  presenter.onCountrySelect(selectedCountry);
                }
              }}
            >
              <ListItemButton>
                <ListItemDecorator
                  className="country-picker-listitem-decorator"
                  sx={{ minWidth: '72px' }}
                >
                  ({selectedCountry.phonePrefix})
                </ListItemDecorator>
                <ListItemContent>
                  <Typography className="country-picker-listitem-text">
                    {selectedCountry.name}
                  </Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          )}

          <List
            sx={{
              overflow: 'scroll',
              mx: 'calc(-1 * var(--ModalDialog-padding))',
              px: 'var(--ModalDialog-padding)',
            }}
            aria-labelledby={`${id}-title`}
          >
            {countries?.map((country) => {
              return (
                <>
                  <ListItem
                    key={country.name}
                    className="country-picker-list-item"
                    onClick={(): void => presenter.onCountrySelect(country)}
                    onKeyDown={(event): void => {
                      if (event.key === 'Enter') {
                        presenter.onCountrySelect(country);
                      }
                    }}
                  >
                    <ListItemButton>
                      <ListItemDecorator
                        className="country-picker-listitem-decorator"
                        sx={{ minWidth: '72px' }}
                      >
                        ({country.phonePrefix})
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography className="country-picker-listitem-text">
                          {country.name}
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                  <ListDivider className="country-picker-listitem-divider" />
                </>
              );
            })}
          </List>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
}
export default withTranslation()(View);
