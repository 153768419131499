import React from 'react';
import TransitionScreen from './screen-transition';

interface PageProps {
  current: number;
  number: number;
  direction: 'left' | 'right';
  children: React.ReactElement | null;
}

export default function Page(props: PageProps): JSX.Element {
  const { current, number, direction, children } = props;
  const show = current === number;
  return (
    <TransitionScreen key={number} in={show} direction={direction}>
      {children}
    </TransitionScreen>
  );
}
