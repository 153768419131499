import React, { ReactNode } from 'react';
import clsx from 'clsx';
import OtpInput from 'react-otp-input';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/joy';
import styles from './index.module.scss';
import FormControlWrapper from '../form/form-control-wrapper';

interface ViewProps {
  className?: string;
  style?: React.CSSProperties;
  label?: string | null;
  helperText?: string | null;
  disabled?: boolean;
  required?: boolean;
  numInputs?: number;
  value: string;
  onChange?: (otp: string) => void;
}

// Mimic input 'outlined' global variant
const StyledInput = styled('input')(({ theme }) => ({
  backgroundColor: theme.palette.background.surface,
  color: theme.variants.outlined.neutral.color,
  border: 'solid',
  borderColor: theme.variants.outlined.neutral.borderColor,
  borderWidth: '1px',
  borderRadius: theme.vars.radius.sm,
  boxShadow: theme.vars.shadow.md,
  [theme.focus.selector]: theme.focus.default,
}));

export default function InputContainer({
  className = undefined,
  style = undefined,
  label = '',
  helperText = '',
  disabled = false,
  required = false,
  numInputs = 6,
  onChange = undefined,
  ...props
}: ViewProps): JSX.Element {
  const { value } = props;
  const isMobile = useMediaQuery('(max-width: 856px)');
  const deviceClass = isMobile ? 'mobile' : 'desktop';

  return (
    <div style={style} className={clsx(styles.container, 'otp-input-container', className)}>
      <FormControlWrapper
        label={label ?? ''}
        helper={helperText ?? ''}
        required={required}
        disabled={disabled}
      >
        <OtpInput
          value={value}
          numInputs={numInputs}
          onChange={(otp: string): void => onChange?.(otp)}
          inputType="number"
          shouldAutoFocus
          renderInput={(inputProps): ReactNode => <StyledInput {...inputProps} />}
          containerStyle={styles.inputContainerStyle}
          inputStyle={clsx(styles.inputStyle, 'otp-input-inner-input', deviceClass)}
        />
      </FormControlWrapper>
    </div>
  );
}
