import React, { Component } from 'react';
import { AuthProvider, FormattedToken } from '../../../types/common';
import {
  Config,
  ConsentType,
  FormItem,
  LanguagePolicies,
  RawFormItem,
} from '../../../types/config';
import createCaptureModel, { CaptureModel } from './model';
import Presenter from './presenter';
import View from './view';

interface IndexProps {
  config: Config;
  authProvider: AuthProvider;
  inputToken: string;
  onTokenChange: (token: string) => void;
  avatarURL: string | null;
  socialName: string | null;
  inputFullName: string;
  inputFirstName: string;
  inputLastName: string;
  onFullNameChange: (name: string) => void;
  onFirstNameChange: (name: string) => void;
  onLastNameChange: (name: string) => void;
  inputAdditional: string | null;
  onAdditionalChange: (value: string) => void;
  language: string;
  dateOfBirth: string | null;
  acceptedPrimaryConsent: boolean;
  acceptedMarketingConsent: boolean;
  isAuthComplete: boolean;
  onConsentChange: (type: ConsentType, consent: boolean) => void;
  onBack: VoidFunction;
  onRegister: (notificationType: string, formattedToken?: FormattedToken) => void;
  formItems: FormItem[];
  preFormItems: RawFormItem[];
  onFormItemChange: (item: FormItem) => void;
}

export default class Index extends Component<IndexProps, CaptureModel> {
  presenter: Presenter;

  authProvider: AuthProvider;

  languagePolicies: LanguagePolicies;

  constructor(props: IndexProps) {
    super(props);
    const {
      config,
      authProvider,
      inputToken,
      inputFullName,
      inputFirstName,
      inputLastName,
      inputAdditional,
      acceptedPrimaryConsent,
      acceptedMarketingConsent,
      language,
      dateOfBirth,
      isAuthComplete,
      onConsentChange,
      onBack,
      onRegister,
      formItems,
      preFormItems,
      onFormItemChange,
    } = props;
    this.authProvider = authProvider;

    if (config.claim.consent.policies && Object.keys(config.claim.consent.policies).length > 0) {
      this.languagePolicies = config.claim.consent.policies[language];
    } else {
      throw new Error('Missing consent policies.');
    }

    const model = createCaptureModel(this, config);

    this.presenter = new Presenter(
      model,
      config,
      inputToken,
      inputFullName,
      inputFirstName,
      inputLastName,
      inputAdditional,
      acceptedPrimaryConsent,
      acceptedMarketingConsent,
      language,
      dateOfBirth,
      isAuthComplete,
      onConsentChange,
      onBack,
      onRegister,
      this.languagePolicies,
      formItems,
      preFormItems,
      onFormItemChange,
    );
  }

  componentDidMount(): void {
    this.presenter.onAttach();
  }

  componentDidUpdate(prevProps: IndexProps): void {
    const {
      inputToken,
      inputFullName,
      inputFirstName,
      inputLastName,
      inputAdditional,
      acceptedPrimaryConsent,
      acceptedMarketingConsent,
      isAuthComplete,
    } = this.props;

    if (prevProps !== this.props) {
      this.presenter.inputToken = inputToken;
      this.presenter.inputFullName = inputFullName;
      this.presenter.inputFirstName = inputFirstName;
      this.presenter.inputLastName = inputLastName;
      this.presenter.inputAdditional = inputAdditional;
      this.presenter.acceptedPrimaryConsent = acceptedPrimaryConsent;
      this.presenter.acceptedMarketingConsent = acceptedMarketingConsent;
      this.presenter.isAuthComplete = isAuthComplete;
    }
  }

  render(): JSX.Element {
    const {
      inputToken,
      onTokenChange,
      socialName,
      inputFullName,
      inputFirstName,
      inputLastName,
      onFullNameChange,
      onFirstNameChange,
      onLastNameChange,
      avatarURL,
      inputAdditional,
      onAdditionalChange,
      acceptedPrimaryConsent,
      acceptedMarketingConsent,
      onConsentChange,
      isAuthComplete,
      formItems,
      onFormItemChange,
      config,
    } = this.props;

    return (
      <View
        inputToken={inputToken}
        onTokenChange={onTokenChange}
        socialName={socialName}
        inputFullName={inputFullName}
        inputFirstName={inputFirstName}
        inputLastName={inputLastName}
        onFullNameChange={onFullNameChange}
        onFirstNameChange={onFirstNameChange}
        onLastNameChange={onLastNameChange}
        avatarURL={avatarURL}
        inputAdditional={inputAdditional}
        onAdditionalChange={onAdditionalChange}
        acceptedPrimaryConsent={acceptedPrimaryConsent}
        acceptedMarketingConsent={acceptedMarketingConsent}
        onConsentChange={onConsentChange}
        {...this.state}
        presenter={this.presenter}
        authProvider={this.authProvider}
        isAuthComplete={isAuthComplete}
        languagePolicies={this.languagePolicies}
        formItems={formItems}
        onFormItemChange={onFormItemChange}
        enableAccessibility={config.enable_vgar}
      />
    );
  }
}
