import BaseModel from '../../util/model';

export type OTPError = 'unknown' | 'invalid_otp' | null;
export interface StateModel {
  otp: string;
  loading: boolean;
  isContinueEnabled: boolean;
  isResendOnCooldown: boolean;
  error: OTPError;
}

export default class Model extends BaseModel implements StateModel {
  static initialState: StateModel = {
    otp: '',
    loading: false,
    isContinueEnabled: false,
    isResendOnCooldown: false,
    error: null,
  };

  constructor(component: React.Component) {
    super(component, Model.initialState);
  }

  get otp(): string {
    const { otp } = this.value;
    return otp;
  }

  set otp(value: string) {
    this.value = { otp: value };
  }

  get loading(): boolean {
    const { loading } = this.value;
    return loading;
  }

  set loading(value: boolean) {
    this.value = { loading: value };
  }

  get isContinueEnabled(): boolean {
    const { isContinueEnabled } = this.value;
    return isContinueEnabled;
  }

  set isContinueEnabled(value: boolean) {
    this.value = { isContinueEnabled: value };
  }

  get isResendOnCooldown(): boolean {
    const { isResendOnCooldown } = this.value;
    return isResendOnCooldown;
  }

  set isResendOnCooldown(value: boolean) {
    this.value = { isResendOnCooldown: value };
  }

  get error(): OTPError {
    const { error } = this.value;
    return error;
  }

  set error(value: OTPError) {
    this.value = { error: value };
  }
}
