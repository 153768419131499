import { Dayjs } from 'dayjs';
import React, { Component } from 'react';
import Model, { StateModel } from './model';
import Presenter from './presenter';
import View from './view';

export interface IndexProps {
  onClose: (date: Dayjs | null) => void;
  minAge: number;
  redirect: string;
  redirectDelay: number;
  format: string;
}

export default class AgeGate extends Component<IndexProps> {
  presenter: Presenter;

  constructor(props: IndexProps) {
    super(props);
    const { onClose } = props;
    this.presenter = new Presenter(new Model(this), onClose);
  }

  componentDidUpdate(): void {
    const { onClose } = this.props;
    this.presenter.onClose = onClose;
  }

  render(): JSX.Element {
    const { minAge, redirect, redirectDelay, format } = this.props;
    return (
      <View
        {...(this.state as StateModel)}
        presenter={this.presenter}
        minAge={minAge}
        redirect={redirect}
        redirectDelay={redirectDelay}
        format={format}
      />
    );
  }
}
