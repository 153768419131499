import { Theme as JoyTheme } from '@mui/joy';
import { experimental_extendTheme } from '@mui/material/styles';
import * as locales from '@mui/x-date-pickers/locales';
import { Config } from '../types/config';

const extendMaterialTheme = (
  config: Config,
  joyTheme: JoyTheme,
  locale: string,
): ReturnType<typeof experimental_extendTheme> => {
  // find the appropriate locale for the date picker
  let datePickerLocale = locales.DEFAULT_LOCALE;
  const localeKey = Object.keys(locales).find(
    (key) => key === locale || key.substring(0, 2) === locale,
  );
  const localeKeyShort = localeKey?.substring(0, 2);
  if (localeKey && localeKeyShort && config.supported_locales?.includes(localeKeyShort)) {
    datePickerLocale = locales[localeKey];
  }

  return experimental_extendTheme(
    {
      components: {
        MuiButtonBase: {
          defaultProps: {
            // Current date picker is using Mui, this breaks the focus if ripple disabled
            disableRipple: false, // No more ripple, on the whole application
          },
        },
        MuiInput: {
          styleOverrides: {
            underline: {
              '&:before': {
                borderBottom: 'none',
              },
              '&:after': {
                borderBottom: 'none',
              },
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              backgroundColor: joyTheme?.colorSchemes.light.palette.background.body,
            },
          },
        },
      },
    },
    datePickerLocale,
  );
};

export default extendMaterialTheme;
