import { LandingModel as Model } from './model';
import { Config, ConsentType, RawFormItem } from '../../../types/config';
import { AuthProvider } from '../../../types/common';
import Log from '../../../util/log';

export default class Presenter {
  model: Model;

  config: Config;

  selectedAuthProvider: AuthProvider | null = null;

  previousIframeScroll?: number;

  iframeScrollUpStart?: number;

  onNext: (authProvider: AuthProvider) => void;

  onConsentChange: (type: ConsentType, consent: boolean) => void;

  onPreFormItemsChange: (items: [RawFormItem]) => void;

  constructor(
    model: Model,
    config: Config,
    onNext: (authProvider: AuthProvider) => void,
    onConsentChange: (type: ConsentType, consent: boolean) => void,
    onPreFormItemsChange: (items: [RawFormItem]) => void,
  ) {
    this.config = config;
    this.onNext = onNext;
    this.onConsentChange = onConsentChange;
    this.model = model;
    this.onPreFormItemsChange = onPreFormItemsChange;
  }

  async onAttach(): Promise<void> {
    window.addEventListener('message', this.onReceiveMessage);
  }

  onDetach(): void {
    window.removeEventListener('message', this.onReceiveMessage);
  }

  onAuthProviderClick(provider: AuthProvider): void {
    this.selectedAuthProvider = provider;
    if (provider !== 'token') {
      this.openConsentPopup(provider);
    } else {
      this.onNext(provider);
    }
  }

  openConsentPopup(authProvider: AuthProvider): void {
    this.selectedAuthProvider = authProvider;
    this.model.isConsentPopupOpen = true;
  }

  closeConsentPopup(): void {
    this.model.isConsentPopupOpen = false;
  }

  onConsentPopupContinue(): void {
    this.onNext(this.selectedAuthProvider!);
  }

  onCardExpandClick(): void {
    this.model.collapseCard = false;
    this.iframeScrollUpStart = undefined;
  }

  onReceiveMessage = (event: MessageEvent): void => {
    const data = event.data as Record<string, unknown> | undefined;

    if (data === undefined) return;

    // check the origin of the message
    if (
      !(
        event.origin === 'https://cdn-alpha.smartmedialabs.io' ||
        event.origin === 'https://cdn-beta.smartmedialabs.io' ||
        event.origin === 'https://cdn.smartmedialabs.io'
      )
    ) {
      Log.error(`Refused message: ${data.event}. Invalid origin: ${event.origin}`);
      return;
    }

    if (data.event === 'clu-auth-visible') {
      this.model.hideAuth = !data.value;
    }

    if (data.event === 'clu-form-items') {
      const items = data.value;
      this.onPreFormItemsChange(items as [RawFormItem]);
    }

    if (data.event === 'iframe-scroll') {
      const previous = this.previousIframeScroll;
      this.previousIframeScroll = data.offsetY as number;

      if (previous !== undefined && this.previousIframeScroll !== undefined) {
        if (this.previousIframeScroll < previous) {
          if (this.iframeScrollUpStart === undefined) {
            this.iframeScrollUpStart = previous;
          } else if (
            this.previousIframeScroll + 100 < this.iframeScrollUpStart ||
            this.previousIframeScroll <= 0
          ) {
            this.model.collapseCard = false;
            this.iframeScrollUpStart = undefined;
          }
        } else if (this.previousIframeScroll > 100) {
          this.model.collapseCard = true;
          this.iframeScrollUpStart = undefined;
        }
      }
    }
  };
}
