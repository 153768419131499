import { Stack, Typography } from '@mui/joy';
import clsx from 'clsx';
import { TFunction, i18n as i18nType } from 'i18next';
import React, { useId } from 'react';
import { withTranslation } from 'react-i18next';
import AlertModal from '../../components/modals/alert-dialog';
import { VlabsSDKError } from '../../manager/vlabs-errors';
import Presenter from './presenter';
import styles from './view.module.scss';

interface ViewProps {
  t: TFunction;
  i18n: i18nType;
  open: boolean;
  presenter: Presenter;
  error?: VlabsSDKError;
}

function View({ error = undefined, ...props }: ViewProps): JSX.Element {
  const id = useId();
  const { presenter, open, t, i18n } = props;
  let titleKey = 'errors_v2.default.title';
  let bodyKey = 'errors_v2.default.body';

  if (error?.code) {
    const titleKeyPath = `errors_v2.${error.code}.title`;
    if (i18n.exists(titleKeyPath) && t(titleKeyPath).length > 0) {
      titleKey = titleKeyPath;
    }

    const bodyKeyPath = `errors_v2.${error.code}.body`;
    if (i18n.exists(bodyKeyPath) && t(bodyKeyPath).length > 0) {
      bodyKey = bodyKeyPath;
    }
  }
  const codeText = t('errors_v2.code_message', { code: error?.code ?? -1 });

  const contentNode = (
    <Stack className={clsx(styles.codeContainer)} spacing={2} alignContent="center">
      <Typography id={`${id}-modal-error-message`} sx={{ textAlign: 'center' }}>
        {t(bodyKey)}
      </Typography>
      <Typography id={`${id}-modal-error-code`} sx={{ textAlign: 'center' }} level="body-md">
        {codeText}
      </Typography>
    </Stack>
  );

  return (
    <AlertModal
      role="alertdialog"
      open={open}
      title={t(titleKey)}
      content={contentNode}
      closeTitle={t('errors_v2.popup_confirm')}
      isActionDisabled
      onClose={(): void => presenter?.onCloseClick()}
      onAction={(): void => {}}
      actionTitle=""
      ariaDescribedby={`${id}-modal-error-message`}
    />
  );
}

export default withTranslation()(View);
