type Provider = (popups: JSX.Element[]) => void;

class Alert {
  id = 0;

  alerts: Map<
    number,
    {
      alert: (open: boolean) => JSX.Element;
      open: boolean;
    }
  >;

  provider?: Provider;

  constructor() {
    this.id = 0;
    this.alerts = new Map();
  }

  setProvider(provider: Provider | undefined): void {
    this.provider = provider;
  }

  getId(): number {
    const { id } = this;
    this.id += 1;
    return id;
  }

  updateAlerts(): void {
    const alerts: JSX.Element[] = [];
    this.alerts.forEach((value) => {
      alerts.push(value.alert(value.open));
    });
    this.provider?.(alerts);
  }

  showAlert(id: number, alert: (open: boolean) => JSX.Element): void {
    this.alerts.set(id, { alert, open: true });
    this.updateAlerts();
  }

  removeAlert(id: number): void {
    const alert = this.alerts.get(id);
    if (alert) {
      alert.open = false;
      this.alerts.set(id, alert);
      setTimeout(() => {
        this.alerts.delete(id);
      }, 1000);
      this.updateAlerts();
    }
  }

  show(factory: (id: number, onClose: () => void) => (open: boolean) => JSX.Element): void {
    const id = this.getId();
    this.showAlert(
      id,
      factory(id, () => {
        this.removeAlert(id);
      }),
    );
  }
}
export default new Alert();
