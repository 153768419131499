import { Button, ButtonProps, SvgIcon } from '@mui/joy';
import React from 'react';
import GoogleSVG from './logos/logo-google';
import FacebookSVG from './logos/logo-facebook';
import WalletConnectSVG from './logos/logo-wallet-connect';
import EmailPNG from './logos/logo-email-30.png';
import EmailPhonePNG from './logos/logo-email-or-sms-30.png';
import PhonePNG from './logos/logo-sms-30.png';
import { TokenAuthOption } from '../../types/common';

export interface ButtonAuthProp extends ButtonProps {
  provider: 'facebook' | 'google' | 'samsung' | 'wallet-connect' | TokenAuthOption;
  iconOnly?: boolean;
}

function ButtonAuth({ iconOnly = false, ...props }: ButtonAuthProp): JSX.Element {
  const { provider, disabled, onClick, children, ...restProps } = props;
  let SVG;
  let PNG;
  if (provider === 'google') {
    SVG = GoogleSVG;
  } else if (provider === 'facebook') {
    SVG = FacebookSVG;
  } else if (provider === 'wallet-connect') {
    SVG = WalletConnectSVG;
  } else if (provider === 'email') {
    PNG = EmailPNG;
  } else if (provider === 'phone') {
    PNG = PhonePNG;
  } else if (provider === 'emailOrPhone') {
    PNG = EmailPhonePNG;
  }

  // const icon;
  return (
    <Button
      size="lg"
      variant="auth"
      disabled={disabled}
      onClick={onClick}
      fullWidth
      endDecorator={!iconOnly ? <div style={{ width: '26px' }} /> : undefined}
      sx={{
        paddingX: '16px',
        justifyContent: iconOnly ? 'center' : 'space-between',
        textOverflow: 'ellipsis',
        whiteSpace: 'preserve-breaks',
        overflowWrap: 'break-word',
        overflow: 'hidden',
        '.MuiButton-startDecorator': {
          width: '36px',
          display: 'flex',
          justifyContent: 'center',
        },
      }}
      slotProps={{ startDecorator: { style: { marginRight: iconOnly ? '0' : undefined } } }}
      startDecorator={
        <>
          {SVG && (
            <SvgIcon>
              <SVG />
            </SvgIcon>
          )}
          {PNG && (
            <img
              style={{ objectFit: 'contain', width: '26px', height: '26px' }}
              className="token-icon"
              alt=""
              src={PNG}
            />
          )}
        </>
      }
      {...restProps}
    >
      {iconOnly ? '' : children}
    </Button>
  );
}

export default ButtonAuth;
