import React, { Component } from 'react';
import Presenter from './presenter';
import Model from './model';
import View from './view';

export interface IndexProps {
  redirect: string;
  redirectDelay: number;
  minAge: number;
}

export default class AgeError extends Component<IndexProps> {
  presenter: Presenter;

  constructor(props: IndexProps) {
    super(props);
    const { redirect, redirectDelay } = props;
    this.presenter = new Presenter(new Model(this, {}), redirect, redirectDelay);
  }

  componentDidMount(): void {
    this.presenter.onAttach();
  }

  render(): JSX.Element {
    const { minAge } = this.props;
    return <View {...this.state} minAge={minAge} />;
  }
}
