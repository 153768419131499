import chroma from 'chroma-js';

// Define the allowed shade numbers as a union of specific numbers
type ShadeLevels = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

// Define the color palette interface with keys constrained to ShadeLevels
type ColorPalette = {
  [key in ShadeLevels]: string;
};

// Function to generate a color palette with an optional reversed parameter
const generateColorPalette = (
  baseColor: string,
  startLevel: number = 500,
  reversed: boolean = false,
): ColorPalette => {
  // Create color scales for lighter and darker shades
  const colorScaleLighter = chroma.scale(['#ffffff', baseColor]).mode('lab');
  const colorScaleDarker = chroma.scale([baseColor, '#000000']).mode('lab');

  // Define the shade levels from 50 to 900
  const shadeLevels: ShadeLevels[] = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

  // Create the palette
  const palette: ColorPalette = {} as ColorPalette;

  // Fill in the colors for the original palette
  shadeLevels.forEach((shade) => {
    let color: string;

    if (shade <= startLevel) {
      // Use the lighter scale for shades 50 to 500
      color = colorScaleLighter(shade / startLevel).hex();
    } else {
      // Use the darker scale for shades 600 to 900
      color = colorScaleDarker((shade - startLevel) / startLevel).hex();
    }

    palette[shade] = color;
  });

  // If reversed is true, reverse the palette colors
  if (reversed) {
    const reversedPalette: ColorPalette = {} as ColorPalette;

    // Reverse the order of the color values while keeping the same keys
    shadeLevels.forEach((shade, index) => {
      const reversedIndex = shadeLevels.length - 1 - index; // Calculate the reversed index
      const reversedShade = shadeLevels[reversedIndex]; // Get the reversed shade
      reversedPalette[shade] = palette[reversedShade]; // Assign the reversed color
    });

    return reversedPalette; // Return the reversed palette
  }

  return palette; // Return the original palette if not reversed
};

// Function to determine whether a color is light or dark
const isColorLight = (color: string): boolean => {
  // Get the luminance of the color
  const luminance = chroma(color).luminance();

  // A common threshold to determine if a color is light or dark
  const threshold = 0.5; // Colors with luminance > 0.5 are generally considered light

  // Return true if the color is light, false if dark
  return luminance > threshold;
};

export { isColorLight, generateColorPalette };
