// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claim-wa7vix-container{box-sizing:border-box;width:100%;height:100%;display:flex;flex-direction:column;align-items:center}.claim-uEBPH8-innerContainer{box-sizing:border-box;display:flex;flex-direction:column;align-items:center;width:100%;height:100%;position:absolute}.claim-uEBPH8-innerContainer>*{z-index:1}.claim-AYQRih-image{width:100%;object-fit:contain}.claim-FABDYe-imageContainer{overflow:visible}.claim-_36UY2-message{text-align:center}.claim-RJ9noa-footerSpace{flex:1}.claim-hf7QiW-footer{text-align:center}`, "",{"version":3,"sources":["webpack://./src/features/geo-blocker/view.module.scss"],"names":[],"mappings":"AAAA,wBACE,qBAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CAGF,6BACE,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,+BACE,SAAA,CAGF,oBACE,UAAA,CAEA,kBAAA,CAGF,6BACE,gBAAA,CAGF,sBACE,iBAAA,CAGF,0BACE,MAAA,CAGF,qBACE,iBAAA","sourcesContent":[".container {\n  box-sizing: border-box;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.innerContainer {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n\n.innerContainer > * {\n  z-index: 1;\n}\n\n.image {\n  width: 100%;\n  // height: auto;\n  object-fit: contain;\n}\n\n.imageContainer {\n  overflow: visible;\n}\n\n.message {\n  text-align: center;\n}\n\n.footerSpace {\n  flex: 1;\n}\n\n.footer {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `claim-wa7vix-container`,
	"innerContainer": `claim-uEBPH8-innerContainer`,
	"image": `claim-AYQRih-image`,
	"imageContainer": `claim-FABDYe-imageContainer`,
	"message": `claim-_36UY2-message`,
	"footerSpace": `claim-RJ9noa-footerSpace`,
	"footer": `claim-hf7QiW-footer`
};
export default ___CSS_LOADER_EXPORT___;
