export function isFocusable(element: Element): boolean {
  const tabsIndex = Number(element.getAttribute('tabIndex') ?? '-1');
  if (tabsIndex < 0) return false;
  if (tabsIndex > -1) return true;

  const formTags = ['BUTTON', 'INPUT', 'SELECT', 'TEXTAREA'];
  return formTags.includes(element.tagName) && !element.hasAttribute('disabled');
}

export function findFirstFocusableElement(
  container: Element,
): (Element & { focus: () => void }) | undefined {
  return Array.from(container.getElementsByTagName('*')).find(isFocusable) as
    | (Element & { focus: () => void })
    | undefined;
}

export function focusElement(element: Element): void {
  if (isFocusable(element)) {
    (element as Element & { focus: () => void }).focus();
  } else {
    const child = findFirstFocusableElement(element);
    if (child) {
      child.focus();
    } else {
      element.setAttribute('tabIndex', '0');
      (element as Element & { focus: () => void }).focus();
      element.setAttribute('tabIndex', '-1');
    }
  }
}
