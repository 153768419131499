import clsx from 'clsx';
import i18next, { TFunction } from 'i18next';
import React, { Component, useId } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Translator } from '../../../types/common';
import styles from './index.module.scss';
import { LanguagePolicies } from '../../../types/config';
import Checkbox from '../../../components/checkbox';
import AlertDialog from '../../../components/modals/alert-dialog';
import Alert from '../../../util/alert';

interface IndexProps {
  open: boolean;
  isContinueEnabled: boolean;
  onContinue: VoidFunction;
  onClose: VoidFunction;
  advertiserName: string;
  showPrimaryConsent: boolean;
  showMarketingConsent: boolean;
  marketingConsentTerms: string;
  languagePolicies: LanguagePolicies;
  primaryChecked: boolean;
  marketingChecked: boolean;
  onPrimaryChange: (checked: boolean) => void;
  onMarketingChange: (checked: boolean) => void;
}

function ConsentPopup(props: IndexProps): JSX.Element {
  const { t } = useTranslation();
  const id = useId();
  const {
    open,
    isContinueEnabled,
    onContinue,
    onClose,
    advertiserName,
    showPrimaryConsent,
    showMarketingConsent,
    marketingConsentTerms,
    languagePolicies,
    primaryChecked,
    marketingChecked,
    onPrimaryChange,
    onMarketingChange,
  } = props;

  const showClientConsent =
    i18next.exists('claim.landing.consent_client') && t('claim.landing.consent_client');

  const consentTranslator: Translator = (tF: TFunction): string => {
    let consentDefaultText = '';

    if (languagePolicies) {
      // Default could be: Platform Only, Platform + Client, or Custom Only
      // For Custom, locale key to overridden
      if (showPrimaryConsent) {
        // Add platform or custom
        consentDefaultText = tF('claim.landing.consent_primary_tokenized', {
          terms: languagePolicies.platform_terms,
          privacy: languagePolicies.platform_privacy,
          nft: languagePolicies.platform_nft,
        });
        // Add client text if needed (only for platform)
        if (showClientConsent) {
          consentDefaultText += ` ${tF('claim.landing.consent_client')}`;
        }
      }
    }

    return consentDefaultText;
  };

  const consentNode = (
    <div id={`${id}-modal-content`} className={styles.additionalContentsContainer}>
      {showPrimaryConsent && (
        <Checkbox
          className={clsx('claim-landing-checkbox', 'claim-landing-checkbox-primary')}
          label={consentTranslator(t)}
          checked={primaryChecked}
          onToggle={(isChecked: boolean): void => {
            onPrimaryChange(isChecked);
          }}
          required
          autoFocus
        />
      )}
      {showMarketingConsent && (
        <Checkbox
          className={clsx('claim-landing-checkbox', 'claim-landing-checkbox-marketing')}
          label={t('claim.landing.consent_marketing', {
            advertiser: advertiserName,
            terms: marketingConsentTerms,
            interpolation: { escapeValue: false },
          })}
          checked={marketingChecked}
          onToggle={(isChecked: boolean): void => {
            onMarketingChange(isChecked);
          }}
        />
      )}
    </div>
  );

  return (
    <AlertDialog
      role="dialog"
      title={t('claim.landing.consent_popup_title')}
      content={consentNode}
      open={open}
      actionTitle={t('claim.landing.consent_popup_button_continue')}
      isActionDisabled={!isContinueEnabled}
      onAction={onContinue}
      closeTitle={t('claim.landing.consent_popup_button_close')}
      onClose={onClose}
      ariaDescribedby={`${id}-modal-content`}
    />
  );
}

export default withTranslation()(ConsentPopup);

// Component based popup

type ShowProps = Omit<IndexProps, 'open'>;

export class ConsentPopupComponent extends Component {
  static createPopup(id: number, props: IndexProps): JSX.Element {
    return <ConsentPopup key={`${id}-consent-popup`} {...props} />;
  }

  static show(props: ShowProps): void {
    Alert.show((id: number, closeAlert: VoidFunction) => {
      return (open: boolean): JSX.Element => {
        return ConsentPopupComponent.createPopup(id, {
          open,
          ...props,
          onClose: () => {
            props.onClose();
            closeAlert();
          },
        });
      };
    });
  }
}
