import { TFunction } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Page from '../../components/page';
import { FormattedToken, LocalePrefix } from '../../types/common';
import { Config, ConsentType, RawFormItem } from '../../types/config';
import ViewAnchor from '../../views/view-anchor';
import MobileOnly from '../../views/view-mobile-only';
import ClaimWalletConnect from '../../views/view-wallet-connect';
import AgeGate from '../age-gate';
import ProveFlow from '../prove/flow';
import ClaimCapture from './capture';
import ClaimExpired from './expired/index';
import ClaimLanding from './landing';
import ClaimLink from './link';
import { ClaimPage, StateModel } from './model';
import ClaimOTP from './otp';
import Presenter from './presenter';

interface ViewProps extends StateModel {
  t: TFunction;
  presenter: Presenter;
  config: Config;
}

function ClaimMulti(props: ViewProps): JSX.Element | null {
  const {
    t,
    index,
    prevIndex,
    config,
    presenter,
    notificationType,
    authProvider,
    inputToken,
    formattedToken,
    avatarURL,
    inputFullName,
    inputFirstName,
    inputLastName,
    socialName,
    inputAdditional,
    acceptedPrimaryConsent,
    acceptedMarketingConsent,
    isConsentPopupContinueEnabled,
    isAuthComplete,
    dateOfBirth,
    language,
    languagePolicies,
    showMobileOnly,
    continuationSessionId,
    continuationDraftId,
    showProveFallback,
    formItems,
    preFormItems,
  } = props;

  if (index === null) {
    return null;
  }

  const prev = prevIndex ?? 0;
  const transitionDirection = index > prev ? 'left' : 'right';

  return (
    <>
      {showMobileOnly && (
        <MobileOnly
          onContinue={
            config.view_mode === 'mobile-first'
              ? (): void => presenter.onMobileFirstContinueClick()
              : undefined
          }
        />
      )}
      {!showMobileOnly && (
        <ViewAnchor prefix={LocalePrefix.claim} t={t}>
          <Page
            key="age-gate-landing"
            number={ClaimPage.ageGate}
            current={index}
            direction={transitionDirection}
          >
            <AgeGate
              format={config.age.format}
              redirect={config.age.redirect}
              redirectDelay={config.age.delay ?? 5000}
              minAge={config.age.min}
              onClose={(date) => presenter.onAgeGatePassed(date)}
            />
          </Page>

          <Page
            key="prove-flow"
            number={ClaimPage.prove}
            current={index}
            direction={transitionDirection}
          >
            <ProveFlow
              page="claim"
              config={config}
              showProveFallback
              language={language}
              languagePolicies={languagePolicies!}
              continuationSessionId={continuationSessionId}
              continuationDraftId={continuationDraftId}
              continuationPhoneNumber={inputToken}
              continuationDob={dateOfBirth ?? undefined}
              continuationFormItems={preFormItems}
              onFormItemChange={(item): void => presenter.onFormItemChange(item)}
              onBack={(): void => presenter.onBack('prove')}
            />
          </Page>
          <Page
            key="claim-expired"
            number={ClaimPage.expired}
            current={index}
            direction={transitionDirection}
          >
            <ClaimExpired />
          </Page>
          <Page
            key="claim-link"
            number={ClaimPage.link}
            current={index}
            direction={transitionDirection}
          >
            {formattedToken && (
              <ClaimLink
                notificationType={notificationType}
                formattedToken={formattedToken}
                config={config}
              />
            )}
          </Page>
          <Page
            key="claim-otp"
            number={ClaimPage.otp}
            current={index}
            direction={transitionDirection}
          >
            {formattedToken && (
              <ClaimOTP
                formattedToken={formattedToken}
                enableAccessibility={config.enable_vgar}
                onBack={(): void => {
                  presenter.onBack();
                }}
              />
            )}
          </Page>
          <Page
            key="claim-landing"
            number={ClaimPage.landing}
            current={index}
            direction={transitionDirection}
          >
            <ClaimLanding
              language={language}
              config={config}
              showProveFallback={showProveFallback}
              acceptedPrimaryConsent={acceptedPrimaryConsent}
              acceptedMarketingConsent={acceptedMarketingConsent}
              isConsentPopupContinueEnabled={isConsentPopupContinueEnabled}
              onNext={(provider): void => {
                presenter.onNext(provider, ClaimPage.landing);
              }}
              onConsentChange={(type: ConsentType, consent: boolean): void =>
                presenter.onConsentChange(type, consent)
              }
              onPreFormItemsChange={(items: [RawFormItem]): void => {
                presenter.onPreFormItemsChange(items);
              }}
            />
          </Page>
          <Page
            key="claim-capture"
            number={ClaimPage.capture}
            current={index}
            direction={transitionDirection}
          >
            {authProvider && (
              <ClaimCapture
                config={config}
                authProvider={authProvider}
                onBack={(): void => {
                  presenter.onBack();
                }}
                onRegister={(nType: string, fToken?: FormattedToken): void => {
                  presenter.onRegister(authProvider, nType, fToken);
                }}
                inputToken={inputToken}
                onTokenChange={(value): void => presenter.onTokenInputChange(value)}
                avatarURL={avatarURL}
                socialName={socialName}
                inputFullName={inputFullName}
                inputFirstName={inputFirstName}
                inputLastName={inputLastName}
                onFullNameChange={(value): void => presenter.onFullNameInputChange(value)}
                onFirstNameChange={(value): void => presenter.onFirstNameInputChange(value)}
                onLastNameChange={(value): void => presenter.onLastNameInputChange(value)}
                inputAdditional={inputAdditional}
                onAdditionalChange={(value): void => presenter.onAdditionalChange(value)}
                acceptedPrimaryConsent={acceptedPrimaryConsent}
                acceptedMarketingConsent={acceptedMarketingConsent}
                onConsentChange={(type: ConsentType, consent: boolean): void =>
                  presenter.onConsentChange(type, consent)
                }
                isAuthComplete={isAuthComplete}
                language={language}
                dateOfBirth={dateOfBirth}
                formItems={formItems}
                preFormItems={preFormItems}
                onFormItemChange={(item): void => presenter.onFormItemChange(item)}
              />
            )}
          </Page>
          <Page
            key="claim-walletConnect-view"
            number={ClaimPage.signChallenge}
            current={index}
            direction={transitionDirection}
          >
            <ClaimWalletConnect
              onNext={(): void => {
                presenter.onNext('wallet-connect', ClaimPage.signChallenge);
              }}
              onBackClick={(): void => {
                presenter.onBack();
              }}
              localePrefix={LocalePrefix.claim}
              language={language ?? undefined}
            />
          </Page>
        </ViewAnchor>
      )}
    </>
  );
}

export default withTranslation()(ClaimMulti);

ClaimMulti.Page = ClaimPage;
