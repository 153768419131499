import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Alert, Button, IconButton, Input, Link, List, ListItem, Typography } from '@mui/joy';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import React, { useEffect, useId, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Checkbox from '../../../components/checkbox';
import FormControlWrapper from '../../../components/form/form-control-wrapper';
import InputOld from '../../../components/input-legacy';
import LabLegacySelect from '../../../components/select-legacy';
import { AuthProvider } from '../../../types/common';
import { ConsentType, FormItem, LanguagePolicies } from '../../../types/config';
import useScrollAndFocusAnchor from '../../../util/hooks/useScrollAndFocusAnchor';
import { tIfExists } from '../../../util/i18next-helpers';
import FormItemsView from '../form-items-view';
import { CaptureModel } from './model';
import Presenter from './presenter';
import styles from './view.module.scss';
import truncateAndEllipsis from '../../../util/string-helpers';
import TypographyHTML from '../../../components/typography-html';
import VisuallyHiddenSpan from '../../../components/span-visually-hidden';

type ViewProps = CaptureModel & {
  t: TFunction;
  presenter: Presenter;
  authProvider: AuthProvider;
  inputToken: string;
  onTokenChange: (token: string) => void;
  avatarURL: string | null;
  socialName: string | null;
  inputFullName: string;
  inputFirstName: string;
  inputLastName: string;
  onFullNameChange: (name: string) => void;
  onFirstNameChange: (name: string) => void;
  onLastNameChange: (name: string) => void;
  inputAdditional: string | null;
  onAdditionalChange: (value: string) => void;
  formItems: FormItem[];
  onFormItemChange: (item: FormItem) => void;
  acceptedPrimaryConsent: boolean;
  acceptedMarketingConsent: boolean;
  onConsentChange: (type: ConsentType, consent: boolean) => void;
  isAuthComplete: boolean;
  languagePolicies: LanguagePolicies;
  formFocusTrigger: number;
  enableAccessibility: boolean;
};

interface ErrorSummary {
  id: string;
  inputRef?: React.RefObject<HTMLInputElement | HTMLButtonElement>;
  error: string | null;
  label: string;
}

function View(props: ViewProps): JSX.Element {
  const {
    t,
    errorToken,
    allowEmail,
    allowPhone,
    errorFullName,
    errorFirstName,
    errorLastName,
    captureName,
    nameType,
    countryCode,
    showCountryCode,
    presenter,
    disableCountryPicker,
    loading,
    errorPrimaryConsent,
    showMarketingConsent,
    showPrimaryConsent,
    errorAdditional,
    advertiserName,
    marketingConsentTerms,
    authProvider,
    inputToken,
    onTokenChange,
    inputFullName,
    inputFirstName,
    inputLastName,
    onFullNameChange,
    onFirstNameChange,
    onLastNameChange,
    avatarURL,
    captureAdditional,
    inputAdditional,
    onAdditionalChange,
    formItems,
    onFormItemChange,
    acceptedPrimaryConsent,
    acceptedMarketingConsent,
    onConsentChange,
    isAuthComplete,
    socialName,
    languagePolicies,
    showErrorSummary,
    formFocusTrigger,
    enableAccessibility,
  } = props;

  const mainContentId = `${useId()}-main-content`;
  const errorListItemRef = useRef<HTMLLIElement>(null);

  // refs to standard form elements
  const fullNameRef = React.useRef<HTMLInputElement>(null);
  const firstNameRef = React.useRef<HTMLInputElement>(null);
  const lastNameRef = React.useRef<HTMLInputElement>(null);
  const tokenRef = React.useRef<HTMLInputElement>(null);
  const labSelectRef = React.useRef<HTMLButtonElement>(null);
  const consentPrimaryRef = React.useRef<HTMLInputElement>(null);

  // hook to focus and scroll to link element targets from error summary
  useScrollAndFocusAnchor([
    errorFullName,
    errorFirstName,
    errorLastName,
    errorAdditional,
    errorPrimaryConsent,
    errorToken,
    formItems,
  ]);
  // scroll and focus first error item in the summary list, tapping ok should focus the first error field
  useEffect(() => {
    setTimeout(() => {
      if (errorListItemRef.current) {
        errorListItemRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        errorListItemRef.current.focus({ preventScroll: true });
      }
    }, 750);
  }, [
    errorFullName,
    errorFirstName,
    errorLastName,
    errorAdditional,
    errorPrimaryConsent,
    errorToken,
    formItems,
  ]);

  // token input text
  let tokenLabel: string | null = null;
  let tokenPlaceholder: string | null = null;
  let tokenType = 'text';
  if (allowEmail && allowPhone) {
    tokenLabel = tIfExists('claim.landing.input_label_email_phone');
    tokenPlaceholder = tIfExists('claim.landing.input_placeholder_email_phone');
  } else if (allowEmail) {
    tokenLabel = tIfExists('claim.landing.input_label_email');
    tokenPlaceholder = tIfExists('claim.landing.input_placeholder_email');
    tokenType = 'email';
  } else if (allowPhone) {
    tokenLabel = tIfExists('claim.landing.input_label_phone');
    tokenPlaceholder = tIfExists('claim.landing.input_placeholder_phone');
    tokenType = 'tel';
  }

  // name input text
  const fullNameLabel = tIfExists('claim.landing.input_label_full_name');
  const fullNamePlaceholder = tIfExists('claim.landing.input_placeholder_full_name');

  const firstNameLabel = tIfExists('claim.landing.input_label_first_name');
  const firstNamePlaceholder = tIfExists('claim.landing.input_placeholder_first_name');

  const lastNameLabel = tIfExists('claim.landing.input_label_last_name');
  const lastNamePlaceholder = tIfExists('claim.landing.input_placeholder_last_name');

  // check for a client consent (override)
  const showClientConsent = tIfExists('claim.landing.consent_client');

  // Primary could be: Platform Only, Platform + Client, or Custom Only
  // For Custom, locale key to overridden
  let consentPrimaryText = '';
  if (showPrimaryConsent) {
    // add platform or custom
    consentPrimaryText = t('claim.landing.consent_primary_tokenized', {
      terms: languagePolicies.platform_terms,
      privacy: languagePolicies.platform_privacy,
      nft: languagePolicies.platform_nft,
    });
    // add client text if needed (only for platform)
    if (showClientConsent) {
      consentPrimaryText += ` ${t('claim.landing.consent_client')}`;
    }
  }

  const stdErrors: ErrorSummary[] = [
    {
      id: 'full-name',
      error: errorFullName,
      label: fullNameLabel ?? '',
      inputRef: fullNameRef,
    },
    {
      id: 'first-name',
      error: errorFirstName,
      label: firstNameLabel ?? '',
      inputRef: firstNameRef,
    },
    {
      id: 'last-name',
      error: errorLastName,
      label: lastNameLabel ?? '',
      inputRef: lastNameRef,
    },
    {
      id: 'token',
      error: errorToken,
      label: tokenLabel ?? '',
      inputRef: tokenRef,
    },
    {
      id: 'lab-select',
      error: errorAdditional,
      label: t('claim.landing.additional.label'),
      inputRef: labSelectRef,
    },
    {
      id: 'consent-primary',
      error: errorPrimaryConsent,
      label: t('claim.landing.consent_primary_aria_label'),
      inputRef: consentPrimaryRef,
    },
  ];

  // map 'standard input' errors to list items
  const FormStdErrorsListItems: JSX.Element[] = stdErrors
    .filter((item) => item.error)
    .map((item) => {
      return (
        <ListItem key={item.id}>
          <Link href={`#form-std-${item.id}`} level="body-sm">
            {item.label}
          </Link>
        </ListItem>
      );
    });

  // map 'form item' errors to list items
  const FormItemErrorsListItems: JSX.Element[] = formItems
    .filter((item) => item.error)
    .map((item) => {
      let label = '';
      if (item.type === 'checkbox') {
        label = truncateAndEllipsis(t(`claim.form_item.${item.id}.text`) ?? '');
      } else if (item.type === 'dropdown') {
        label = t(`claim.form_item.${item.id}.label`);
      }
      return (
        <ListItem key={item.id}>
          <Link href={`#form-item-${item.type}-${item.id}`} level="body-sm">
            {label}
          </Link>
        </ListItem>
      );
    });

  const formItemsWithRef = formItems.map((item) => {
    return { ...item, inputRef: useRef<HTMLInputElement | HTMLButtonElement>(null) };
  });

  // focus on the first error item
  useEffect(() => {
    const firstStandardError = stdErrors.find((item) => item.error);
    const firstFormItemError = formItemsWithRef.find((item) => item.error);
    if (firstStandardError) {
      firstStandardError.inputRef?.current?.focus();
    } else if (firstFormItemError) {
      firstFormItemError.inputRef?.current?.focus();
    }
  }, [formFocusTrigger]);

  // summary of all errors as a list
  const ErrorSummary: JSX.Element = (
    <Alert aria-live="polite" sx={{ width: '100%' }}>
      <div>
        <Typography id="list-title" level="title-md">
          {t('claim.landing.form_review_required')}
        </Typography>

        <List
          aria-labelledby="list-title"
          component="ol"
          marker="decimal"
          sx={{
            '--ListItem-paddingY': '2px',
            '--ListItem-minHeight': '20px',
          }}
        >
          {FormStdErrorsListItems}
          {FormItemErrorsListItems}
        </List>
      </div>
    </Alert>
  );

  const headerText = t('claim.landing.detail_text');
  let pageTitle = tIfExists('claim.page_title') ?? '';
  const headerTextSuffix = `${t('claim.landing.progress_context')} (${t('common.progress_step', { current: 1, total: 2 })})`;
  if (enableAccessibility) {
    pageTitle = `${pageTitle} - ${headerText} - ${headerTextSuffix}`;
  }

  return (
    <>
      {enableAccessibility && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      <div className={clsx('claim-form-capture-container', styles.container)}>
        {/* <SkipToLink targetId={mainContentId} text={t('common.skip_to_content')} /> */}

        <div className={clsx('claim-form-capture-inner-container', styles.innerContainer)}>
          <main id={mainContentId} className={styles.main}>
            <div className={styles.headerContainer}>
              <div className={styles.headerSpacer}>
                {!isAuthComplete && (
                  <div role="navigation">
                    <IconButton
                      className="claim-back-button"
                      onClick={(): void => presenter.onBackClick()}
                      aria-label={t('common.back_icon_aria_label')}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </div>
                )}
              </div>

              <Typography
                className={clsx('claim-form-capture-detail-text', styles.text, styles.header)}
                component="h1"
                level="h3"
              >
                {headerText}
                {enableAccessibility && <VisuallyHiddenSpan>{headerTextSuffix}</VisuallyHiddenSpan>}
              </Typography>

              <div className={styles.headerSpacer} />
            </div>

            {authProvider !== 'token' && (
              <div className={styles.socialInfoContainer}>
                {avatarURL && avatarURL !== '' && (
                  <img
                    className={clsx('claim-form-capture-avatar', styles.avatar)}
                    alt="Avatar"
                    src={avatarURL}
                  />
                )}
                {socialName && socialName !== '' && (
                  <Typography
                    className={(clsx('claim-form-capture-welcome-label'), styles.welcomeNameLabel)}
                  >
                    {`${t('claim.landing.greeting')} ${socialName}!`}
                  </Typography>
                )}
              </div>
            )}

            <div className={(clsx('claim-form-items-container'), styles.formContainer)}>
              {captureName && nameType === 'full_name' && (
                <FormControlWrapper
                  id="form-std-full-name"
                  className={clsx('claim-form-item-control')}
                  label={fullNameLabel ?? undefined}
                  helper={(errorFullName && t(errorFullName)) ?? undefined}
                  disabled={loading}
                  required
                  error={!!errorFullName}
                >
                  <Input
                    className="claim-form-item-name"
                    value={inputFullName}
                    type="text"
                    autoComplete="name"
                    placeholder={fullNamePlaceholder ?? undefined}
                    onChange={(event): void => onFullNameChange(event.target.value)}
                    onBlur={(event): void => {
                      onFullNameChange(event.target.value.trim());
                      presenter.onFieldBlur('full_name');
                    }}
                    slotProps={{ input: { ref: fullNameRef } }}
                  />
                </FormControlWrapper>
              )}

              {captureName && (nameType === 'first_name' || nameType === 'first_and_last') && (
                <FormControlWrapper
                  id="form-std-first-name"
                  className={clsx('claim-form-item-control')}
                  label={firstNameLabel ?? undefined}
                  helper={(errorFirstName && t(errorFirstName)) ?? undefined}
                  disabled={loading}
                  required
                  error={!!errorFirstName}
                >
                  <Input
                    className="claim-form-item-name"
                    value={inputFirstName}
                    type="text"
                    autoComplete="given-name"
                    placeholder={firstNamePlaceholder ?? undefined}
                    onChange={(event): void => onFirstNameChange(event.target.value)}
                    onBlur={(event): void => {
                      onFirstNameChange(event.target.value.trim());
                      presenter.onFieldBlur('first_name');
                    }}
                    slotProps={{ input: { ref: firstNameRef } }}
                  />
                </FormControlWrapper>
              )}

              {captureName && (nameType === 'last_name' || nameType === 'first_and_last') && (
                <FormControlWrapper
                  id="form-std-last-name"
                  className={clsx('claim-form-item-control')}
                  label={lastNameLabel ?? undefined}
                  helper={(errorLastName && t(errorLastName)) ?? undefined}
                  disabled={loading}
                  required
                  error={!!errorLastName}
                >
                  <Input
                    className="claim-form-item-name"
                    value={inputLastName}
                    type="text"
                    autoComplete="family-name"
                    placeholder={lastNamePlaceholder ?? undefined}
                    onChange={(event): void => onLastNameChange(event.target.value)}
                    onBlur={(event): void => {
                      onLastNameChange(event.target.value.trim());
                      presenter.onFieldBlur('last_name');
                    }}
                    slotProps={{ input: { ref: lastNameRef } }}
                  />
                </FormControlWrapper>
              )}

              {authProvider === 'token' && (
                <FormControlWrapper
                  id="form-std-token"
                  className={clsx('claim-form-item-control')}
                  label={tokenLabel ?? undefined}
                  helper={(errorToken && t(errorToken)) ?? undefined}
                  disabled={loading}
                  required
                  error={!!errorToken}
                >
                  <InputOld
                    className={clsx('claim-form-item-token')}
                    value={inputToken}
                    placeholder={tokenPlaceholder}
                    leftText={showCountryCode ? countryCode : null}
                    onChange={(value: string): void => {
                      presenter.onTokenChange(value);
                      onTokenChange(value);
                    }}
                    onBlur={(): void => presenter.onFieldBlur('token')}
                    onLeftClick={(): void => presenter.onCountryCodeClick()}
                    disableLeft={disableCountryPicker}
                    type={tokenType}
                    inputRef={tokenRef}
                  />
                </FormControlWrapper>
              )}

              {captureAdditional && captureAdditional.length > 0 && (
                <LabLegacySelect
                  id="form-std-lab-select"
                  className={clsx('claim-form-item-control')}
                  disabled={loading}
                  helper={(errorAdditional && t(errorAdditional)) ?? undefined}
                  error={!!errorAdditional}
                  selected={inputAdditional}
                  items={captureAdditional}
                  onChange={(value): void => {
                    onAdditionalChange(value ?? '');
                  }}
                  onBlur={(listViewed): void => {
                    if (listViewed) {
                      presenter.onFieldBlur('legacy_select');
                    }
                  }}
                  inputRef={labSelectRef}
                />
              )}

              {authProvider === 'token' && showPrimaryConsent && (
                <FormControlWrapper
                  id="form-std-consent-primary"
                  className={clsx('claim-form-item-control')}
                  helper={(errorPrimaryConsent && t(errorPrimaryConsent)) ?? undefined}
                  disabled={loading}
                  required
                  error={errorPrimaryConsent !== null}
                >
                  <Checkbox
                    className={clsx('claim-form-item-checkbox', 'claim-form-item-checkbox-primary')}
                    label={consentPrimaryText}
                    checked={acceptedPrimaryConsent}
                    onToggle={(consent: boolean): void => {
                      onConsentChange('primary', consent);
                    }}
                    onBlur={(): void => presenter.onFieldBlur('primary_consent')}
                    ref={consentPrimaryRef}
                  />
                </FormControlWrapper>
              )}

              {authProvider === 'token' && showMarketingConsent && (
                <FormControlWrapper
                  id="form-std-marketing-checkbox"
                  className={clsx('claim-form-item-control')}
                  disabled={loading}
                >
                  <Checkbox
                    className={clsx(
                      'claim-form-item-checkbox',
                      'claim-form-item-checkbox-marketing',
                    )}
                    label={t('claim.landing.consent_marketing', {
                      advertiser: advertiserName,
                      terms: marketingConsentTerms,
                      interpolation: { escapeValue: false },
                    })}
                    checked={acceptedMarketingConsent}
                    onToggle={(consent: boolean): void => onConsentChange('marketing', consent)}
                  />
                </FormControlWrapper>
              )}

              <FormItemsView
                containerClassName={styles.formContainer}
                formItems={formItemsWithRef}
                onFormItemChange={onFormItemChange}
                onFormItemBlur={(item) => presenter.onFormItemBlur(item)}
              />
            </div>

            {/* TODO: Side effect: ugly layout shift. Unsure how this should be handled. */}
            {showErrorSummary &&
              (FormStdErrorsListItems.length > 0 || FormItemErrorsListItems.length > 0) &&
              ErrorSummary}

            <div className={styles.spacer1} role="none" />
            <Button
              className={clsx('claim-form-button', styles.button)}
              loading={loading}
              onClick={(): void => presenter.onNextClick(authProvider)}
            >
              {t('claim.landing.button_text')}
            </Button>
          </main>

          <div className={styles.spacer2} role="none" />

          <div className={clsx('claim-form-footer-space', styles.footerSpace)} />

          <TypographyHTML
            role="contentinfo"
            className={clsx('claim-form-capture-footer', styles.footer)}
            level="footer"
            htmlContent={t('claim.landing.footer')}
          />
        </div>
      </div>
    </>
  );
}

export default withTranslation()(View);
