import { Option } from '@mui/joy';
import { TFunction } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { CaptureAdditionalItem } from '../../types/config';
import { tIfExists } from '../../util/i18next-helpers';
import Select from '../select';
import FormControlWrapper, { FormControlWrapperProps } from '../form/form-control-wrapper';

export interface LabSelectProps
  extends Omit<
    FormControlWrapperProps,
    'onBlur' | 'onChange' | 'required' | 'label' | 'placeholder'
  > {
  selected: string | null; // TODO: should be a 'CaptureAdditionalItem'
  items: CaptureAdditionalItem[];
  onChange: (item: string | null) => void;
  onBlur?: (viewed: boolean) => void;
  inputRef?: React.RefObject<HTMLButtonElement>;
}
export interface ILabSelectProps extends LabSelectProps {
  t: TFunction;
}

function LabSelect({ onBlur = undefined, ...props }: ILabSelectProps): JSX.Element {
  const { t, selected, items, onChange, ...restProps } = props;

  const placeholder = tIfExists('claim.landing.additional.placeholder') ?? '';
  const label = tIfExists('claim.landing.additional.label') ?? '';

  const options: JSX.Element[] = items.map((item) => (
    <Option key={item.name} value={item.value}>
      {t(item.value)}
    </Option>
  ));

  const { size } = props;
  return (
    <FormControlWrapper {...restProps} label={label} required>
      <Select
        className="claim-form-item-select"
        size={size}
        value={selected}
        required
        placeholder={placeholder}
        onChange={(newValue): void => onChange(newValue)}
        onBlur={(visible) => onBlur?.(visible)}
        slotProps={{ button: { ref: props.inputRef } }}
      >
        {options}
      </Select>
    </FormControlWrapper>
  );
}

export default withTranslation()(LabSelect);
