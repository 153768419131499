import { Theme, extendTheme } from '@mui/joy/styles';
import chroma from 'chroma-js';
import { contrastColor } from 'contrast-color';
import { Config } from '../types/config';
import { generateColorPalette } from '../util/palette-tools';

const extendJoyTheme = (config: Config): Theme => {
  const configTheme = config.style.theme;
  const themePalette = configTheme?.palette;
  const themePaletteLink = configTheme?.palette?.link;
  const themeTypography = configTheme?.typography;
  const themeButtonLarge = configTheme?.button?.size?.large;
  const themeButtonSmall = configTheme?.button?.size?.small;
  const themeButtonPrimary = configTheme?.button?.variant?.primary;
  const themeButtonSecondary = configTheme?.button?.variant?.secondary;
  const themeInput = configTheme?.input;
  const themeCheckbox = configTheme?.checkbox;

  const primaryAutoPalette = generateColorPalette(themePalette!.primary!);
  const dangerAutoPalette = generateColorPalette(themePalette!.warning!);

  return extendTheme({
    cssVarPrefix: 'smt',
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 856, // desktop
        lg: 1200,
        xl: 1536,
      },
    },
    fontFamily: {
      display: themeTypography?.mobile?.h6?.font_family,
      body: themeTypography?.mobile?.body2?.font_family,
    },
    fontSize: {
      xxs: '0.625rem',
    },
    typography: {
      footer: {
        fontSize: 'var(--smt-fontSize-xxs, 0.6rem)',
        fontWeight: 'var(--smt-fontWeight-sm, 300)',
        lineHeight: 'var(--smt-lineHeight-xs, 1.5)',
      },
    },
    colorSchemes: {
      light: {
        palette: {
          primary: {
            ...(themeButtonPrimary && {
              ...primaryAutoPalette,
              solidActiveBg: themeButtonPrimary.active_background_color,
              solidActiveBorder: themeButtonPrimary.active_border_color,
              solidHoverBg: chroma(themeButtonPrimary.active_background_color!).darken(0.2).hex(),
              solidDisabledColor: themeButtonPrimary.inactive_background_color,
            }),
          },
          danger: {
            ...dangerAutoPalette,
          },
          background: {
            desktop_backdrop: themePalette?.backdrop,
            body: themePalette?.background,
            surface: themePalette?.background,
            popup: themePalette?.background,
          },
          link: {
            active: themePaletteLink?.active,
            hover: themePaletteLink?.hover,
            unvisited: themePaletteLink?.unvisited,
            visited: themePaletteLink?.visited,
          },
        },
      },
    },
    components: {
      JoyButton: {
        defaultProps: {
          fullWidth: true,
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            paddingTop: '0px',
            paddingBottom: '0px',
            borderRadius: themeButtonLarge?.border_radius,
            height: 'fit-content',
            ...(ownerState.variant === 'solid' && {
              color: themeButtonPrimary?.active_font_color ?? themeButtonLarge?.font_color,
              borderColor: themeButtonPrimary?.active_border_color,
              borderStyle: themeButtonLarge?.border_style,
              borderWidth: themeButtonLarge?.border_thickness,
              backgroundColor: themeButtonPrimary?.active_background_color,
              ...(ownerState.disabled && {
                color:
                  themeButtonPrimary?.inactive_font_color ?? themeButtonPrimary?.active_font_color,
                borderColor: themeButtonPrimary?.inactive_border_color,
                backgroundColor: themeButtonPrimary?.inactive_background_color,
              }),
            }),
            ...(ownerState.variant === 'solid' &&
              ownerState.color === 'neutral' &&
              themeButtonSecondary && {
                color: themeButtonSecondary?.active_font_color ?? themeButtonLarge?.font_color,
                borderColor: themeButtonSecondary?.active_border_color,
                backgroundColor: themeButtonSecondary?.active_background_color,
                ...(ownerState.disabled && {
                  color:
                    themeButtonSecondary?.inactive_font_color ??
                    themeButtonSecondary?.active_font_color,
                  borderColor: themeButtonSecondary?.inactive_border_color,
                  backgroundColor: themeButtonSecondary?.inactive_background_color,
                }),
              }),
            ...(ownerState.size === 'md' && {
              minHeight: themeButtonLarge?.height,
              fontSize: themeButtonLarge?.font_size,
              fontFamily: themeButtonLarge?.font_family,
              fontWeight: themeButtonLarge?.font_weight,
            }),
            ...(ownerState.size === 'sm' && {
              minHeight: themeButtonSmall?.height,
              fontSize: themeButtonSmall?.font_size,
              fontFamily: themeButtonSmall?.font_family,
              fontWeight: themeButtonSmall?.font_weight,
            }),
            ...(ownerState.variant === 'auth' && {
              minHeight: themeButtonLarge?.height,
              fontSize: themeButtonLarge?.font_size,
              fontFamily: themeButtonLarge?.font_family,
              fontWeight: themeButtonLarge?.font_weight,
              marginTop: '6px',
              marginBottom: '6px',
              color: '#3F3F3F',
              background: '#ffffff',
              border: '1px solid #ececec',
              '&:hover, &:active': {
                background: '#f5f5f5',
              },
              ...(ownerState.disabled && {
                background: '#ffffff',
                color: '#a3a3a3',
              }),
            }),
          }),
        },
      },
      JoyInput: {
        styleOverrides: {
          root: {
            height: '44px',
            backgroundColor: themeInput?.background_color,
            borderStyle: themeInput?.border_style,
            borderWidth: themeInput?.border_width,
            '--Input-radius': themeInput?.border_radius,
            '& input': {
              '&::placeholder': {
                color: themeTypography?.desktop?.placeholder?.font_color,
              },
            },
          },
        },
      },
      JoySelect: {
        styleOverrides: {
          root: {
            height: '44px',
            backgroundColor: themeInput?.background_color,
            borderRadius: themeInput?.border_radius,
            borderStyle: themeInput?.border_style,
            borderWidth: themeInput?.border_width,
            '&:focus-within::before': {
              boxShadow:
                'inset 0 0 0 var(--Select-focusedThickness) var(--Select-focusedHighlight)',
            },
          },
        },
      },
      JoyCheckbox: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            '& .MuiCheckbox-checkbox': {
              backgroundColor: themeCheckbox?.background_color,
              borderColor: themeCheckbox?.border_color,
              borderRadius: themeCheckbox?.border_radius,
              borderStyle: themeCheckbox?.border_style,
              borderWidth: themeCheckbox?.border_width,
              '&:hover, &:active': {
                backgroundColor: themeCheckbox?.background_color,
              },
            },
          },
        },
      },
      JoyFormLabel: {
        styleOverrides: {
          root: ({ theme }) => ({
            [theme.breakpoints.down('md')]: {
              color: themeTypography?.mobile?.subtitle2?.font_color,
              fontSize: themeTypography?.mobile?.subtitle2?.font_size,
              fontWeight: themeTypography?.mobile?.subtitle2?.font_weight,
              fontFamily: themeTypography?.mobile?.subtitle2?.font_family,
            },
            [theme.breakpoints.up('md')]: {
              color: themeTypography?.desktop?.subtitle2?.font_color,
              fontSize: themeTypography?.desktop?.subtitle2?.font_size,
              fontWeight: themeTypography?.desktop?.subtitle2?.font_weight,
              fontFamily: themeTypography?.desktop?.subtitle2?.font_family,
            },
          }),
        },
      },
      JoyDialogTitle: {
        styleOverrides: {
          root: ({ theme }) => ({
            [theme.breakpoints.down('md')]: {
              color: themeTypography?.mobile?.subtitle1?.font_color,
              fontSize: themeTypography?.mobile?.subtitle1?.font_size,
              fontWeight: themeTypography?.mobile?.subtitle1?.font_weight,
              fontFamily: themeTypography?.mobile?.subtitle1?.font_family,
            },
            [theme.breakpoints.up('md')]: {
              color: themeTypography?.desktop?.subtitle1?.font_color,
              fontSize: themeTypography?.desktop?.subtitle1?.font_size,
              fontWeight: themeTypography?.desktop?.subtitle1?.font_weight,
              fontFamily: themeTypography?.desktop?.subtitle1?.font_family,
            },
          }),
        },
      },
      JoyModalDialog: {
        styleOverrides: {
          root: ({ theme }) => ({
            [theme.breakpoints.down('md')]: {
              '--Card-padding': '26px',
            },
          }),
        },
      },
      JoyAlert: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...(ownerState.variant === 'soft' && {
              [theme.breakpoints.down('md')]: {
                '& li': {
                  color: themePaletteLink?.active ?? themeTypography?.mobile?.subtitle1?.font_color,
                },
              },
              [theme.breakpoints.up('md')]: {
                color: themePaletteLink?.active ?? themeTypography?.desktop?.subtitle1?.font_color,
              },
              '& a': {
                color: themePaletteLink?.active ?? 'inherit', // Inherit the text color from the parent Typography component
                textDecoration: 'none',
                '&:hover': {
                  color: themePaletteLink?.hover ?? 'inherit',
                  textDecoration: 'none',
                },
                '&:visited': {
                  color: themePaletteLink?.visited ?? 'inherit',
                },
                '&:active': {
                  color: themePaletteLink?.active ?? 'inherit',
                },
              },
              backgroundColor: contrastColor({
                bgColor: themePalette?.background ?? '#fff',
                fgDarkColor: 'rgba(0,0,0,0.05)',
                fgLightColor: 'rgba(255,255,255,0.1)',
              }),
            }),
          }),
        },
      },
      JoyTypography: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            '& span a': {
              color: themePaletteLink?.active ?? 'inherit', // Inherit the text color from the parent Typography component
              textDecoration: 'underline',
              '&:hover': {
                color: themePaletteLink?.hover ?? 'inherit',
                textDecoration: 'underline',
              },
              '&:visited': {
                color: themePaletteLink?.visited ?? 'inherit',
              },
              '&:active': {
                color: themePaletteLink?.active ?? 'inherit',
              },
            },
            [theme.breakpoints.down('md')]: {
              ...(ownerState.level === 'h3' && {
                color: themeTypography?.mobile?.h5?.font_color,
                fontSize: themeTypography?.mobile?.h5?.font_size,
                fontWeight: themeTypography?.mobile?.h5?.font_weight,
                fontFamily: themeTypography?.mobile?.h5?.font_family,
              }),
              ...(ownerState.level === 'h4' && {
                color: themeTypography?.mobile?.h6?.font_color,
                fontSize: themeTypography?.mobile?.h6?.font_size,
                fontWeight: themeTypography?.mobile?.h6?.font_weight,
                fontFamily: themeTypography?.mobile?.h6?.font_family,
              }),
              ...(ownerState.level === 'title-lg' && {
                color: themeTypography?.mobile?.subtitle1?.font_color,
                fontSize: themeTypography?.mobile?.subtitle1?.font_size,
                fontWeight: themeTypography?.mobile?.subtitle1?.font_weight,
                fontFamily: themeTypography?.mobile?.subtitle1?.font_family,
              }),
              ...(ownerState.level === 'title-md' && {
                color: themeTypography?.mobile?.subtitle1?.font_color,
                fontSize: themeTypography?.mobile?.subtitle1?.font_size,
                fontWeight: themeTypography?.mobile?.subtitle1?.font_weight,
                fontFamily: themeTypography?.mobile?.subtitle1?.font_family,
              }),
              ...(ownerState.level === 'title-sm' && {
                color: themeTypography?.mobile?.subtitle2?.font_color,
                fontSize: themeTypography?.mobile?.subtitle2?.font_size,
                fontWeight: themeTypography?.mobile?.subtitle2?.font_weight,
                fontFamily: themeTypography?.mobile?.subtitle2?.font_family,
              }),
              ...(ownerState.level === 'body-lg' && {
                color: themeTypography?.mobile?.body1?.font_color,
                fontSize: themeTypography?.mobile?.body1?.font_size,
                fontWeight: themeTypography?.mobile?.body1?.font_weight,
                fontFamily: themeTypography?.mobile?.body1?.font_family,
              }),
              ...(ownerState.level === 'body-md' && {
                color: themeTypography?.mobile?.body2?.font_color,
                fontSize: themeTypography?.mobile?.body2?.font_size,
                fontWeight: themeTypography?.mobile?.body2?.font_weight,
                fontFamily: themeTypography?.mobile?.body2?.font_family,
              }),
              ...(ownerState.level === 'body-sm' && {
                color: themeTypography?.mobile?.body2?.font_color,
                fontSize: themeTypography?.mobile?.body2?.font_size,
                fontWeight: themeTypography?.mobile?.body2?.font_weight,
                fontFamily: themeTypography?.mobile?.body2?.font_family,
              }),
              ...(ownerState.level === 'body-xs' && {
                color: themeTypography?.mobile?.body2?.font_color,
                fontSize: themeTypography?.mobile?.body2?.font_size,
                fontWeight: themeTypography?.mobile?.body2?.font_weight,
                fontFamily: themeTypography?.mobile?.body2?.font_family,
              }),
              ...(ownerState.level === 'footer' && {
                color: themeTypography?.mobile?.footer1?.font_color,
                fontSize: themeTypography?.mobile?.footer1?.font_size ?? undefined,
                fontWeight: themeTypography?.mobile?.footer1?.font_weight,
                fontFamily: themeTypography?.mobile?.footer1?.font_family,
              }),
            },
            [theme.breakpoints.up('md')]: {
              ...(ownerState.level === 'h3' && {
                color: themeTypography?.desktop?.h5?.font_color,
                fontSize: themeTypography?.desktop?.h5?.font_size,
                fontWeight: themeTypography?.desktop?.h5?.font_weight,
                fontFamily: themeTypography?.desktop?.h5?.font_family,
              }),
              ...(ownerState.level === 'h4' && {
                color: themeTypography?.desktop?.h6?.font_color,
                fontSize: themeTypography?.desktop?.h6?.font_size,
                fontWeight: themeTypography?.desktop?.h6?.font_weight,
                fontFamily: themeTypography?.desktop?.h6?.font_family,
              }),
              ...(ownerState.level === 'title-lg' && {
                color: themeTypography?.desktop?.subtitle1?.font_color,
                fontSize: themeTypography?.desktop?.subtitle1?.font_size,
                fontWeight: themeTypography?.desktop?.subtitle1?.font_weight,
                fontFamily: themeTypography?.desktop?.subtitle1?.font_family,
              }),
              ...(ownerState.level === 'title-md' && {
                color: themeTypography?.desktop?.subtitle1?.font_color,
                fontSize: themeTypography?.desktop?.subtitle1?.font_size,
                fontWeight: themeTypography?.desktop?.subtitle1?.font_weight,
                fontFamily: themeTypography?.desktop?.subtitle1?.font_family,
              }),
              ...(ownerState.level === 'title-sm' && {
                color: themeTypography?.desktop?.subtitle2?.font_color,
                fontSize: themeTypography?.desktop?.subtitle2?.font_size,
                fontWeight: themeTypography?.desktop?.subtitle2?.font_weight,
                fontFamily: themeTypography?.desktop?.subtitle2?.font_family,
              }),
              ...(ownerState.level === 'body-lg' && {
                color: themeTypography?.desktop?.body1?.font_color,
                fontSize: themeTypography?.desktop?.body1?.font_size,
                fontWeight: themeTypography?.desktop?.body1?.font_weight,
                fontFamily: themeTypography?.desktop?.body1?.font_family,
              }),
              ...(ownerState.level === 'body-md' && {
                color: themeTypography?.desktop?.body2?.font_color,
                fontSize: themeTypography?.desktop?.body2?.font_size,
                fontWeight: themeTypography?.desktop?.body2?.font_weight,
                fontFamily: themeTypography?.desktop?.body2?.font_family,
              }),
              ...(ownerState.level === 'body-sm' && {
                color: themeTypography?.desktop?.body2?.font_color,
                fontSize: themeTypography?.desktop?.body2?.font_size,
                fontWeight: themeTypography?.desktop?.body2?.font_weight,
                fontFamily: themeTypography?.desktop?.body2?.font_family,
              }),
              ...(ownerState.level === 'body-xs' && {
                color: themeTypography?.desktop?.body2?.font_color,
                fontSize: themeTypography?.desktop?.body2?.font_size,
                fontWeight: themeTypography?.desktop?.body2?.font_weight,
                fontFamily: themeTypography?.desktop?.body2?.font_family,
              }),
              ...(ownerState.level === 'footer' && {
                color: themeTypography?.desktop?.footer1?.font_color,
                fontSize: themeTypography?.desktop?.footer1?.font_size,
                fontWeight: themeTypography?.desktop?.footer1?.font_weight,
                fontFamily: themeTypography?.desktop?.footer1?.font_family,
              }),
            },
          }),
        },
      },
    },
  });
};

export default extendJoyTheme;
