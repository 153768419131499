import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { CaptureModel as Model } from './model';
import { validateEmail, validatePhone } from '../../../util/validation';
import {
  Config,
  ConsentType,
  FormItem,
  LanguagePolicies,
  Token,
  RawFormItem,
  AgeConfig,
} from '../../../types/config';
import Countries, { CountryInfo } from '../../../util/countries';
import CountryPicker from '../../country-select';
import { NotificationType, VLabsParams } from '../../../types/vlabs-user';
import Log from '../../../util/log';
import ErrorPopup from '../../error';
import { AuthProvider, FormattedToken } from '../../../types/common';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

interface ErrorBag {
  errorPrimaryConsent: string | null;
  errorFullName: string | null;
  errorFirstName: string | null;
  errorLastName: string | null;
  errorEmail: string | null;
  errorPhone: string | null;
  errorToken: string | null;
  errorAdditional: string | null;
  errorFormItems: FormItem[];
}

type Bag = Entries<ErrorBag>;

export default class Presenter {
  advertiserName: string;

  age: AgeConfig;

  config: Config;

  dateOfBirth: string | null;

  language: string;

  model: Model;

  selectedCountry: CountryInfo | null = null;

  token: Token;

  acceptedPrimaryConsent: boolean;

  acceptedMarketingConsent: boolean;

  inputToken: string;

  formattedToken: FormattedToken | null = null;

  inputFirstName: string;

  inputLastName: string;

  inputFullName: string;

  inputAdditional: string | null;

  isAuthComplete: boolean;

  onConsentChange: (type: ConsentType, consent: boolean) => void;

  onRegister: (notificationType: string, formattedToken?: FormattedToken) => void;

  onBack: VoidFunction;

  languagePolicies: LanguagePolicies;

  formItems: FormItem[];

  onFormItemChange: (formItem: FormItem) => void;

  preFormItems: RawFormItem[];

  supportedCountryCodes: CountryInfo[] = [];

  constructor(
    model: Model,
    config: Config,
    inputToken: string,
    inputFullName: string,
    inputFirstName: string,
    inputLastName: string,
    inputAdditional: string | null,
    acceptedPrimaryConsent: boolean,
    acceptedMarketingConsent: boolean,
    language: string,
    dateOfBirth: string | null,
    isAuthComplete: boolean,
    onConsentChange: (type: ConsentType, consent: boolean) => void,
    onBack: VoidFunction,
    onRegister: (notificationType: string, formattedToken?: FormattedToken) => void,
    languagePolicies: LanguagePolicies,
    formItems: FormItem[],
    preFormItems: RawFormItem[],
    onFormItemChange: (formItem: FormItem) => void,
  ) {
    this.model = model;
    this.advertiserName = config.advertiser_name ?? '';
    this.config = config;
    this.age = config.age;
    this.token = config.token;
    this.inputToken = inputToken;
    this.inputFullName = inputFullName;
    this.inputFirstName = inputFirstName;
    this.inputLastName = inputLastName;
    this.inputAdditional = inputAdditional;
    this.acceptedPrimaryConsent = acceptedPrimaryConsent;
    this.acceptedMarketingConsent = acceptedMarketingConsent;
    this.language = language;
    this.dateOfBirth = dateOfBirth;
    this.isAuthComplete = isAuthComplete;
    this.onConsentChange = onConsentChange;
    this.onBack = onBack;
    this.onRegister = onRegister;
    this.languagePolicies = languagePolicies;
    this.formItems = formItems;
    this.preFormItems = preFormItems;
    this.onFormItemChange = onFormItemChange;
  }

  async onAttach(): Promise<void> {
    const { allow_phone: allowPhone, regions } = this.token;
    if (allowPhone) {
      this.supportedCountryCodes = await Countries.getCountryInfoForRegions(regions ?? []);
      await this.updateDefaultCountryCode(regions);
    }
  }

  async updateDefaultCountryCode(regions: string[] | null): Promise<void> {
    // set the default country immediately (other look ups are async)
    this.selectedCountry = Countries.getDefault();
    this.model.countryCode = this.selectedCountry?.phonePrefix ?? null;

    // disable picker if there is only one choice
    if (regions?.length === 1) {
      this.model.disableCountryPicker = true;
    }

    // if we have a continuation token, show the country code
    if (this.inputToken) {
      this.onTokenChange(this.inputToken);
    }

    // find a recommended country, if any, to replace the default
    const recommendedCountry = await Countries.getRecommendedCountryRestrictedByRegion(regions);
    Log.info('recommendedCountry', recommendedCountry);

    this.selectedCountry = recommendedCountry;
    this.model.countryCode = this.selectedCountry?.phonePrefix ?? null;
  }

  // validate on blur
  onFieldBlur(
    field: 'full_name' | 'first_name' | 'last_name' | 'token' | 'legacy_select' | 'primary_consent',
  ): void {
    if (field === 'full_name') {
      this.model.errorFullName = this.validateName(this.inputFullName, field);
    } else if (field === 'first_name') {
      this.model.errorFirstName = this.validateName(this.inputFirstName, field);
    } else if (field === 'last_name') {
      this.model.errorLastName = this.validateName(this.inputLastName, field);
    } else if (field === 'token') {
      const { allow_phone: allowPhone, allow_email: allowEmail } = this.token;
      this.model.errorToken = this.validateToken(this.inputToken, allowPhone, allowEmail);
    } else if (field === 'legacy_select') {
      this.model.errorAdditional = this.validateSelect(this.inputAdditional);
    } else if (field === 'primary_consent') {
      this.model.errorPrimaryConsent = this.validatePrimaryConsent(this.acceptedPrimaryConsent);
    }
  }

  // validate on blur
  onFormItemBlur(item: FormItem): void {
    const hasError = !this.isFormItemValid(item);
    const updatedItem = item;
    updatedItem.error = hasError;
    this.onFormItemChange(updatedItem);
  }

  onTokenChange(value: string): void {
    const { allow_phone: allowPhone } = this.token;
    if (allowPhone && validatePhone(value) && value.indexOf('+') !== 0) {
      this.model.showCountryCode = true;
    } else {
      this.model.showCountryCode = false;
    }
  }

  onCountryCodeClick(): void {
    const { regions } = this.token;
    CountryPicker.show(this.selectedCountry, regions ?? [], null, (selected: CountryInfo) => {
      if (selected) {
        this.selectedCountry = selected;
        this.model.countryCode = this.selectedCountry.phonePrefix;
      }
    });
  }

  validateName(value: string, type: 'full_name' | 'first_name' | 'last_name'): string | null {
    if (value.trim().length === 0) {
      if (type === 'full_name') {
        return 'claim.landing.error_invalid_name';
      }
      if (type === 'first_name') {
        return 'claim.landing.error_invalid_first_name';
      }
      if (type === 'last_name') {
        return 'claim.landing.error_invalid_last_name';
      }
    }
    return null;
  }

  validateToken(value: string, allowPhone: boolean, allowEmail: boolean): string | null {
    if (allowPhone && allowEmail) {
      if (!validateEmail(value) && !validatePhone(value)) {
        return 'claim.landing.error_invalid_email_phone_number';
      }
    } else if (allowEmail) {
      if (!validateEmail(value)) {
        return 'claim.landing.error_invalid_email';
      }
    } else if (allowPhone) {
      if (!validatePhone(value)) {
        return 'claim.landing.error_invalid_phone_number';
      }
    }

    if (allowEmail && validateEmail(value)) {
      this.formattedToken = { value, type: 'email' };
    } else if (allowPhone && validatePhone(value)) {
      try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        if (!this.selectedCountry?.code) {
          return 'claim.landing.error_invalid_region';
        }

        const phoneNumber = phoneUtil.parse(value, this.selectedCountry.code);
        const token = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164).trim();
        this.formattedToken = { value: token, type: 'phone' };
        if (!phoneUtil.isValidNumber(phoneNumber)) {
          return 'claim.landing.error_invalid_phone_number';
        }

        if (
          this.supportedCountryCodes.length > 0 &&
          !this.supportedCountryCodes.find((countryInfo) =>
            token.startsWith(countryInfo.phonePrefix),
          )
        ) {
          return 'claim.landing.error_invalid_region';
        }

        if (token.length > 0) {
          this.inputToken = token;
          return null;
        }
      } catch (error) {
        Log.error(error);
        return 'claim.landing.error_invalid_phone_number';
      }
    }
    return null;
  }

  validateSelect(value: string | null): string | null {
    if (!value) {
      return 'claim.landing.additional.error_select';
    }
    return null;
  }

  validatePrimaryConsent(value: boolean): string | null {
    if (!value) {
      return 'claim.landing.error_consent';
    }
    return null;
  }

  isFormItemValid(item: FormItem): boolean {
    // check if required and value has been set
    return item.required ? !!item.value : true;
  }

  validateForm(provider: AuthProvider): { hasError: boolean; bag: ErrorBag } {
    this.inputToken = this.inputToken?.trim();
    const { allow_phone: allowPhone, allow_email: allowEmail } = this.token;

    const { capture_name: captureName, capture_additional: captureAdditional } = this.config.claim;

    const errorBag: ErrorBag = {
      errorPrimaryConsent: null,
      errorFullName: null,
      errorFirstName: null,
      errorLastName: null,
      errorEmail: null,
      errorPhone: null,
      errorToken: null,
      errorAdditional: null,
      errorFormItems: [],
    };

    // validate token
    if (provider === 'token') {
      errorBag.errorToken = this.validateToken(this.inputToken, allowPhone, allowEmail);
    }

    // validate select
    if (captureAdditional && captureAdditional.length > 0) {
      errorBag.errorAdditional = this.validateSelect(this.inputAdditional);
    }

    // validate name
    if (captureName) {
      if (this.model.nameType === 'full_name') {
        errorBag.errorFullName = this.validateName(this.inputFullName ?? '', 'full_name');
      } else if (this.model.nameType === 'first_name') {
        errorBag.errorFirstName = this.validateName(this.inputFirstName ?? '', 'first_name');
      } else if (this.model.nameType === 'last_name') {
        errorBag.errorLastName = this.validateName(this.inputLastName ?? '', 'last_name');
      }
    }

    // validate primary consent
    if (!this.isAuthComplete) {
      if (!this.acceptedPrimaryConsent) {
        errorBag.errorPrimaryConsent = 'claim.landing.error_consent';
      } else {
        errorBag.errorPrimaryConsent = null;
      }
    }

    // validate form items
    this.formItems.forEach((item) => {
      const hasError = !this.isFormItemValid(item);
      const updatedItem = item;
      updatedItem.error = hasError;
      this.onFormItemChange(updatedItem);
    });

    this.formItems.forEach((item) => {
      if (item.error) {
        errorBag.errorFormItems.push(item);
      }
    });

    // check for any error in the bag
    let hasError = false;
    const entries = Object.entries(errorBag) as Bag;
    entries.forEach(([key, value]) => {
      // check for any errored form items
      if (key === 'errorFormItems') {
        const thing = value.find((item) => item.error);
        if (thing) {
          hasError = true;
        }
      } else if (typeof value === 'string') {
        // check for any other errors
        hasError = true;
      }
    });

    Log.info('hasError', hasError, 'errorBag', errorBag);

    return { hasError, bag: errorBag };
  }

  onNextClick(provider: AuthProvider): void {
    this.inputToken = this.inputToken?.trim();

    const { delay: errorDelay } = this.config.error;

    // validate form
    const { hasError, bag } = this.validateForm(provider);
    if (hasError) {
      this.model.formFocusTrigger += 1;
      if (this.config.enable_vgar) {
        this.model.showErrorSummary = true;
      }
    }

    // update model
    this.model.errorAdditional = bag.errorAdditional;
    this.model.errorEmail = bag.errorEmail;
    this.model.errorPhone = bag.errorPhone;
    this.model.errorPrimaryConsent = bag.errorPrimaryConsent;
    this.model.errorToken = bag.errorToken;
    this.model.errorFullName = bag.errorFullName;
    this.model.errorFirstName = bag.errorFirstName;
    this.model.errorLastName = bag.errorLastName;

    // build vlabs params
    if (!hasError) {
      this.model.loading = true;

      const params: VLabsParams = { user: this.formattedToken?.value };

      if (this.inputFullName && this.inputFullName.length > 0) {
        params.name = this.inputFullName;
      }
      if (this.inputFirstName && this.inputFirstName.length > 0) {
        params.first_name = this.inputFirstName;
      }
      if (this.inputLastName && this.inputLastName.length > 0) {
        params.last_name = this.inputLastName;
      }

      if (this.inputAdditional) {
        params.select = this.inputAdditional;
      }

      params.language = this.language;

      // Consent Framework
      if (this.acceptedPrimaryConsent) {
        // Build accepted policies
        const accepted = this.languagePolicies;

        // Remove marketing if enabled but not accepted
        if (
          this.config.claim.consent.marketing?.enabled === true &&
          !this.acceptedMarketingConsent
        ) {
          delete accepted.marketing;
        }
        params.policies_accepted = accepted;
      }

      if (this.dateOfBirth) {
        params.birthday = this.dateOfBirth;
      }

      // get config form items
      params.form_items = this.formItems.map((item) => ({
        id: item.id,
        value: item.value ?? null,
      }));

      // map form item shape to legacy param shape
      const preFormItemsMapped = Object.fromEntries(
        this.preFormItems.map((obj) => [obj.id, obj.value]),
      );

      // add iframe form items as params (mimicking url search param behavior)
      params.additionalParams = preFormItemsMapped;

      if (provider === 'token') {
        // Register the user (token flow captures input before registration).
        Log.warn(`[Debug] VlabsUser.go('/signup', params: ${JSON.stringify(params)}`);
        VlabsUser.go(
          '/signup',
          params,
          (data, notificationType: NotificationType) => {
            this.onRegister?.(notificationType, this.formattedToken!);
          },
          (error) => {
            Log.error('[Debug] .go(/signup) error:', error);
            if (error.code === 94) {
              // if error 'token_expired', push user back to start
              ErrorPopup.show(error, () => window.location.reload());
            } else {
              ErrorPopup.show(error, () => {}, errorDelay);
            }
          },
        ).finally((): void => {
          this.model.loading = false;
        });
      } else {
        // Update the user (OAuth & WalletConnect capture inputs after registration).
        Log.warn(`[Debug] VlabsUser.go('/update', params: ${JSON.stringify(params)}`);
        VlabsUser.go(
          '/update',
          params,
          (_, notificationType: NotificationType) => {
            this.onRegister?.(notificationType);
          },
          (error) => {
            ErrorPopup.show(error, () => {}, errorDelay);
            Log.error(error);
          },
        ).finally((): void => {
          this.model.loading = false;
        });
      }
    }
  }

  onBackClick(): void {
    // remove form-item errors
    this.formItems.forEach((item) => {
      const updatedItem = item;
      updatedItem.error = false;
      this.onFormItemChange(updatedItem);
    });

    this.onBack();
  }

  hasFormError(): boolean {
    return (
      !!this.model.errorAdditional ||
      !!this.model.errorPrimaryConsent ||
      !!this.model.errorFullName ||
      !!this.model.errorFirstName ||
      !!this.model.errorLastName ||
      !!this.model.errorToken ||
      !!this.model.errorPhone ||
      !!this.model.errorEmail ||
      this.formItems.find((item) => !!item.error) !== undefined
    );
  }
}
