import clsx from 'clsx';
import React, { useId, useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation, withTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/joy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Helmet } from 'react-helmet';
import styles from './index.module.scss';
import Image from '../../components/image';
import TypographyHTML from '../../components/typography-html';
import { focusElement } from '../../util/focus';
import { tIfExists } from '../../util/i18next-helpers';

interface ViewProps {
  localePrefix?: string;
  prefix: string;
  pageTitleSuffix?: string;
  headerText?: string | null;
  headerTextChildren?: React.ReactNode;
  headerStyles?: string | null;
  footerText?: string | null;
  footerStyles?: string | null;
  backgroundImageSrc?: string | null;
  backgroundImageAltText?: string | null;
  topOffset?: string;
  onBack?: () => void;
  autoFocusHeader?: boolean;
}

function View({
  pageTitleSuffix = undefined,
  headerText = undefined,
  headerTextChildren = undefined,
  headerStyles = undefined,
  footerText = undefined,
  footerStyles = undefined,
  backgroundImageSrc = undefined,
  backgroundImageAltText = undefined,
  topOffset = undefined,
  onBack = undefined,
  autoFocusHeader = false,
  ...props
}: React.PropsWithChildren<ViewProps>): JSX.Element {
  const { t } = useTranslation();
  const { prefix, localePrefix, children } = props;
  const isSmall = useMediaQuery('(max-width: 856px)');

  const mainContentId = `${useId()}-main-content`;

  const headerRef = useRef<HTMLElement | null>(null);

  let basePageTitle = tIfExists(`${localePrefix}.page_title`) ?? '';
  if (basePageTitle.length > 0) {
    basePageTitle += ' - ';
  }

  useEffect(() => {
    if (autoFocusHeader && headerRef.current) {
      focusElement(headerRef.current);
    }
  }, []);

  return (
    <>
      {pageTitleSuffix && (
        <Helmet>
          <title>{`${basePageTitle}${pageTitleSuffix}`}</title>
        </Helmet>
      )}
      <Box
        className={clsx(
          isSmall ? `${prefix}-base-container` : `${prefix}-base-container-anchored`,
          styles.container,
        )}
        sx={{ backgroundColor: 'background.body' }}
      >
        {isSmall && (
          <div
            className={clsx(
              `base-background-image-container`,
              `${prefix}-base-background-image-container`,
              styles.imageContainer,
            )}
            style={{ height: topOffset }} // Override the height of the image container
          >
            {backgroundImageSrc && (
              <Image
                className={clsx(`${prefix}-base-background-image`, styles.image)}
                src={backgroundImageSrc}
                alt={backgroundImageAltText ?? ''}
              />
            )}
          </div>
        )}

        <div className={clsx(`${prefix}-base-inner-container`, styles.innerContainer)}>
          <main id={mainContentId} className={styles.mainContainer}>
            <div className={styles.topHeader}>
              <div className={styles.headerSpacer}>
                {onBack && (
                  <div role="navigation">
                    <IconButton
                      className="header-back-button"
                      onClick={(): void => onBack?.()}
                      aria-label={t('common.back_icon_aria_label')}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </div>
                )}
              </div>

              {headerText && (
                <TypographyHTML
                  ref={headerRef}
                  className={clsx(styles.text, styles.header, headerStyles)}
                  component="h1"
                  level="h3"
                  htmlContent={headerText}
                >
                  {headerTextChildren}
                </TypographyHTML>
              )}
              <div className={styles.headerSpacer} />
            </div>

            {children}
          </main>

          <div className={clsx(`${prefix}-base-footer-space`, styles.footerSpace)} />
          {footerText && (
            <TypographyHTML
              role="contentinfo"
              className={clsx(
                isSmall ? `${prefix}-base-footer` : `${prefix}-base-footer-anchored`,
                styles.text,
                footerStyles,
              )}
              level="footer"
              htmlContent={footerText}
            />
          )}
        </div>
      </Box>
    </>
  );
}

export default withTranslation()(View);
