import React, { useState } from 'react';
import dayjs from 'dayjs';
import { MobileDatePicker, MobileDatePickerProps } from '@mui/x-date-pickers';

interface MobileDatePickerWrapperProps
  extends Omit<MobileDatePickerProps<dayjs.Dayjs>, 'open' | 'onAccept' | 'onClose'> {
  /// Callback for when the text field loses focus and the date picker is closed.
  onTextFieldCloseBlur?: () => void;
}

/**
 * Wrapper around the MobileDatePicker component that handles the open state,
 * specifically to prevent backdrop clicks (INT-WEB-004-07).
 */
function MobileDatePickerWrapper({
  onTextFieldCloseBlur = undefined,
  slotProps,
  ...props
}: MobileDatePickerWrapperProps): JSX.Element {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen);
  };

  return (
    <MobileDatePicker
      {...props}
      open={open}
      onAccept={(): void => handleOpenChange(false)}
      onClose={(): void => handleOpenChange(false)}
      slotProps={{
        ...slotProps,
        textField: {
          fullWidth: true,
          variant: 'outlined',
          onClick: (): void => handleOpenChange(true),
          onKeyDown: (event): void => {
            if (event.key === 'Enter') {
              handleOpenChange(true);
            }
          },
          onBlur: (): void => {
            if (!open) {
              onTextFieldCloseBlur?.();
            }
          },
          ...slotProps?.textField,
        },
        dialog: {
          onClose: (event, reason): void => {
            if (reason !== 'backdropClick') {
              handleOpenChange(false);
            }
          },
          ...slotProps?.dialog,
        },
      }}
    />
  );
}

export default MobileDatePickerWrapper;
