import { Dayjs } from 'dayjs';
import Model, { AgePage } from './model';

export default class Presenter {
  model: Model;

  onClose: (date: Dayjs | null) => void;

  constructor(model: Model, onClose: (date: Dayjs | null) => void) {
    this.model = model;
    this.onClose = onClose;
  }

  onCloseClick(): void {}

  onAgeValidate(date: Dayjs | null): void {
    this.onClose(date);
  }

  onError(): void {
    this.model.index = AgePage.error;
  }
}
