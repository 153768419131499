// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claim-eRhcKh-innerContainer{box-sizing:border-box;display:flex;flex-direction:column;align-items:center;width:100%;height:100%}.claim-sWcdJ8-resend:active{filter:brightness(70%)}.claim-FF33_J-text{text-align:center;margin-bottom:12px}.claim-K2aq7o-spacer1{min-height:12px;flex:1 1 auto}.claim-zaCO89-spacer5{min-height:12px;flex:5 1 auto}`, "",{"version":3,"sources":["webpack://./src/views/view-otp/view.module.scss"],"names":[],"mappings":"AAAA,6BACE,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CAGF,4BACE,sBAAA,CAGF,mBACE,iBAAA,CACA,kBAAA,CAGF,sBACE,eAAA,CACA,aAAA,CAGF,sBACE,eAAA,CACA,aAAA","sourcesContent":[".innerContainer {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n\n.resend:active {\n  filter: brightness(70%);\n}\n\n.text {\n  text-align: center;\n  margin-bottom: 12px;\n}\n\n.spacer1 {\n  min-height: 12px;\n  flex: 1 1 auto;\n}\n\n.spacer5 {\n  min-height: 12px;\n  flex: 5 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `claim-eRhcKh-innerContainer`,
	"resend": `claim-sWcdJ8-resend`,
	"text": `claim-FF33_J-text`,
	"spacer1": `claim-K2aq7o-spacer1`,
	"spacer5": `claim-zaCO89-spacer5`
};
export default ___CSS_LOADER_EXPORT___;
