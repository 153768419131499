import { TFunction } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import ViewBase from '../../../views/view-base';
import { tIfExists } from '../../../util/i18next-helpers';

interface ViewProps {
  t: TFunction;
}

function View(props: ViewProps): JSX.Element {
  const { t } = props;

  return (
    <ViewBase
      localePrefix="claim"
      headerText={t('claim.expired.text')}
      footerText={t('claim.expired.footer')}
      prefix="claim-expired"
      backgroundImageSrc={tIfExists('claim.expired.background_image.src')}
      backgroundImageAltText={t('claim.expired.background_image.alt')}
    />
  );
}

export default withTranslation()(View);
