import React, { Component } from 'react';
import createWafCaptchaModel, { WafCaptchaModel } from './model';
import Presenter, { WafCaptchaPresenterType } from './presenter';
import View from './view';
import Alert from '../../util/alert';

interface WafCaptchaPopupProps {
  apiKey: string;
  onSuccess: () => void;
  open: boolean;
}
let wafCaptchaPopup: Promise<void> | undefined;
export default class WafCaptchaPopup extends Component<WafCaptchaPopupProps, WafCaptchaModel> {
  static show(apiKey: string): Promise<void> {
    if (!wafCaptchaPopup) {
      wafCaptchaPopup = new Promise((resolve) => {
        // eslint-disable-next-line react/no-unstable-nested-components, react/function-component-definition
        Alert.show(
          (id: number, close: () => void) =>
            // eslint-disable-next-line react/no-unstable-nested-components
            function (open: boolean): JSX.Element {
              return (
                <WafCaptchaPopup
                  key={`${id}-waf-captcha`}
                  open={open}
                  apiKey={apiKey}
                  onSuccess={(): void => {
                    resolve();
                    close();
                    wafCaptchaPopup = undefined;
                  }}
                />
              );
            },
        );
      });
    }
    return wafCaptchaPopup;
  }

  presenter: WafCaptchaPresenterType;

  constructor(props: WafCaptchaPopupProps) {
    super(props);
    const { onSuccess, apiKey } = props;
    this.presenter = new Presenter(createWafCaptchaModel(this), apiKey, onSuccess);
  }

  componentDidMount(): void {
    this.presenter.onAttach();
  }

  componentWillUnmount(): void {
    this.presenter.onDetach();
  }

  render(): JSX.Element {
    const { open } = this.props;
    return <View {...this.state} presenter={this.presenter} open={open} />;
  }
}
