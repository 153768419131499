import clsx from 'clsx';
import { TFunction } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Typography } from '@mui/joy';
import Image from '../../../components/image';
import { FormattedToken } from '../../../types/common';
import { NotificationType } from '../../../types/vlabs-user';
import ViewBase from '../../../views/view-base';
import Presenter from './presenter';
import styles from './view.module.scss';
import { tIfExistsAndIsValid } from '../../../util/i18next-helpers';
import { Config } from '../../../types/config';
import VisuallyHiddenSpan from '../../../components/span-visually-hidden';

interface ViewProps {
  t: TFunction;
  // eslint-disable-next-line react/no-unused-prop-types
  presenter: Presenter;
  notificationType: NotificationType | null;
  formattedToken: FormattedToken;
  enableAccessibility: boolean;
  config: Config;
}

function View(props: ViewProps): JSX.Element {
  const { t, notificationType, formattedToken, enableAccessibility, config } = props;

  const linkImageUrl = config.claim?.style?.link?.link_image?.src;

  let headerLabel = '';
  let label = '';
  if (notificationType === 'login') {
    if (formattedToken.type === 'phone') {
      label = 'claim.link.login_text_phone_only';
      headerLabel = 'claim.link.login_header_text_phone_only';
    } else {
      label = 'claim.link.login_text_email_only';
      headerLabel = 'claim.link.login_header_text_email_only';
    }
  } else if (formattedToken.type === 'phone') {
    label = 'claim.link.verify_text_phone_only';
    headerLabel = 'claim.link.verify_header_text_phone_only';
  } else {
    label = 'claim.link.verify_text_email_only';
    headerLabel = 'claim.link.verify_header_text_email_only';
  }

  const headerText = t(headerLabel);

  const headerTextSuffix = ` - ${t('claim.landing.progress_context')} (${t('common.progress_step', { current: 2, total: 2 })})`;
  const headerTextSuffixSpan = <VisuallyHiddenSpan>{headerTextSuffix}</VisuallyHiddenSpan>;
  const pageTitleSuffix = headerText + headerTextSuffix;

  return (
    <div className={clsx('claim-link-container', styles.container)}>
      <ViewBase
        localePrefix="claim"
        prefix="claim-link"
        pageTitleSuffix={enableAccessibility ? pageTitleSuffix : undefined}
        headerText={headerText}
        headerTextChildren={enableAccessibility ? headerTextSuffixSpan : undefined}
        backgroundImageSrc={tIfExistsAndIsValid('claim.link.background_image.src')}
        backgroundImageAltText={t('claim.link.background_image.alt')}
      >
        {linkImageUrl && (
          <div className={clsx('login-link-image-container', styles.contextImageContainer)}>
            <Image
              className={clsx('login-link-image', styles.contextImage)}
              src={linkImageUrl}
              alt={t('claim.link.link_image_alt')}
            />
          </div>
        )}
        <Typography className={clsx('claim-link-text', styles.linkText)}>{t(label)}</Typography>
      </ViewBase>
    </div>
  );
}

export default withTranslation()(View);
