import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Divider, Input } from '@mui/joy';
import { InputProps } from '@mui/joy/Input';
import React, { ChangeEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CountryPicker from '../../features/country-select';
import { FormattedToken } from '../../types/common';
import Countries, { CountryInfo } from '../../util/countries';
import { validatePhone } from '../../util/validation';
import FormControlWrapper, { FormControlWrapperProps } from './form-control-wrapper';
import formatAndValidate, { TokenValidationErrorKey } from './format-validate';

export interface InputTokenProps extends Omit<FormControlWrapperProps, 'onChange'> {
  allowPhone: boolean;
  allowEmail: boolean;
  regions?: string[];
  defaultCountry?: CountryInfo;
  /** Initial value - does not follow the controlled/uncontrolled pattern - yet. */
  defaultValue?: string;
  inputProps?: Omit<Omit<InputProps, 'value'>, 'onChange'>;
  onChange: (token: Token) => void;
  onBlur?: () => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export interface Token {
  rawValue: string | null;
  country: CountryInfo | null;
  formattedToken?: FormattedToken;
  errorKey?: TokenValidationErrorKey | null;
}

function FormInputToken({
  defaultCountry = undefined,
  regions = [],
  inputProps = undefined,
  defaultValue = undefined,
  inputRef = undefined,
  ...props
}: InputTokenProps): JSX.Element {
  // const inputRef = useRef<HTMLInputElement>(null);
  const selectionRef = useRef({ start: 0, end: 0 });
  const { t } = useTranslation();
  const { allowPhone, allowEmail, onChange, onBlur, ...formElementProps } = props;

  const [country, setCountry] = useState<CountryInfo | null>(
    defaultCountry ?? Countries.getDefault(),
  );
  const [rawValue, setRawValue] = useState<string | null>(defaultValue ?? null);
  const [countryPickerVisible, setCountryPickerVisible] = useState(false);
  const [disableCountryPicker, setDisableTokenSelect] = useState(false);

  function updateCountryCodeVisibility(tokenValue: string): void {
    // only show country code button if valid phone
    if (allowPhone && validatePhone(tokenValue) && !tokenValue.trim().startsWith('+', 0)) {
      setCountryPickerVisible(true);
    } else {
      setCountryPickerVisible(false);
    }
  }

  useEffect(() => {
    // check if country code should be initially shown
    updateCountryCodeVisibility(rawValue ?? defaultValue ?? '');
    // disable select for one item
    if (regions?.length === 1) {
      setDisableTokenSelect(true);
    }

    if (defaultValue) {
      const { formattedToken, errorKey } = formatAndValidate(
        allowEmail,
        allowPhone,
        rawValue ?? '',
        regions ?? [],
        country,
      );
      const token: Token = { rawValue, country, formattedToken, errorKey };
      onChange(token);
    }
  }, [country]);

  const onCountryCodeSelectorClick = (): void => {
    CountryPicker.show(country ?? null, regions ?? [], null, (selected: CountryInfo) => {
      if (selected) {
        setCountry(selected);
        const { formattedToken, errorKey } = formatAndValidate(
          allowEmail,
          allowPhone,
          rawValue ?? '',
          regions ?? [],
          selected,
        );

        const token: Token = { rawValue, country: selected, formattedToken, errorKey };
        onChange(token);
      }
    });
  };

  // restore cursor position after re-render
  useLayoutEffect(() => {
    if (inputRef?.current) {
      inputRef.current?.setSelectionRange(selectionRef.current.start, selectionRef.current.end);
    }
  }, [defaultValue]);

  function handleChange(e: ChangeEvent<HTMLInputElement>): void {
    // preserve cursor position before updating the value
    selectionRef.current = {
      start: e.target.selectionStart ?? 0,
      end: e.target.selectionEnd ?? 0,
    };

    updateCountryCodeVisibility(e.target.value);

    const { formattedToken, errorKey } = formatAndValidate(
      allowEmail,
      allowPhone,
      e.target.value,
      regions ?? [],
      country,
    );

    const token: Token = { rawValue: e.target.value, country, formattedToken, errorKey };
    setRawValue(e.target.value);
    onChange(token);
  }

  return (
    <FormControlWrapper {...formElementProps}>
      <Input
        {...inputProps}
        type="text"
        value={rawValue ?? ''}
        slotProps={{ input: { ref: inputRef } }}
        onChange={(e): void => handleChange(e)}
        onBlur={() => {
          onBlur?.();
        }}
        startDecorator={
          countryPickerVisible && (
            <>
              <div aria-live="polite" className="screen-reader-only">
                {t('common.token_button.aria_country_code_picker_available')}
              </div>
              <div aria-live="polite">
                <Button
                  aria-label={
                    t('common.token_button.aria_label_choose_country_code') +
                    (country?.phonePrefix ?? '')
                  }
                  aria-expanded
                  aria-haspopup="dialog"
                  variant="plain"
                  size="sm"
                  sx={{
                    m: 0,
                    px: '4px',
                    minWidth: '40px',
                    height: 'fit-content',
                    backgroundColor: 'transparent',
                    fontWeight: '500',
                  }}
                  onClick={(): void => onCountryCodeSelectorClick()}
                  disabled={inputProps?.readOnly || disableCountryPicker}
                  endDecorator={<ArrowDropDownIcon />}
                >
                  {country?.phonePrefix}
                </Button>
              </div>
              <Divider orientation="vertical" />
            </>
          )
        }
      />
    </FormControlWrapper>
  );
}

export default FormInputToken;
