import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import i18next, { TFunction } from 'i18next';
import styles from './index.module.scss';
import Image from '../../components/image';
import { LocalePrefix } from '../../types/common';
import { tIfExistsAndIsValid } from '../../util/i18next-helpers';
import addCurrentSearchParamsToUrl from '../../util/add-current-search-params-to-url';

export interface IndexProps {
  t: TFunction;
  prefix: LocalePrefix;
}

function Index({ children = undefined, ...props }: PropsWithChildren<IndexProps>): JSX.Element {
  const { t } = props;
  const { prefix } = props;

  const backdropImage = tIfExistsAndIsValid(`${prefix}.backdrop.background_image.src`);

  const landingPrefix = `${prefix}.landing`;

  let anchoredImage = tIfExistsAndIsValid(`${landingPrefix}.anchored.background_image.src`);
  let anchoredImageAltText = t(`${landingPrefix}.anchored.background_image.alt`);
  if (!anchoredImage) {
    anchoredImage = tIfExistsAndIsValid(`${landingPrefix}.background_image.src`);
    anchoredImageAltText = t(`${landingPrefix}.background_image.alt`);
  }

  let backgroundIframeUrl = i18next.exists(`${landingPrefix}.anchored.background_iframe.src`)
    ? t(`${landingPrefix}.anchored.background_iframe.src`)
    : null;
  if (backgroundIframeUrl) {
    backgroundIframeUrl = addCurrentSearchParamsToUrl(backgroundIframeUrl);
  }
  let backgroundIframeTitle = t(`${landingPrefix}.anchored.background_iframe.title`, {
    defaultValue: '',
  });
  const backgroundIframeLang = t(`${landingPrefix}.anchored.background_iframe.lang`, {
    defaultValue: 'en',
  });

  if (!backgroundIframeUrl) {
    backgroundIframeUrl = i18next.exists(`claim.landing.background_iframe.src`)
      ? t(`claim.landing.background_iframe.src`)
      : null;
    if (backgroundIframeUrl) {
      backgroundIframeUrl = addCurrentSearchParamsToUrl(backgroundIframeUrl);
    }
    backgroundIframeTitle = t(`claim.landing.background_iframe.title`, { defaultValue: '' });
  }

  return (
    // TODO: Setting role="image" or aria-hidden breaks voiceover desktop. Refuses to focus book content.
    <div
      className={clsx(`anchor-page-backdrop`, styles.backdrop)}
      style={{
        backgroundImage: backdropImage ? `url(${backdropImage})` : undefined,
      }}
      aria-label={t(`${prefix}.backdrop.background_image.alt`)}
    >
      <div className={clsx('anchor-page-container', styles.container)} role="region">
        <div
          role="complementary"
          className={clsx(`anchor-page-left-container`, styles.leftContainer)}
        >
          {/* <video
            className={clsx(
              `anchor-anchored-background-video-container`,
              styles.anchoredVideoContainer,
            )}
            autoPlay
            muted
            loop
          >
            <Source
              type="video/mp4"
              className={clsx(`anchor-anchored-background-video`, styles.video)}
            />
          </video> */}
          <div
            className={clsx(
              `anchor-anchored-background-image-container`,
              styles.anchoredImageContainer,
            )}
          >
            {anchoredImage && (
              <Image
                className={clsx(`anchor-anchored-background-image`, styles.image)}
                src={anchoredImage}
                alt={anchoredImageAltText}
              />
            )}
          </div>

          {backgroundIframeUrl && (
            <div
              className={clsx(
                `anchor-anchored-background-iframe-container`,
                styles.anchoredIframeContainer,
              )}
            >
              <iframe
                id="background_iframe"
                title={backgroundIframeTitle}
                lang={backgroundIframeLang}
                src={backgroundIframeUrl}
                className={clsx(`anchor-anchored-background-iframe`, styles.iframe)}
              />
            </div>
          )}
        </div>

        <div className={clsx('anchor-page-content-container', styles.contentContainer)}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Index;
