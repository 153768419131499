import { Box, Button, Container, CssBaseline, Grid, Stack, Typography } from '@mui/joy';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { TFunction } from 'i18next';
import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import FormControlWrapper from '../../components/form/form-control-wrapper';
import FormInputToken, { Token } from '../../components/form/form-input-token';
import { TokenValidationErrorKey } from '../../components/form/format-validate';
import styles from './view.module.scss';
import PoweredByProveIcon from '../../resources/images/powered_by_prove.svg';
import { tIfExists } from '../../util/i18next-helpers';
import MobileDatePicker from '../../components/mobile-date-picker';

interface ChallengeProps {
  /** If MobileAuth succeeded, then the number should be passed in and displayed as read-only, maybe stared, with last 4 digits shown */
  t: TFunction;
  page: 'login' | 'claim';
  phoneNumber: string;
  phoneReadOnly?: boolean;
  allowedRegions: string[];
  dob?: Dayjs | null;
  disableDobPicker: boolean;
  loading?: boolean;
  phoneInvalid: boolean; // made these strings since the helper text may be different depending on the error
  dobInvalid: boolean;
  showPhoneValidationError: boolean;
  showDobValidationError: boolean;
  formFocusTrigger: number;
  onChange: (
    phoneNumber?: string,
    validationError?: TokenValidationErrorKey | null,
    dob?: Dayjs | null,
  ) => void;
  onBlur: (field: 'dob' | 'phone') => void;
  onSubmit?: () => void;
  onBack: (reason: 'error') => void;
}

interface FormElements extends HTMLFormControlsCollection {
  dob: HTMLInputElement;
  phoneNumber?: HTMLInputElement;
}
interface IdentityFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

/**
 * Challenge asks the user for two pieces of info: dob and phone number.
 * The phone number is passed in (from Mobile Auth), or must be collected in fallback case.
 */
function IdentityChallenge({
  dob = null,
  phoneReadOnly = false,
  loading = false,
  onSubmit = undefined,
  ...props
}: ChallengeProps): JSX.Element {
  const {
    t,
    page,
    phoneNumber,
    allowedRegions,
    disableDobPicker,
    phoneInvalid,
    dobInvalid,
    showPhoneValidationError,
    showDobValidationError,
    formFocusTrigger,
    onChange,
    onBlur,
    onBack,
  } = props;

  const stateRef = useRef('');
  const dobFieldRef = useRef<HTMLInputElement>(null);
  const phoneFieldRef = useRef<HTMLInputElement>(null);

  // focus on the field that has an error
  useEffect(() => {
    if (dobInvalid) {
      dobFieldRef.current?.focus();
    } else if (phoneInvalid) {
      phoneFieldRef.current?.focus(); // TODO: Pass this ref down
    }
  }, [formFocusTrigger]);

  const handleDobChange = (newValue: Dayjs | null): void => {
    onChange?.(undefined, undefined, newValue);
  };

  const handleTokenChange = (value: Token): void => {
    stateRef.current = value.formattedToken?.value ?? '';
    onChange?.(value.formattedToken?.value ?? '', value.errorKey ?? null, undefined);
  };

  const handleSubmit = async (event: React.FormEvent<IdentityFormElement>): Promise<void> => {
    event.preventDefault();
    onSubmit?.();
  };

  // show normal helper text, unless there is an error
  let doHasError = false;
  let dobHelperText = t(`${page}.prove.challenge.dob_helper`);
  if (showDobValidationError) {
    doHasError = true;
    dobHelperText = t(`${page}.prove.challenge.dob_helper_invalid`);
  }

  // show normal helper text, unless there is an error
  let tokenHasError = false;
  let tokenHelperText =
    stateRef.current.length > 0
      ? `E.164 ${stateRef.current}`
      : tIfExists(`${page}.landing.input_helper_phone`) ?? '';
  if (showPhoneValidationError) {
    tokenHasError = true;
    tokenHelperText = t(`${page}.prove.challenge.phone_helper_invalid`);
  }

  return (
    <Container component="main" sx={{ height: '100%' }}>
      <CssBaseline />
      <Box
        sx={{
          pt: 4,
          pb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Stack spacing={2} mb={1}>
          <Typography component="h1" level="h3" textAlign="center">
            {t(`${page}.prove.challenge.title`)}
          </Typography>
          <Typography component="h2" level="body-sm" textAlign="center">
            {tIfExists(`${page}.prove.challenge.sub_title`)}
          </Typography>
        </Stack>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%' }}>
          <Stack spacing={2} mt={3}>
            {page === 'claim' && (
              <FormControlWrapper
                sx={{ mt: '16px', mb: '8px' }}
                required
                label={t(`${page}.prove.challenge.dob_label`)}
                helper={dobHelperText}
                error={doHasError}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  dateFormats={{ monthAndYear: 'MMMM' }}
                >
                  <MobileDatePicker
                    name="dob"
                    label={null}
                    views={['month', 'day']}
                    format="MM / DD"
                    disabled={disableDobPicker}
                    value={dob}
                    onChange={(newValue): void => handleDobChange(newValue)}
                    onTextFieldCloseBlur={(): void => onBlur('dob')}
                    readOnly={loading}
                    inputRef={dobFieldRef}
                  />
                </LocalizationProvider>
              </FormControlWrapper>
            )}

            {!phoneReadOnly && (
              <FormInputToken
                allowPhone
                allowEmail={false}
                size="lg"
                required
                label={t(`${page}.prove.challenge.phone_label`)}
                helper={tokenHelperText}
                error={tokenHasError}
                regions={allowedRegions}
                defaultValue={phoneNumber}
                inputProps={{
                  placeholder: t(`${page}.prove.challenge.phone_placeholder`),
                  readOnly: phoneReadOnly,
                  fullWidth: true,
                  name: 'phoneNumber',
                  variant: 'plain',
                  type: 'text',
                  inputMode: 'tel',
                  autoComplete: 'tel',
                }}
                onChange={(token: Token): void => {
                  handleTokenChange(token);
                }}
                onBlur={(): void => onBlur('phone')}
                inputRef={phoneFieldRef}
              />
            )}
          </Stack>

          <Button type="submit" size="lg" sx={{ width: '100%', mt: 4 }} loading={loading}>
            {t(`${page}.prove.challenge.button_text`)}
          </Button>

          {!phoneReadOnly && (
            <Grid container justifyContent="center" sx={{ mt: 2 }}>
              <Grid>
                <Button
                  variant="plain"
                  disabled={loading}
                  onClick={(): void => {
                    onBack('error');
                  }}
                >
                  {t(`${page}.prove.challenge.no_mobile`)}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
        <div style={{ flexGrow: 1 }} />
        <div className={styles.proveLogoContainer}>
          <img className={clsx(`powered-by-prove-icon`)} alt="" src={PoweredByProveIcon} />
        </div>
      </Box>
    </Container>
  );
}

export default withTranslation()(IdentityChallenge);
