// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claim-CkoauS-container{width:100%;height:100%}.claim-CkoauS-container>main{width:100%;height:100%;position:relative;overflow:hidden}.claim-CkoauS-container>main>img{width:100%;height:100%;object-fit:contain}.claim-b1xqMv-buttonContainer{position:absolute;z-index:1;bottom:48px;width:100%;display:flex;align-items:center;justify-content:center}.claim-f5y09M-button.claim-f5y09M-button{width:220px}`, "",{"version":3,"sources":["webpack://./src/views/view-mobile-only/index.module.scss"],"names":[],"mappings":"AAAA,wBACE,UAAA,CACA,WAAA,CAEA,6BACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,eAAA,CACA,iCACE,UAAA,CACA,WAAA,CACA,kBAAA,CAKN,8BACE,iBAAA,CACA,SAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,yCACE,WAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n\n  > main {\n    width: 100%;\n    height: 100%;\n    position: relative;\n    overflow: hidden;\n    > img {\n      width: 100%;\n      height: 100%;\n      object-fit: contain;\n    }\n  }\n}\n\n.buttonContainer {\n  position: absolute;\n  z-index: 1;\n  bottom: 48px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.button.button {\n  width: 220px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `claim-CkoauS-container`,
	"buttonContainer": `claim-b1xqMv-buttonContainer`,
	"button": `claim-f5y09M-button`
};
export default ___CSS_LOADER_EXPORT___;
