import { Select, SelectProps } from '@mui/joy';
import React from 'react';

interface BaseSelectProps {
  onChange: (value: string | null) => void;
  onBlur?: (viewed: boolean) => void;
}

type ExtendedProps = BaseSelectProps & Omit<SelectProps<string, false>, 'onBlur' | 'onChange'>;

function BaseSelect({ ...props }: ExtendedProps): JSX.Element {
  const { onBlur, onChange } = props;
  // track if the list view is viewed
  const [listViewed, setListViewed] = React.useState(false);
  return (
    <Select
      {...props}
      onChange={(event: React.SyntheticEvent | null, newValue: string | null): void => {
        onChange(newValue);
      }}
      onListboxOpenChange={() => {
        setListViewed(true);
      }}
      onBlur={() => {
        onBlur?.(listViewed);
      }}
    />
  );
}

export default BaseSelect;
