import { Component } from 'react';
import BaseModel from '../../util/mvp/base-model';
import { VlabsSDKError } from '../../manager/vlabs-errors';

export type ErrorModelType = {
  error?: VlabsSDKError;
};

export default class ErrorModel extends BaseModel<ErrorModelType> {
  constructor(component: Component, error?: VlabsSDKError) {
    super(component, {
      error,
    });
  }
}
