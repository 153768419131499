import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { FormControl, FormControlProps, FormHelperText, FormLabel } from '@mui/joy';
import React from 'react';

export interface FormControlWrapperProps extends FormControlProps {
  label?: string;
  helper?: string;
  inputId?: string;
}

function FormControlWrapper({
  label = undefined,
  helper = undefined,
  inputId = undefined,
  ...props
}: FormControlWrapperProps): JSX.Element {
  const { children, required, error, ...formControlProps } = props;
  // forward required to children
  const childrenWithProps = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return null; // or handle invalid child
    }
    return React.cloneElement(child, { ...child.props, required, error });
  });

  return (
    <FormControl
      sx={{ width: '100%', height: '100%' }}
      required={required}
      error={error}
      {...formControlProps}
    >
      {label && label.length > 0 && (
        <FormLabel htmlFor={inputId} id={inputId ? `${inputId}-label` : undefined}>
          {label}
        </FormLabel>
      )}
      {childrenWithProps}
      {helper && helper.length > 0 && (
        <FormHelperText
          sx={{ mt: '2px', display: 'flex', alignItems: 'flex-start' }}
          id={inputId ? `${inputId}-helper-text` : undefined}
        >
          <InfoOutlined fontSize="inherit" sx={{ position: 'relative', bottom: '-0.15rem' }} />{' '}
          {helper}
        </FormHelperText>
      )}
      {/* Reserve space to prevent layout shift when helper is conditionally shown */}
      {!helper && <FormHelperText sx={{ mt: '2px' }}>&nbsp;</FormHelperText>}
    </FormControl>
  );
}

export default FormControlWrapper;
