import React from 'react';
import clsx from 'clsx';
import styles from './view.module.scss';
import Page from '../../components/page';
import Landing from './landing';
import Error from './error';
import Presenter from './presenter';
import { AgePage, StateModel } from './model';

interface ViewProps extends StateModel {
  presenter: Presenter;
  minAge: number;
  redirect: string;
  redirectDelay: number;
  format: string;
}

export default function View(props: ViewProps): JSX.Element {
  const { presenter, minAge, redirect, redirectDelay, format, index, prevIndex } = props;

  const prev = prevIndex ?? 0;
  const transitionDirection = index > prev ? 'left' : 'right';

  return (
    <div className={clsx(styles.content, 'age-gage-content')}>
      <Page
        key={AgePage.main}
        number={AgePage.main}
        current={index}
        direction={transitionDirection}
      >
        <Landing
          format={format}
          minAge={minAge}
          onComplete={(date): void => {
            presenter.onAgeValidate(date);
          }}
          onError={(): void => {
            presenter.onError();
          }}
        />
      </Page>

      <Page
        key={AgePage.error}
        number={AgePage.error}
        current={index}
        direction={transitionDirection}
      >
        <Error minAge={minAge} redirect={redirect} redirectDelay={redirectDelay} />
      </Page>
    </div>
  );
}

View.Page = Page;
