export default class BaseModel {
  constructor(component, initialState) {
    this.component = component;
    this.component.state = { ...initialState, ...this.component.state };

    if (initialState) {
      Object.keys(initialState).forEach((key) => {
        if (!(key in this)) {
          const self = this;
          Object.defineProperty(self, key, {
            get() {
              return self.value[key];
            },
            set(value) {
              const data = {};
              data[key] = value;
              self.value = data;
            },
          });
        }
      });
    }
  }

  get value() {
    return this.component.state;
  }

  set value(value) {
    this.component.setState(value);
  }

  get values() {
    return this.value;
  }

  set values(value) {
    this.value = value;
  }
}
